import axios from "../interceptors/axios";

export default class NotificationService {
  list = async (params) => {
    try {
      const response = await axios.get("/api/notifications/list", { params });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
      throw error;
    }
  };

  delete = async (params) => {
    try {
      const response = await axios.delete("/api/notifications/delete", {
        params,
      });
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
      throw error;
    }
  };

  create = async (data) => {
    try {
      const response = await axios.post("/api/notifications/create", data);
      console.log(response);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  edit = async (data) => {
    try {
      const response = await axios.post("/api/notifications/edit", data);
      console.log(response);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  read = async (data) => {
    try {
      const response = await axios.put("/api/notifications/read", data);
      console.log(response);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };
}
