import Vue from "vue";
import Router from "vue-router";

import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import NotFound from "@/views/NotFound.vue";

import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import Home from "@/views/HomeView.vue";
import SeriesViews from "@/views/Series.vue";
import ObraOpenView from "@/views/ObraOpenView.vue";
import ObraPageView from "@/views/ObraPageView.vue";
import AuthorView from "@/views/AuthorView.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ChangePassword from "@/views/ChangePassword.vue";
import AdminPanelLayout from "@/layouts/AdminPanelLayout.vue";
import PainelUserCreator from "@/views/PainelUserCreator.vue";
import ProfilerUserView from "@/views/ProfilerUserView.vue";
import PostWorkView from "@/views/PostWorkView.vue";
import PostBannerView from "@/views/PostBannerView.vue";
import WarningsView from "@/views/WarningsView.vue";
import ListCapituloView from "@/views/ListCapituloView.vue";
import ListPagesCapituloView from "@/views/ListPagesCapituloView.vue";
import AdmDashboardView from "@/views/AdmDashboardView.vue";
import AdmAcessUserView from "@/views/AdmAcessUserView.vue";
import AdmModeradoresView from "@/views/AdmModeradoresView.vue";
import EditPorfileView from "@/views/EditPorfileView.vue";
import EditorView from "@/views/EditorView.vue";
import CreateChapterBookView from "@/views/CreateChapterBookView.vue";
import ReadingPageView from "@/views/ReadingPageView.vue";
import Notifications from "@/views/Notifications.vue";
import ComunityView from "@/views/ComunityView.vue";
import BlogView from "@/views/BlogView.vue";

Vue.use(Router);

export default new Router({
  mode: "hash",
  routes: [
    {
      path: "/login",
      component: AuthLayout,
      children: [
        {
          path: "",
          name: "Login",
          component: Login,
        },
      ],
    },
    {
      path: "/forgot-password",
      component: AuthLayout,
      children: [
        {
          path: "",
          name: "ForgotPassword",
          component: ForgotPassword,
        },
      ],
    },
    {
      path: "/cadastro",
      component: AuthLayout,
      children: [
        {
          path: "",
          name: "Register",
          component: Register,
        },
      ],
    },
    {
      path: "/nova-senha",
      component: AuthLayout,
      children: [
        {
          path: "",
          name: "ChangePassword",
          component: ChangePassword,
        },
      ],
    },
    {
      path: "/",
      component: DefaultLayout,
      children: [
        {
          path: "",
          name: "Home",
          component: Home,
        },
        {
          path: "series",
          name: "Series",
          component: SeriesViews,
        },
        { path: "/comunidade", component: ComunityView },
        { path: "/blog", component: BlogView },
        {
          path: "/notifications",
          name: "UserNotifications",
          component: Notifications,
          props: true,
        },
        {
          path: ":idChapter/page",
          name: "ObraPage",
          component: ObraPageView,
          props: true,
        },
        {
          path: "obra/:idObra",
          name: "ObraOpenView",
          component: ObraOpenView,
          props: true,
        },
        {
          path: "obra/book/reading/:idBook",
          name: "ReadingPageView",
          component: ReadingPageView,
          props: true,
        },
        {
          path: "",
        },
        {
          path: "autor/:authorId",
          name: "AuthorView",
          component: AuthorView,
          props: true,
        },
      ],
    },
    {
      path: "/dashboard",
      component: AdminPanelLayout,
      children: [
        {
          path: "painel",
          name: "PainelUserCreator",
          component: PainelUserCreator,
        },
        {
          path: "user-profiler",
          name: "CardDatailsUser",
          component: ProfilerUserView,
        },
        {
          path: "publicar-obra",
          name: "PostWorkView",
          component: PostWorkView,
        },
        {
          path: "publicar-banner",
          name: "PostBannerView",
          component: PostBannerView,
        },
        {
          path: "avisos",
          name: "WarningsView",
          component: WarningsView,
        },
        {
          path: "obrar/:idObra",
          name: "ListCapituloView",
          component: ListCapituloView,
          props: true,
        },
        {
          path: "paginas_capitulos/:idCapitulo",
          name: "ListPagesCapituloView",
          component: ListPagesCapituloView,
          props: true,
        },
        {
          path: "adm_dashboard",
          name: "AdmDashboardView",
          component: AdmDashboardView,
        },
        {
          path: "adm_acess_user",
          name: "AdmAcessUserView",
          component: AdmAcessUserView,
        },
        {
          path: "adm_moderadores",
          name: "AdmModeradoresView",
          component: AdmModeradoresView,
        },
        {
          path: "publicar-banner",
          name: "PostBannerView",
          component: PostBannerView,
        },
        {
          path: "avisos",
          name: "WarningsView",
          component: WarningsView,
        },
        {
          path: "configuracao",
          name: "EditProfileView",
          component: EditPorfileView,
        },
        {
          path: "/editor",
          name: "EditorView",
          component: EditorView,
        },
        {
          path: "criar-capitulo-livro/:idObra",
          name: "CreateChapterBookView",
          component: CreateChapterBookView,
          props: true,
        },
      ],
    },
    {
      path: "*",
      component: NotFound,
    },
    {
      path: "page",
      name: "ObraPage",
      component: ObraPageView,
    },
  ],
});
