<template>
  <v-app class="bg-black">
    <v-container class="text-center justify-center">
    <h1>Página Não Encontrada</h1>
    <p>Desculpe, a página que você está procurando não existe.</p>
    <v-btn color="#451755" class="btn-back" @click="$router.push('/')">Voltar para Home</v-btn>
  </v-container>
  </v-app>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>

.justify-center{
  justify-content: center;
  margin: auto;
}

.bg-black{
  background-color: #0000 !important;
}

h1 {
  font-size: 3rem;
  margin-top: 2rem;
  color: #fcfcfc !important;
}

p {
  font-size: 1.5rem;
  color: #fcfcfc !important;
}

.btn-back{
  color: #fff;
}
</style>
