<template>
  <v-app class="app">
    <div class="reading-container">
      <div class="chapter-header">
        <h2>Capítulo: {{ chapter.name }}</h2>
      </div>

      <!-- Conteúdo do capítulo usando Quill -->
      <div id="quill-editor" class="chapter-content"></div>

      <!-- Controles de navegação -->
      <div class="chapter-controls">
        <v-btn @click="backChapter" :disabled="!chapter.previous_title?.id" color="purple" class="control-button">
          <v-icon>mdi-chevron-left</v-icon> Voltar
        </v-btn>

        <v-btn @click="nextChapter" :disabled="!chapter.next_title?.id" color="purple" class="control-button">
          Avançar <v-icon>mdi-chevron-right</v-icon>
        </v-btn>

        <!-- Botão de Menu -->
        <v-btn @click="openMenuModal" color="purple" class="control-button">
          <v-icon>mdi-menu</v-icon> Capítulos
        </v-btn>
      </div>

      <!-- Modal de seleção de capítulos -->
      <v-dialog v-model="menuModalOpen" max-width="400">
        <v-card>
          <v-card-title>Capítulos</v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item-group v-if="chapters.length">
                <v-list-item v-for="chapter in chapters" :key="chapter.id" @click="selectChapter(chapter.id)">
                  <v-list-item-content>{{ chapter.name }}</v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="closeMenuModal" color="error" block>Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <CommentsChapter :idChapter="chapter.id" />
  </v-app>
</template>


<script>
import CommentsChapter from '@/components/CommentsChapter.vue';
import TitleService from '@/server/TitleService';
import { getProjectDetailsById } from '@/server/homeServe';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';


export default {
  name: 'ReadingPageView',
  props: ['idBook'],
  components: {
    CommentsChapter
  },
  data: () => ({
    chapter: {},
    menuModalOpen: false,
    chapters: [],
    zoomLevel: 1,
    quill: null, // Instância do Quill
    service: new TitleService(),
    controlsVisible: true,
  }),

  async created() {
    await this.fetchChapter();
  },

  methods: {
    startIdleDetection() {
      let timeout;
      window.addEventListener('mousemove', this.resetIdleTimer);
      window.addEventListener('touchstart', this.resetIdleTimer);

      this.resetIdleTimer = () => {
        clearTimeout(timeout);
        this.controlsVisible = true; // Torna os controles visíveis quando o usuário interage
        timeout = setTimeout(() => {
          this.controlsVisible = false; // Oculta os controles após 3 segundos de inatividade
        }, 3000);
      };
    },
    nextChapter() {
      console.log('nextChapter', this.chapter.next_title?.id);
      this.$router.push(`/obra/book/reading/${this.chapter.next_title?.id}`);
      window.location.reload();
    },

    backChapter() {
      console.log('backChapter', this.chapter.previous_title);
      this.$router.push(`/obra/book/reading/${this.chapter.previous_title?.id}`);
      window.location.reload();
    },

    async fetchChapter() {
      try {
        const response = await this.service.view(this.idBook);
        this.chapter = response;
        console.log('Chapter loaded:', this.chapter);
        this.initializeQuill();
      } catch (error) {
        console.error('Erro ao carregar capítulo:', error);
      }
    },

    // Inicializa o Quill para exibir o conteúdo
    initializeQuill() {
      if (this.chapter.content && !this.quill) {
        const quillElement = document.getElementById('quill-editor');
        this.quill = new Quill(quillElement, {
          theme: 'bubble', // Tema do Quill
          readOnly: true, // O editor será somente leitura
          modules: {
            toolbar: false, // Desabilita a barra de ferramentas
          },
        });

        this.quill.root.style.color = "black"
        this.quill.root.innerHTML = this.chapter.content;
      }
    },

    async fetchObra() {
      try {
        const data = await getProjectDetailsById(this.chapter.project_id);
        this.chapters = data.titles;
        console.log('Chapters:', this.chapters);
      } catch (error) {
        console.error('Erro ao buscar obra:', error);
      }
    },

    async openMenuModal() {
      await this.fetchObra()
      this.menuModalOpen = true;
    },

    closeMenuModal() {
      this.menuModalOpen = false;
    },

    selectChapter(id) {
      this.$router.push(`/obra/book/reading/${id}`);
      window.location.reload();
      this.closeMenuModal();
    },
  },
};
</script>

<style scoped>
.ql-editor .ql-blank img {
  width: 100%;
}

p {
  color: #fff !important;
}

#quill-editor p {
  color: #fff !important;
}


.reading-container {
  max-width: 1080px;
  width: 100%;
  margin: 60px auto;
  background: rgb(0, 0, 0);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #fff;
  border: solid 1px #6b6969 !important;
}

.chapter-header {
  text-align: center;
  margin-bottom: 20px;
}

.chapter-content {
  font-size: 1.2rem;
  line-height: 1.6;
  margin: 20px 0;
  min-height: 200px;
  text-align: justify;
  background-color: #000000 !important;
  padding: 16px;
}


#quill-editor {
  background-color: #ffffff;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.control-button {
  background-color: #8e44ad !important;
  color: white !important;
  margin: 0 5px;
  border-radius: 5px;
  padding: 8px 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-btn {
  display: flex;
  align-items: center;
}

.chapter-controls {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border-radius: 5px;
  z-index: 100;
  gap: 8px;
  /* Espaçamento entre os botões */
  flex-wrap: nowrap;
  /* Impede que os botões quebrem para outra linha */
  transition: opacity 0.3s ease;
  /* Efeito suave para ocultar e mostrar os controles */
}

.chapter-controls.hidden {
  opacity: 0;
  /* Faz os controles desaparecerem */
  pointer-events: none;
  /* Impede que os controles escondidos recebam interações */
}

/* Ajustes para dispositivos móveis */
@media (max-width: 600px) {
  .chapter-controls {
    max-width: 90%;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px;
  }

  .control-button {
    flex: 1;
    text-align: center;
    padding: 12px;
    min-width: 100px;
  }
}
</style>
