<template>
  <v-container color='#11111' fluid class="my-profile-page">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <div class="pa-4">
          <v-row justify="center" class="mb-5">
            <div class="profile-container" @click="showImageModal = true">
              <v-avatar size="200" class="profile-avatar">
                <img :src="profile.profile_photo || defaultAvatar" alt="Profile Image" class="profile-image" />
              </v-avatar>

              <!-- Ícone de Câmera -->
              <v-btn icon class="edit-icon">
                <v-icon color="#000000">mdi-camera</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-dialog v-model="showImageModal" max-width="400px" scrollable>
            <v-card>
              <v-card-title class="text-h5">Alterar Imagem de Perfil</v-card-title>
              <v-card-text class="text-center">
                <div class="image-upload-container">
                  <label for="file-input" class="circle-label">
                    <v-avatar size="200">
                      <img :src="selectedImagePreview || profile.profile_photo || 'https://via.placeholder.com/150'"
                        alt="New Profile Image" />
                    </v-avatar>
                  </label>
                  <input id="file-input" type="file" @change="handleImageChange" accept="image/*" hidden />
                </div>
              </v-card-text>
              <v-col>
                <v-btn color="red" text @click="closeModal">Cancelar</v-btn>
                <v-btn color="green" text @click="saveProfileImage">Salvar</v-btn>
              </v-col>
            </v-card>
          </v-dialog>
          <v-form>
            <v-text-field label="Nome" v-model="profile.name" outlined dark></v-text-field>
            <v-text-field label="Nickname" v-model="profile.nickname" outlined dark></v-text-field>
            <v-text-field label="Email" v-model="profile.email" outlined dark></v-text-field>
            <v-textarea label="Bio" v-model="profile.description" outlined dark></v-textarea>

            <v-row>
              <v-col cols="12" sm="6">
                <v-select label="Gênero" v-model="profile.gender" :items="[
                  { text: 'Masculino', value: 'M' },
                  { text: 'Feminino', value: 'F' }
                ]" item-text="text" item-value="value" outlined dark></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Data de Nascimento" v-model="profile.birthday" outlined dark
                  placeholder="DD/MM/AAAA" @input="formatDate" maxlength="10"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field label="País" v-model="profile.country" outlined dark></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Estado" v-model="profile.estate" outlined dark></v-text-field>
              </v-col>
            </v-row>

            <v-text-field label="Instagram" v-model="profile.instagram" outlined dark></v-text-field>
            <v-text-field label="Facebook" v-model="profile.facebook" outlined dark></v-text-field>
            <v-text-field label="Twitter" v-model="profile.twitter" outlined dark></v-text-field>

            <v-btn color="purple" class="white--text" @click="saveProfile">
              Salvar alterações
            </v-btn>
          </v-form>
        </div>
        <div class="pa-4 mt-5">
          <h3>Alterar senha</h3>
          <v-form>
            <v-text-field label="Senha atual" :type="showCurrentPassword ? 'text' : 'password'"
              v-model="passwords.current" outlined :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="togglePasswordVisibility('current')" append-icon-class="password-icon" dark></v-text-field>
            <v-text-field label="Nova senha" :type="showNewPassword ? 'text' : 'password'" v-model="passwords.new"
              outlined :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="togglePasswordVisibility('new')" append-icon-class="password-icon" dark></v-text-field>
            <v-text-field label="Repetir nova senha" :type="showRepeatPassword ? 'text' : 'password'"
              v-model="passwords.repeat" outlined :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="togglePasswordVisibility('repeat')" append-icon-class="password-icon" dark></v-text-field>

            <v-btn color="purple" class="white--text" @click="savePassword">
              Salvar alterações
            </v-btn>
          </v-form>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" top>
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { findInfoUser, formatDateToISO, resetPassword, updateProfile, uploadImageProfile } from "../server/userServe.js";

export default {
  data() {
    return {
      defaultAvatar: "https://cdn-icons-png.flaticon.com/512/847/847969.png",
      profile: {
        profile_photo: '',
        name: '',
        nickname: '',
        email: '',
        description: '',
        gender: '',
        birthday: '',
        country: '',
        estate: '',
        instagram: '',
        facebook: '',
        twitter: ''
      },
      showCurrentPassword: false,
      showNewPassword: false,
      showRepeatPassword: false,
      selectedImage: null,
      selectedImagePreview: '',
      showImageModal: false,
      passwords: {
        current: '',
        new: '',
        repeat: ''
      },
      snackbar: {
        show: false,
        message: '',
        color: '',
        timeout: 3500,
      },
    };
  },
  async created() {
    try {
      this.profile = await findInfoUser();
    } catch (error) {
      this.showSnackbar('Erro ao carregar os dados do usuário:', 'error');
    }
  },

  methods: {
    togglePasswordVisibility(type) {
      if (type === 'current') {
        this.showCurrentPassword = !this.showCurrentPassword;
      } else if (type === 'new') {
        this.showNewPassword = !this.showNewPassword;
      } else if (type === 'repeat') {
        this.showRepeatPassword = !this.showRepeatPassword;
      }
    },
    formatDate() {
      let value = this.profile.birthday.replace(/\D/g, '');
      if (value.length > 2) {
        value = value.replace(/(\d{2})(\d)/, '$1/$2');
      }
      if (value.length > 5) {
        value = value.replace(/(\d{2})\/(\d{2})(\d)/, '$1/$2/$3');
      }
      this.profile.birthday = value;
    },

    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedImage = file;
        this.selectedImagePreview = URL.createObjectURL(file);
      }
    },
    closeModal() {
      this.showImageModal = false;
      this.selectedImage = null;
      this.selectedImagePreview = '';
    },
    async saveProfileImage() {
      try {
        if (this.selectedImage) {
          const response = await uploadImageProfile(this.selectedImage);
          this.profile.profile_photo = response.profile_photo_url;
          this.closeModal();
          this.showSnackbar('Imagem de perfil atualizada com sucesso!', 'success');
          this.profile = await findInfoUser();
        } else {
          this.showSnackbar('Selecione uma imagem antes de salvar.', 'error');
        }
      } catch (error) {
        console.error('Erro ao atualizar a imagem de perfil:', error);
        this.showSnackbar(`${error.message}`, 'error');
      }
    },
    showSnackbar(message, type) {
      this.snackbar.message = message;
      this.snackbar.color = type === 'success' ? 'green' : 'red';
      this.snackbar.show = true;
    },
    async saveProfile() {
      try {
        const updatedProfile = {
          name: this.profile.name,
          nickname: this.profile.nickname,
          email: this.profile.email,
          description: this.profile.description,
          gender: this.profile.gender,
          birthday: formatDateToISO(this.profile.birthday),
          country: this.profile.country,
          estate: this.profile.estate,
          link_instagram: this.profile.instagram || '',
          link_facebook: this.profile.facebook || '',
          link_twitter: this.profile.twitter || '',
          google: this.profile.google || '',
          link_youtube: this.profile.link_youtube || '',
          link_apoiase: this.profile.link_apoiase || '',
          link_padrim: this.profile.link_padrim || '',
          link_patreon: this.profile.link_patreon || '',
          link_site: this.profile.link_site || '',
        };
        await updateProfile(updatedProfile);
        await findInfoUser();
        this.profile = await findInfoUser()
        this.showSnackbar('Perfil atualizado com sucesso!', 'success');
      } catch (error) {
        console.error('Erro ao atualizar o perfil:', error);
        this.showSnackbar('Erro ao atualizar o perfil. Tente novamente.', 'error');
      }
    },

    async savePassword() {
      if (this.passwords.new !== this.passwords.repeat) {
        this.showSnackbar('As senhas não coincidem.', 'error');
        return;
      }
      const newPassword = {
        old_password: this.passwords.current,
        password: this.passwords.new,
        password_confirmation: this.passwords.repeat,
      }
      try {
        await resetPassword(newPassword)
        this.showSnackbar('Senha atualizada com sucesso!', 'success');
        this.passwords = {}
      } catch (error) {
        this.showSnackbar('Erro ao atualizar senha!', 'error');
      }
    },

  }
};
</script>

<style scoped>
.profile-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Estiliza o avatar */
.profile-avatar {
  border: 2px solid #4b4a4a;
  transition: 0.3s ease-in-out;
}

/* Ícone de câmera sobreposto */
.edit-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.my-profile-page {
  color: white;
  min-height: 100vh;
}

.v-btn {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.image-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.circle-label {
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
}

input[type="file"] {
  display: none;
}

@media(max-width: 600px) {
  .my-profile-page {
    padding: 80px 0px !important;
  }
}
</style>
