import axios from "../interceptors/axios";

export default class ProjectService {
  delete = async (params) => {
    try {
      const response = await axios.delete("/api/projects/delete", { params });
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
      throw error;
    }
  };
}
