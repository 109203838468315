<template>
  <v-container fluid class="pa-4 pa-sm-6 pa-md-8">
    <v-dialog v-model="dialog" max-width="950px" max-height="800px">
      <v-card class="modal-card" color="#333333" dark>
        <v-card-text>
          <v-form class="form-inputs" ref="form" v-model="isFormValid">
            <v-row>
              <v-col cols="12" sm="5" class="image-container">
                <div class="image-upload-placeholder">
                  <label for="file-upload" class="image-label">
                    <v-icon size="80" color="grey lighten-1" v-if="!novoCapitulo.imagem">
                      mdi-image
                    </v-icon>
                    <img v-else :src="novoCapitulo.imagem" alt="Pré-visualização" class="image-preview" />
                    <div class="image-text" v-if="!novoCapitulo.imagem">400x700</div>
                  </label>
                  <input type="file" id="file-upload" ref="fileInput" class="file-input" accept="image/*"
                    @change="onImageSelected" />
                  <div v-if="!novoCapitulo.imagem && showErrors" class="error-message">A imagem é obrigatória.</div>
                </div>
              </v-col>
              <v-col cols="12" sm="7">
                <v-text-field v-model="novoCapitulo.nome" label="Nome do Capítulo" outlined dense class="custom-input"
                  :rules="[rules.required]" required></v-text-field>
                <v-textarea v-model="novoCapitulo.descricao" label="Descrição do Capítulo" outlined dense rows="4"
                  class="custom-input" :rules="[rules.required]" required></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="actions-container">
          <v-btn color="red darken-1" text @click="dialog = false" block>
            Cancelar
          </v-btn>
          <v-btn color="purple darken-4" dark @click="salvarCapitulo" block>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" top>
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { publiChapter, getchapters } from '../server/workService.js';

export default {
  name: "AddCapitulo",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    idObra: {
      type: String,
      required: true
    },
    obras: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialog: this.value,
      novoCapitulo: {
        nome: '',
        descricao: '',
        imagem: null,
        file: null
      },
      snackbar: {
        show: false,
        message: '',
        color: '',
        timeout: 2500,
      },
      isFormValid: true,
      showErrors: false,
      rules: {
        required: value => !!value || 'Este campo é obrigatório'
      },
      newObras: [],
    };
  },
  watch: {
    value(newValue) {
      this.dialog = newValue;
    },
    dialog(newValue) {
      this.$emit('input', newValue);
    },
    newObras(newValue) {
      console.log(newValue);
      this.$emit('updateNewChapter', newValue);
    }
  },
  methods: {
    onImageSelected(event) {
      const file = event.target.files[0];
      if (file) {
        this.novoCapitulo.file = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.novoCapitulo.imagem = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async salvarCapitulo() {
      this.showErrors = !this.isFormValid || !this.novoCapitulo.imagem;

      if (!this.isFormValid) {
        this.showSnackbar('O formulário contém erros. Verifique os campos obrigatórios.', 'error');
        return;
      }

      if (!this.novoCapitulo.imagem) {
        this.showSnackbar('É necessário adicionar uma imagem para o capítulo.', 'error');
        return;
      }

      try {
        await publiChapter(this.idObra, this.novoCapitulo);
        const chapters = await getchapters(this.idObra);
        this.newObras = chapters.chapters;
        this.dialog = false;
        this.showSnackbar('Capítulo adicionado com sucesso', 'success');
      } catch (error) {
        console.log(error);
        this.showSnackbar(`Erro ao adicionar capítulo: ${error.message}`, 'error');
      }
    },

    showSnackbar(message, type) {
      this.snackbar.message = message;
      this.snackbar.color = type === 'success' ? 'green' : 'red';
      this.snackbar.show = true;
    },
  }
};
</script>
<style>
@media (max-width: 600px) {
  .image-preview {
    max-width: 100%;
    height: auto;
  }

  .image-text {
    font-size: 12px;
  }

  .custom-input {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .image-preview {
    max-width: 100%;
    height: auto;
  }

  .image-text {
    font-size: 10px;
  }

  .image-upload-placeholder {
    background-color: #4d4d4d;
    border: 2px dashed #b0b0b0;
    width: 60% !important;
    height: 250px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #b0b0b0;
  }
}

.modal-card {
  background-color: #333333;
  color: #fff;
  height: 650px !important;
  padding: 24px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-upload-placeholder {
  background-color: #4d4d4d;
  border: 2px dashed #b0b0b0;
  width: 85%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #b0b0b0;
}

.image-container {
  margin-bottom: 0;
}

.image-text {
  text-align: center
}

.actions-container {
  display: flex;
  align-items: center;
  justify-content: end;
}
</style>
