import TitleSessionComponent from '@/components/TitleSessionComponent.vue';
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-4 purple--text text--darken-3" flat>
          <v-row align="center">
            <v-col cols="6">
              <title-session-component title="Lista de Moderadores" tag="h1" />
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <v-text-field
                v-model="search"
                placeholder="Buscar moderador..."
                outlined
                dark
                append-icon="mdi-magnify"
                class="search-bar"
                @input="fetchUsers"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(moderator, index) in moderators"
        :key="index"
        cols="12"
        md="2"
        class="d-flex justify-center"
      >
      <v-card class="moderator-card">
          <v-img
            src="https://via.placeholder.com/150"
            height="150px"
          ></v-img>
          <v-card-title>{{ moderator.name }}</v-card-title>
          <v-card-subtitle>
            <v-btn text color="orange">Configurações</v-btn>
          </v-card-subtitle>
          <v-btn
            icon
            color="red"
            @click="removeModerator(moderator)"
            class="delete-btn"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleSessionComponent from '@/components/TitleSessionComponent.vue'
export default {
  name: "AdmModeradoresView",
  components:{
    TitleSessionComponent
  },
  data() {
    return {
      search: '',
      menu: false,
      moderators: [
        { name: 'Jefferson' },
        { name: 'Jefferson' },
        { name: 'Jefferson' },
        { name: 'Jefferson' },
        { name: 'Jefferson' },
        { name: 'Jefferson' },
        { name: 'Jefferson' },
        { name: 'Jefferson' }
      ],
      filteredUsers: [],
    };
  },
  methods: {
    fetchUsers() {
      const users = [
        { name: 'Carlos', avatar: 'https://via.placeholder.com/50' },
        { name: 'Maria', avatar: 'https://via.placeholder.com/50' }
      ];
      this.filteredUsers = users.filter((user) =>
        user.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    promoteUser(user) {
      console.log('Usuário promovido:', user);
      this.menu = false;
    },
    removeModerator(moderator) {
      console.log('Moderador removido:', moderator);
    }
  }
};
</script>

<style scoped>
.v-card {
  background-color: #1e1e1e;
  color: white;
}

.moderator-card {
  background-color: #000;
  color: #fff;
  position: relative;
  border: 1px solid #9c27b0;
}

.v-card-title {
  font-weight: bold;
}

.search-bar {
  background-color: #1e1e1e;
  color: white;
}

.delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.purple--text {
  color: #9c27b0 !important;
}

.orange--text {
  color: #ffa500 !important;
}

.v-btn {
  margin: 5px;
}
</style>
