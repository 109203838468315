<template>
  <div class="zinnes-session">
    <div class="container-banners">
      <div class="banner-info">
        <img src="@/assets/images/zinnes-capas-1.jpg" alt="banner 01">
        <div class="banner-info-text">
          <h2 class="title-info">Você também pode ser autor no Zinnes! Crie uma conta em nossa plataforma.</h2>
          <button class="btn-info" @click="goToCreateAccount">Criar Conta</button>
        </div>
      </div>
      <div class="banner-info">
        <img src="@/assets/images/zinnes-capas-2.jpg" alt="banner 02">
        <div class="banner-info-text">
          <h2 class="title-info">Descubra histórias incríveis no Zinnes! Acesse agora e comece a ler..</h2>
          <button class="btn-info" @click="goToSearchStory">Buscar História</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'ZinnesInformations',
  props: {
    totalCreators: {
      type: [Number, String],
      default: '0',
    },
    totalStories: {
      type: [Number, String],
      default: '0',
    },
    totalViews: {
      type: [Number, String],
      default: '0',
    },
  },
  methods: {
    goToCreateAccount() {
      this.$router.push('/cadastro');
    },
    goToSearchStory() {
      this.$router.push('/series');
    },
  },
};
</script>



<style scoped>
.banner-info-text {
  padding: 24px;
  position: absolute;
  top: 10%;
  width: 70%;
  color: #fff;
}

.btn-info {
  background-color: #A542DF;
  color: #fff;
  padding: 8px 16px;
  border-radius: 10px;
  border: solid 1px #fdfdfd;
  cursor: pointer;
}

.zinnes-session {
  width: 100%;
  height: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  align-items: center;
  background-color: #262626 !important;
}

.container-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

.container-banners {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
}

.banner-info {
  position: relative;
}

.banner-info img {
  width: 100%;
  max-width: 900px;
  height: auto;
  border-radius: 10px;
  box-shadow: 10px 10px 8px rgba(0, 0, 0, 0.2);
}

.v-application .info {
  background-color: #A542DF !important;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 250px;
  min-height: 150px;
  border-radius: 10px;
  background-color: #A542DF !important;
  box-shadow: inset 10px 4px 8px rgba(255, 255, 255, 0.89);
  text-align: center;
  padding: 20px;
}



.headline {
  color: #fff;
  font-size: 20px;
  font-weight: 800;
}

.info h3 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

/* Responsividade */
@media (max-width: 1024px) {
  .container-info {
    gap: 30px;
  }

  .container-banners {
    gap: 15px;
  }

  .info {
    width: 200px;
    padding: 15px;
  }

  .headline {
    font-size: 18px;
  }

  .info h3 {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .container-info {
    gap: 20px;
  }

  .info {
    width: 180px;
    padding: 10px;
  }

  .headline {
    font-size: 16px;
  }

  .info h3 {
    font-size: 13px;
  }
}

@media (max-width: 520px) {
  .zinnes-session {
    padding: 16px;
  }

  .container-info,
  .container-banners {
    flex-direction: column;
    gap: 20px;
  }

  .info {
    width: 100%;
    max-width: 180px;
    padding: 10px;
  }

  .headline {
    font-size: 14px;
  }

  .info h3 {
    font-size: 12px;
  }

  .banner-info img {
    max-width: 250px;
    width: 100%;
  }
}

@media (max-width: 420px) {
  .banner-info-text {
    padding: 6px;
  }

  .info {
    max-width: 160px;
    padding: 8px;
  }

  .headline {
    font-size: 9px;
  }

  .info h3 {
    font-size: 9px;
  }

  .banner-info img {
    max-width: 200px;
  }

  .title-info {
    font-size: 10px !important;
  }

  .btn-info {
    padding: 3px 5px !important;
    font-size: 10px !important;
  }
}
</style>
