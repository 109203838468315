<template>
  <v-container fluid class="pa-4 pa-sm-6 pa-md-8 mt-8 mb-4 mt-sm-0 mb-sm-0">
    <v-row class="pa-4">
      <v-col cols="12" class="pa-4 pa-sm-6 pa-md-8">
        <v-card elevation="0" style="background-color: transparent; color: white; position: relative;">
          <v-row class="mb-4 pa-2">
            <!-- Imagem -->
            <v-col cols="12" md="3" class="d-flex justify-center justify-md-start">
              <v-img :src="chapter.title_thumb" aspect-ratio="0.67" max-width="250px"></v-img>
            </v-col>

            <!-- Conteúdo -->
            <v-col cols="12" md="9" class="text-center text-md-left">
              <v-card-title class="text-h5 title">Capítulo: {{ chapter.name }}</v-card-title>
              <v-card-subtitle class="subtitle">Páginas: {{ chapter.pagesData.length }}</v-card-subtitle>
              <v-card-text class="description">
                {{ chapter.description }}
              </v-card-text>

              <v-card-text>
                <div class="info-text">
                  <strong class="strong-white">Data de publicação:</strong> {{ chapter.release_date | formataData }}
                </div>
                <div class="info-text">
                  <strong class="strong-white">Última atualização:</strong> {{ chapter.updated_at | formataDataHora }}
                </div>
                <div class="info-text">
                  <strong class="strong-white">Status:</strong>
                  <v-chip small :class="chapter.title_status === '' ? 'chip-not-published' : 'chip-published'">
                    {{ chapter.title_status ? 'Não publicado' : 'Publicado' }}
                  </v-chip>
                </div>
              </v-card-text>
            </v-col>

            <!-- Botão de Editar -->
            <v-col cols="12" class="d-flex justify-center justify-md-end">
              <v-btn @click="openEditModal" dark color="purple" class="btn"
                :class="{ 'position-absolute': $vuetify.breakpoint.mdAndUp }" style="top: 0; right: 0;">
                Editar Capítulo
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-dialog v-model="isEditModalOpen" max-width="500px">
            <v-card>
              <v-card-title class="headline">Editar Capítulo</v-card-title>
              <v-card-text>
                <v-container>
                  <!-- Campo para Nome -->
                  <v-text-field label="Nome" v-model="editableChapter.name" outlined dense></v-text-field>

                  <!-- Campo para Descrição -->
                  <v-textarea label="Descrição" v-model="editableChapter.description" outlined dense
                    rows="3"></v-textarea>

                  <!-- Campo para Imagem -->
                  <v-file-input label="Imagem do Capítulo" v-model="editableChapter.thumb" outlined
                    dense></v-file-input>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="saveChanges">Salvar</v-btn>
                <v-btn color="red darken-1" text @click="closeEditModal">Cancelar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row align="center" class="px-2">
          <v-col cols="12" sm="auto" class="text-h6 title">Páginas</v-col>

          <!-- Desktop layout -->
          <v-col class="d-none d-sm-flex justify-end">
            <v-btn class="btn mr-2" icon color="white" @click="openPreviewModal">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn @click="openConfirmationDialog" color="purple" dark class="mr-2">
              Publicar
            </v-btn>
            <v-btn color="purple" @click="savePagesChapter" dark class="mr-2">
              Salvar
            </v-btn>
            <v-btn dark color="purple" @click="openImageUploadModal">
              Adicionar novas páginas
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>

          <!-- Mobile layout -->
          <v-col class="d-flex d-sm-none flex-column align-center">
            <v-btn block color="purple" dark class="mb-2" @click="openPreviewModal">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn block @click="openConfirmationDialog" color="purple" dark class="mb-2">
              Publicar
            </v-btn>
            <v-btn block color="purple" @click="savePagesChapter" dark class="mb-2">
              Salvar
            </v-btn>
            <v-btn block dark color="purple" @click="openImageUploadModal">
              Adicionar novas páginas
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>



        <v-row ref="drogArea">
          <v-col v-for="(page) in chapter.pagesData" :key="page.id" :data-id="page.id" cols="12" class="mb-2">
            <v-card elevation="2" class="pa-2 page-item" style="background-color: transparent; color: white;">
              <v-row align="center">
                <!-- Imagem reduzida no mobile -->
                <v-col cols="4" sm="2">
                  <v-img :src="page.image" aspect-ratio="0.67"></v-img>
                </v-col>

                <!-- Número da página, com fonte menor no mobile -->
                <v-col cols="4" sm="4" :class="$vuetify.breakpoint.smAndUp ? 'text-h6' : 'text-subtitle-1'">
                  Página {{ page.page }}
                </v-col>

                <!-- Botões com tamanho ajustado no mobile -->
                <v-col cols="4" sm="5" class="d-flex justify-end">
                  <!-- Botão de editar com ícone menor -->
                  <v-btn @click="openEditPageModal(page)" icon class="btn mr-2" color="white" small
                    v-bind="$vuetify.breakpoint.smAndUp ? {} : { size: 'small' }">
                    <v-icon :size="$vuetify.breakpoint.smAndUp ? undefined : 'small'">mdi-pencil</v-icon>
                  </v-btn>

                  <!-- Botão de deletar com ícone menor -->
                  <v-btn @click="deletePage(page.id)" icon class="btn" color="white" small
                    v-bind="$vuetify.breakpoint.smAndUp ? {} : { size: 'small' }">
                    <v-icon :size="$vuetify.breakpoint.smAndUp ? undefined : 'small'">mdi-delete</v-icon>
                  </v-btn>
                </v-col>

                <!-- Ícone de arrastar -->
                <v-icon color="white" class="ml-1 d-none d-sm-flex">mdi-menu</v-icon>
              </v-row>
            </v-card>
            <v-divider class="mt-2"></v-divider>
          </v-col>
        </v-row>



      </v-col>
    </v-row>

    <v-dialog v-model="isModalOpen" persistent max-width="600px">
      <v-card>
        <v-card-title class="headline">Upload de Páginas</v-card-title>
        <v-card-text>
          <input type="file" multiple @change="handleFileUpload" />
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="closeImageUploadModal">Cancelar</v-btn>
          <v-btn color="green" text @click="uploadImages">Upload</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isModalOpenEditPageOpen" persistent max-width="600px">
      <v-card>
        <v-card-title class="headline">Upload de Páginas</v-card-title>
        <v-card-text>
          <input type="file" multiple @change="EdithandleFile" />
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="closeEditPageModal">Cancelar</v-btn>
          <v-btn color="green" text @click="EditUploadImage">Upload</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="isPreviewModalOpen" fullscreen persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Visualizar Páginas</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closePreviewModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" v-for="(page, index) in chapter.pagesData" :key="index"
                class="h-screen d-flex justify-center mb-4">
                <v-img :src="page.image" contain class="h-100" style="max-width: 60%;" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPublic" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmar publicação</v-card-title>

        <v-card-text>
          <p class="text-black">Você está prestes a tornar este capítulo público. Uma vez publicado, não será possível
            torná-lo privado novamente.</p>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" text @click="dialogPublic = false">Cancelar</v-btn>
          <v-btn color="green" text @click="publishChapter">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" top>
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import Sortable from "sortablejs";
import { getChapterPages, uploadPagesImages, updatePagesChapter, publiChapterPages } from "../server/workService.js";
import TitlePageService from "@/server/TitlePageService.js";
import TitleService from "@/server/TitleService.js";
import UtilMixins from "@/mixins/UtilMixins.js";

export default {
  name: "ListPagesCapituloView",
  mixins: [UtilMixins],
  props: ["idCapitulo"],
  data() {
    return {
      isEditModalOpen: false,
      isModalOpenEditPageOpen: false,
      editableChapter: {},
      selectedPage: null, // Para armazenar a página que está sendo editada
      selectedFile: null,
      snackbar: {
        show: false,
        message: '',
        color: '',
        timeout: 2500,
      },
      isPreviewModalOpen: false,
      chapter: {
        id: null,
        name: "",
        thumb: "",
        description: "",
        pagesData: [],
        release_date: "",
        last_update: "",
      },
      isModalOpen: false,
      isPreviewOpen: false,
      selectedFiles: [],
      dialogPublic: false,
      service: new TitlePageService(),
    };
  },

  async created() {
    this.init();
  },
  methods: {

    openEditPageModal(page) {
      this.selectedPage = { ...page };
      this.isModalOpenEditPageOpen = true
    },

    closeEditPageModal() {
      this.isModalOpenEditPageOpen = false
    },
    openEditModal() {
      console.log("capitulo: ", this.chapter);
      this.editableChapter = { ...this.chapter }; // Clonar dados do capítulo para edição
      this.isEditModalOpen = true;
    },
    EdithandleFile(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file; // Armazena o arquivo selecionado
      }
    },

    async EditUploadImage() {
      // Aqui você já tem o arquivo selecionado (this.selectedFile) e as informações da página (this.selectedPage)

      // Prepare os dados que você precisa enviar para o backend
      const formData = {
        file: this.selectedFile,
        id: this.selectedPage.id, // id da página
        title_id: this.selectedPage.title_id, // id do capítulo
        page: this.selectedPage.page, // número da página
      }
      console.log("formData", formData);
      let service = new TitlePageService();
      await service.editImage(formData);
      this.init();
      this.isModalOpenEditPageOpen = false;
    },
    closeEditModal() {
      this.isEditModalOpen = false; // Fechar o modal sem salvar
    },
    async saveChanges() {
      const params = {
        id: this.chapter.id,
        project_id: this.chapter.project_id,
        name: this.editableChapter.name,
        description: this.editableChapter.description,
        title_thumb: this.editableChapter.thumb,
      }
      let service = new TitleService();
      await service.edit(params);
      this.init();
      this.$emit("update-chapter", this.editableChapter); // Emite evento para atualizar o capítulo
      this.closeEditModal(); // Fecha o modal após salvar
    },
    async init() {
      try {
        const response = await getChapterPages(this.idCapitulo);

        this.chapter = {
          ...response,
          pagesData: (response.pages_data || []).sort((a, b) => a.page - b.page),
        };
      } catch (error) {
        this.showSnackbar("Erro ao carregar páginas do capítulo:", 'error');
      }
    },
    openConfirmationDialog() {
      this.dialogPublic = true;
    },
    async publishChapter() {
      try {
        await publiChapterPages(this.idCapitulo);
        this.dialogPublic = false;
        const response = await getChapterPages(this.idCapitulo);

        this.chapter = {
          ...response,
          pagesData: (response.pages_data || []).sort((a, b) => a.page - b.page),
        };
        this.showSnackbar("Capitulo publicado com sucesso!", 'success');

      } catch (error) {
        console.log(error)
        this.showSnackbar("Erro ao publicar capitulo:", 'error');
      }

    },
    openImageUploadModal() {
      this.isModalOpen = true;
    },
    openPreviewModal() {
      this.isPreviewModalOpen = true;
    },

    closePreviewModal() {
      this.isPreviewModalOpen = false;
    },
    closeImageUploadModal() {
      this.isModalOpen = false;
    },
    handleFileUpload(event) {
      this.selectedFiles = Array.from(event.target.files);
    },
    async uploadImages() {
      const data = {
        title_id: this.idCapitulo,
        images: this.selectedFiles,
      }
      try {
        const res = await uploadPagesImages(data);
        this.chapter.pagesData = res || [];
        const response = await getChapterPages(this.idCapitulo);

        this.chapter = {
          ...response,
          pagesData: (response.pages_data || []).sort((a, b) => a.page - b.page),
        };
        this.showSnackbar("Imagens carregadas com sucesso!", 'success');
      } catch (error) {
        console.log(error);
        this.showSnackbar("Erro ao carregar imagens:", 'error');
      }
      this.closeImageUploadModal();
    },
    openPagePreview() {
      this.isPreviewOpen = true;
    },
    closePreview() {
      this.isPreviewOpen = false;
    },

    async deletePage(id) {
      let params = { id }

      await this.service.delete(params);

      this.init();
    },

    async savePagesChapter() {
      const dataNewOrder = {
        title_id: this.idCapitulo,
        pages: this.chapter.pagesData.map((page) => ({
          page_id: page.id,
          new_order: page.page,
        })),
      };
      try {
        await updatePagesChapter(dataNewOrder);
        this.chapter.pagesData = [];
        const response = await getChapterPages(this.idCapitulo);
        this.chapter = {
          ...response,
          pagesData: (response.pages_data || []).sort((a, b) => a.page - b.page),
        };
        this.showSnackbar("Ordem das páginas salva com sucesso!", 'success');
      } catch (err) {
        this.showSnackbar("Erro ao salvar a nova ordem das páginas:", 'error');
      }
    },
    showSnackbar(message, type) {
      this.snackbar.message = message;
      this.snackbar.color = type === 'success' ? 'green' : 'red';
      this.snackbar.show = true;
    },
  },
  mounted() {
    const drogArea = this.$refs.drogArea;
    new Sortable(drogArea, {
      animation: 150,
      onEnd: () => {
        const items = drogArea.children;
        const reorderedPages = Array.from(items).map((item, index) => {
          const pageId = parseInt(item.getAttribute("data-id"));
          const page = this.chapter.pagesData.find((p) => p.id === pageId);
          if (page) {
            page.page = index + 1;
          }
          return page;
        });
        this.chapter.pagesData = reorderedPages.sort((a, b) => a.page - b.page);
        this.showSnackbar("Nova ordem!", 'success');
      },
    });
  }
};
</script>






<style scoped>
.chip-not-published {
  background-color: red !important;
  /* Cor para "Não publicado" */
  color: white;
}

.chip-published {
  background-color: green !important;
  /* Cor para "Publicado" */
  color: white;
}

.text-black {
  color: #363636 !important;
}

.pa-2 {
  padding: 8px !important;
}

.pa-3 {
  padding: 16px !important;
}

.title {
  font-size: 34px !important;
  font-weight: bold;
  color: #FFFF;
}

.subtitle {
  font-size: 24px;
  font-weight: 600;
  color: #ABABAB;
  margin-top: 12px;
}

.description {
  font-size: 16px;
  font-weight: 500;
  color: #ABABAB;
}

.strong-white {
  font-size: 14px;
  font-weight: bold;
  color: #FFF;
}

.info-text {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #ABABAB;
}

.btn {
  height: 40px;
  padding: 8px;
  border-radius: 12px;
  background-color: #451755;
}
</style>
