import moment from "moment";

export default {
    methods:{
        formataData(data, hora=false){
            return data?moment(data).format(`DD/MM/YYYY ${hora? 'HH:mm': ''}`):'';
        },
    },
    filters:{
        formataData(data){
            return data?moment(data).format('DD/MM/YYYY'):'';
        },
        formataDataHora(data){
            return data?moment(data).format('DD/MM/YYYY HH:mm'):'';
        },
    }
}