import axios from "../interceptors/axios";

export const EditProject = async (data) => {
  try {
    const response = await axios.post("/api/projects/edit", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
    } else {
      console.error("Error Message:", error.message);
    }
    throw error;
  }
};
