export function storeUserData(response) {
  const userData = {
    id: response.user.id,
    name: response.user.name,
    nickname: response.user.nickname,
    email: response.user.email,
    level: response.user.level,
    profile_photo: response.user.profile_photo
  };
  localStorage.setItem('auth', JSON.stringify(response.auth));
  localStorage.setItem('user', JSON.stringify(userData));
  localStorage.setItem('permissions', JSON.stringify(response.permissions));
}