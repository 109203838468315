<template>
  <div class="carousel-section">
    <title-session-component :title="title" tag="h1" />
    <vue-slick-carousel ref="slickCarousel" :dots="true" :infinite="false" :speed="500" :slides-to-show="slidesToShow"
      :arrows="showArrows" :slides-to-scroll="1" class="carousel-container">
      <div v-for="(item, index) in items" :key="index" class="carousel-item">
        <ProjectCard v-if="type == 'projects'" :cardData="item" />
        <TitleCard v-if="type == 'titles'" :cardData="item" />
        <CommonCard v-if="type == 'last-updated'" :cardData="item" />
        <CardZinnes v-if="type == 'zinnes'" :cardData="item" />
      </div>
    </vue-slick-carousel>
  </div>
</template>

<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import VueSlickCarousel from "vue-slick-carousel";
import TitleSessionComponent from '@/components/TitleSessionComponent.vue';
import ProjectCard from './cards/ProjectCard.vue';
import TitleCard from './cards/TitleCard.vue';
import CommonCard from "./CommonCard.vue";
import CardZinnes from "./cards/CardZinnes.vue";

export default {
  name: "CarouselSection",
  components: {
    VueSlickCarousel,
    ProjectCard,
    TitleSessionComponent,
    TitleCard,
    CommonCard,
    CardZinnes
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    type: {
      type: String,
      default: 'projects',
    }
  },
  data() {
    return {
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    showArrows() {
      return window.innerWidth > 530; // Oculta as setas a partir de 430px
    },
    slidesToShow() {
      if (this.screenWidth <= 768) return 2; // Mobile
      if (this.screenWidth <= 1024) return 3; // Tablet
      if (this.screenWidth <= 1440) return 4; // Desktop médio
      if (this.screenWidth <= 2550) return 5; // Desktop grande
      return 6; // Ultrawide
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("resize", this.updateShowArrows);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("resize", this.updateShowArrows);
  },

  methods: {
    updateShowArrows() {
      this.showArrows = window.innerWidth > 430;
    },
    prev() {
      this.$refs.slickCarousel.slickPrev();
    },
    next() {
      this.$refs.slickCarousel.slickNext();
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
      this.isMobile = this.screenWidth <= 768;
    },
  },
};
</script>
<style scoped>
.carousel-item {
  padding: 10px;
}

.custom-arrow {
  font-size: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: white;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.custom-arrow:hover {
  opacity: 1;
}

.left-arrow {
  left: 15px;
}

.right-arrow {
  right: 15px;
}

@media (max-width: 768px) {
  .custom-arrow {
    font-size: 30px;
  }
}

.carousel-container {
  border-radius: 8px;
  gap: 14px;
}

.carousel-item {
  margin: 0 10px;
}

.carousel-item:first-of-type {
  margin-left: 0;
}

.carousel-item:last-of-type {
  margin-right: 0;
}

.custom-arrow {
  font-size: 30px;
  /* Tamanho do ícone padrão */
  color: white;
  /* Cor do ícone */
  cursor: pointer;
  /* Indica que é clicável */
  width: 30px;
  /* Largura padrão */
  height: 30px;
  /* Altura padrão */
  display: inline-flex;
  /* Para centrar o conteúdo */
  align-items: center;
  /* Centraliza verticalmente */
  justify-content: center;
  /* Centraliza horizontalmente */
}

/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
  .custom-arrow {
    width: 20px;
    /* Menor largura para ícones em dispositivos móveis */
    height: 20px;
    /* Menor altura para ícones em dispositivos móveis */
    font-size: 20px;
    /* Reduz o tamanho do ícone */
  }

  .custom-arrow {
    display: none;
  }

  .carousel-item {
    max-width: 150px !important;
    /* Ajuste o tamanho máximo dos itens para dispositivos móveis */
  }

  .carousel-section h2 {
    font-size: 1.25rem;
    /* Ajusta o tamanho do título */
    text-align: center;
    /* Centraliza o texto do título */
  }



}
</style>
