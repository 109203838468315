<template>
    <v-row>
      <v-col>
        <div class="title-session">
          <div class="divider"></div>
          <h2 :tag="tag" class="title-text">
            {{ title }}
          </h2>
        </div>
      </v-col>
    </v-row>
</template>


<script>
export default {
  name: 'TitleSessionComponent',
  props: {
    title: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      default: 'h1'
    }
  }
}
</script>

<style scoped>

.title-session {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
}
.divider {
  width: 4px;
  height: 30px;
  border-radius: 5px;
  background-color: #fff;
}

.title-text {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

@media (max-width: 1024px) {
  .title-session {
    width: 350px;
    height: 45px;
  }

  .title-text {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .title-session {
    width: 300px;
    height: 40px;
  }

  .title-text {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .title-session {
    width: 250px;
    height: 35px;
    gap: 8px;
  }

  .title-text {
    font-size: 16px;
  }

  .divider {
    height: 25px;
  }
}

@media (max-width: 320px) {
  .title-session {
    width: 200px;
    height: 30px;
    gap: 5px;
    padding: 1px 6px;
  }

  .title-text {
    font-size: 14px;
  }

  .divider {
    height: 20px;
  }
}
</style>


