<template>
  <div class="banner-serie">
    <div class="container-details">
      <div class="details">
        <div class="image-serie">
          <img class="image-banner" :src="obra.project_thumb" alt="capa">
        </div>
        <div class="details-serie">
          <v-btn color="#451755" class="custom-btn" @click="gottoAuthor(author.id)">Visitar autor</v-btn>
          <h1>{{ obra.name }}</h1>
          <v-row class="my-2">
            <v-chip color="#451755" text-color="white">{{ obra.category ? obra.category.name : '' }}</v-chip>
          </v-row>

          <v-row class="decription">
            <p>{{ obra.description }}</p>
          </v-row>

          <div class="container-buttons">
            <v-col cols="auto" class="d-flex justify-space-between" style="gap: 16px;">
              <v-btn class="custom-btn" :large="$vuetify.breakpoint.smAndUp" color="#451755" @click="favorite(obra.id)">
                <v-icon :size="$vuetify.breakpoint.smAndDown ? '24px' : '36px'" color="white">
                  {{ obra.is_favorite ? 'mdi-bookmark' : 'mdi-bookmark-outline' }}
                </v-icon>
              </v-btn>

              <v-btn :large="$vuetify.breakpoint.smAndUp" color="#451755" @click="like(obra.id)">
                <v-icon class="custom-btn" :size="$vuetify.breakpoint.smAndDown ? '24px' : '36px'" color="white">
                  {{ obra.is_like ? 'mdi-heart' : 'mdi-heart-outline' }}
                </v-icon>
              </v-btn>

              <v-btn class="custom-btn" :large="$vuetify.breakpoint.smAndUp" color="#451755" @click="follow(obra.id)">
                <v-icon :size="$vuetify.breakpoint.smAndDown ? '24px' : '36px'" color="white">
                  {{ obra.is_follow ? 'mdi-bell' : 'mdi-bell-outline' }}
                </v-icon>
              </v-btn>
            </v-col>

            <div cols="12" class="d-flex mt-3">
              <v-btn class="custom-btn" :disabled="idChapter == 0" @click="goToReadingPage(obra.category.name)"
                :large="$vuetify.breakpoint.smAndUp" color="#451755" text-color="white">
                Iniciar leitura
              </v-btn>
            </div>
          </div>

        </div>

      </div>
      <div class="raking-serie">
        <v-row class="my-2">
          <v-icon color="purple">mdi-heart</v-icon>
          {{ obra.likes_count }} like
        </v-row>
        <strong>Ano de publicação:</strong> <span>{{ publicationYear }}</span>
        <p class="mt-2"> <strong>View:</strong> <span>{{ obra.views }}</span></p>
        <p class="mt-2"><strong>Tipo:</strong> <span>{{ obra.type ? obra.type.name : '' }}</span></p>
        <p class="mt-2"><strong>Status: </strong><span>Lançamento</span></p>
        <p class="mt-2 click" @click="gottoAuthor(author.id)"><strong>Autor:</strong> <span>{{ author.name }}</span></p>
      </div>
    </div>
  </div>
</template>


<script>
import UtilMixins from '@/mixins/UtilMixins';
import { FavoriteProjectService } from '@/server/FavoriteService';
import { FollowProjectService } from '@/server/FollowtService';
import { LikeProjectService } from '@/server/LikeService';
import moment from 'moment';

export default {
  name: 'BannerSerie',
  mixins: [UtilMixins],
  props: {
    obra: {
      type: Object,
      required: true,
    },
    author: {
      type: Object,
      required: true,
    },
    idChapter: {
      type: Number,
      default: 0,
    }
  },

  methods: {
    gottoAuthor(authorId) {
      this.$router.push(`/autor/${authorId}`)
    },
    goToReadingPage(type) {
      if (type === 'Livros') {
        console.log("Dentro do IF", type)
        this.$router.push(`/obra/book/reading/${this.idChapter}`);
      } else {
        this.$router.push(`/${this.idChapter}/page`);
      }
    },
    async follow(project_id) {
      let params = {
        project_id
      }

      let service = new FollowProjectService();
      let res = await service.store(params);
      this.$emit('update-obra');
      console.log(res);
    },
    async like(project_id) {
      let params = {
        project_id
      }

      let service = new LikeProjectService();
      let res = await service.store(params);
      this.$emit('update-obra');
      console.log(res);
    },
    async favorite(project_id) {
      let params = {
        project_id
      }

      let service = new FavoriteProjectService();
      let res = await service.store(params);
      this.$emit('update-obra');
      console.log(res);
    },
  },

  computed: {
    publicationYear() {
      return moment(this.obra.created_at).year();
    },
  },
}

</script>


<style scoped>
.custom-btn {
  color: #fff !important;
}

h3 {
  cursor: pointer;
}

.container-buttons {
  display: flex !important;
  gap: 16px;
}

p {
  color: #fff !important;
}

.image-serie {
  width: 280px;
  height: 450px;
}

.banner {
  width: 100%;
  height: 20vh;
  background-color: #1b1818
}

.image-banner {
  width: 100%;
  height: 100%;
}

.click {
  cursor: pointer;
}

.my-2 {
  display: flex;
  gap: 20px;
}

.details {
  display: flex;
  align-items: center;
  gap: 30px;
}

.description {
  max-height: 150px;
  /* Altura máxima */
  overflow: hidden;
  text-overflow: ellipsis;
}

.decription p {
  font-size: 20px
}

.details-serie h1 {
  font-size: 64px;
  font-weight: 800;
}

.banner-serie {
  height: 20vh;
  color: #fff;
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  padding: 0 16px;
  flex: 1;
}

.container-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 100px;
  padding: 12px 64px;
}


@media (max-width: 900px) {
  .container-details {
    padding: 8px 16px;
  }

  .details-serie h1 {
    font-size: 34px;
  }

  .decription {
    width: 100%;
  }

  .image-serie {
    width: 200px;
    height: 350px;
  }

  .raking-serie {
    display: none
  }

  .details {
    width: 100% !important;
    justify-content: space-between;
  }
}

@media (max-width: 400px) {
  .container-buttons {
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    gap: 16px;
  }

  .container-details {
    width: 100%;
    padding: 8px 16px;
  }

  .details-serie h1 {
    font-size: 34px;
  }

  .decription {
    width: 100%;
  }

  .image-serie {
    width: 200px;
    height: 350px;
  }

  .raking-serie {
    display: none
  }

  .details {
    flex-direction: column;
    width: 100% !important;
    justify-content: space-between;
  }
}

@media (max-width: 1024px) {
  .raking-serie {
    display: none;
  }
}
</style>