<template>
  <v-container class="container-painel" fluid>
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="pa-5 bg-purple darken-4 white--text text-center">
          <v-card-title class="flex justify-center align-center">
            <v-icon size="40">mdi-eye</v-icon>
            <div class="text-h4 ml-2">{{ painelData.views || 0 }}</div>
          </v-card-title>
          <div class="text-center mt-2 custom-text">
            Visualizações
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="pa-5 bg-purple darken-4 white--text text-center">
          <v-card-title class="flex justify-center align-center">
            <v-icon size="40">mdi-heart</v-icon>
            <div class="text-h4 mt-2">{{ painelData.likes || 0 }}</div>
          </v-card-title>
          <div class="custom-text mt-2">Curtidas</div>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="pa-5 bg-purple darken-4 white--text text-center">
          <v-card-title class="flex justify-center align-center">
            <v-icon size="40">mdi-account-group</v-icon>
            <div class="text-h4 mt-2">{{ painelData.followers || 0 }}</div>
          </v-card-title>
          <div class="custom-text mt-2">Seguidores</div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-card class="pa-5 card-container">
          <v-card-title class="text-h6 custom-text">Histórico de Curtidas</v-card-title>
          <v-list dense class="scrollable-list list-item">
            <v-list-item v-for="(like, index) in painelData.like_history || []" :key="index" class="hover-item">
              <v-list-item-avatar>
                <v-avatar size="40">
                  <img :src="like.avatar || 'https://via.placeholder.com/40'" alt="Avatar" />
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="grey--text">{{ like.name }}</v-list-item-title>
                <v-list-item-subtitle class="grey--text lighten-1">Obra: {{ like.work }}</v-list-item-subtitle>
                <v-list-item-subtitle class="grey--text lighten-2">Data: {{ like.date }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-pagination color="#451755" 
            v-model="likes.current_page" 
            :length="likes.last_page"
            total-visible="5"
            @input="commentList"
            ></v-pagination>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="pa-5 card-container">
          <v-card-title class="text-h6 custom-text">Comentários não respondidos</v-card-title>
          <v-divider></v-divider>
          <v-list dense class="scrollable-list list-item">
            <v-list-item v-for="(comment, index) in comments.data || []" :key="index"
              class="hover-item">
              <v-list-item-avatar>
                <v-avatar size="40">
                  <img :src="comment.user.profile_photo || 'https://via.placeholder.com/40'" alt="Avatar" />
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="grey--text">{{ comment.user.nickname }}</v-list-item-title>
                <v-list-item-subtitle class="grey--text">
                  {{ comment.text }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="openCommentModal(comment)">
                  <v-icon color="purple">mdi-eye</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-pagination color="#451755" 
            v-model="comments.current_page" 
            :length="comments.last_page"
            total-visible="5"
            @input="commentList"
            ></v-pagination>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showCommentModal" max-width="600px">
    <v-card dark>
      <!-- Cabeçalho com foto e nome do usuário -->
      <v-card-title>
        <v-avatar size="40" class="mr-3">
          <img :src="selectedComment?.user.profile_photo || 'https://via.placeholder.com/40'" alt="Foto do Usuário" />
        </v-avatar>
        <span class="text-h6">{{ selectedComment?.user.nickname || "Usuário desconhecido" }}</span>
      </v-card-title>
      
      <v-card-text>
        <!-- Comentário -->
        <div class="mb-4">
          <strong>Comentário:</strong>
          <p>{{ selectedComment?.text || "Comentário não disponível" }}</p>
        </div>
        
        <!-- Caixa de texto para resposta -->
        <v-textarea 
          v-model="replyText" 
          label="Resposta" 
          outlined 
          rows="4">
        </v-textarea>
      </v-card-text>
      
      <!-- Botões de ação -->
      <v-card-actions>
        <v-btn color="red" text @click="closeCommentModal">Cancelar</v-btn>
        <v-btn color="purple" text @click="sendReply">Enviar Resposta</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-container>
</template>

<script>
import { fetchAuthorManagerData } from "@/server/authorADMService";
import CommentTitleService from "@/server/CommentTitleService";

export default {
  data() {
    return {
      page: 1,
      page2: 1,
      painelData: {
        views: 0,
        likes: 0,
        followers: 0,
      },
      likes:{
        data:[],
        current_page:1,
        last_page:1,
      },
      comments:{
        data:[],
        current_page:1,
        last_page:1,
      },
      showCommentModal: false,
      selectedComment: null,
      replyText: "",
      comment_service: new CommentTitleService(),
    };
  },
  created() {
    this.fetAllPainelData();
    this.commentList();
  },
  methods: {
    async commentList(page=1){
      let params = {
        page,
      };
      const response = await this.comment_service.index(params);
      console.log('commentList',response);
      
      this.comments = response;

    },
    async fetAllPainelData() {
      try {
        const response = await fetchAuthorManagerData();
        this.painelData = response || {};
      } catch (error) {
        console.log("Erro ao buscar dados do painel:", error.message);
      }
    },
    openCommentModal(comment) {
      this.selectedComment = comment;
      this.replyText = "";
      this.showCommentModal = true;
    },
    closeCommentModal() {
      this.showCommentModal = false;
      this.selectedComment = null;
    },
    sendReply() {
      if (!this.replyText.trim()) {
        alert("A resposta não pode estar vazia.");
        return;
      }
      console.log("Enviando resposta:", this.replyText);
      // Aqui você pode fazer a lógica para enviar a resposta
      this.closeCommentModal();
    },
  },
};

</script>


<style scoped>
.v-card {
  border-radius: 10px;
}

.bg-purple {
  background-color: #451755 !important;
}

.pa-5 {
  padding: 20px;
}

.v-pagination {
  justify-content: center;
  margin-top: 20px;
}

.flex {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.custom-text {
  font-size: 26px;
  font-weight: 600;
  color: #fff !important;
}

.card-container {
  max-width: 770px;
  height: 650px;
}

.scrollable-list {
  height: 500px;
  overflow-y: auto;
}

.v-card {
  background-color: #121212;
}

.list-item {
  background-color: #121212;
}

.hover-item:hover {
  background-color: #222121;
}
@media (max-width: 600px) {
  .container-painel{
    margin: 80px 0px;
  }
}
</style>
