import axios from "../interceptors/axios";

export default class TitlePageService {
  delete = async (params) => {
    try {
      const response = await axios.delete("/api/title_pages/delete", {
        params,
      });
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
      throw error;
    }
  };

  editImage = async (data) => {
    try {
      const response = await axios.post("/api/title_pages/edit", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("auth")).access_token
          }`,
        },
      });
      console.log(response);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };
}
