import axios from "../interceptors/axios";

// Função para criar um capítulo de um livro
export const createChapter = async (data) => {
  try {
    const response = await axios.post(
      "/api/books",
      {
        project_id: data.project_id,
        title: data.title,
        content: data.content,
        published: data.published,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { success: false, message: "Error occurred" };
  }
};

// Função para listar capítulos de um livro
export const listChapters = async (bookId, published = null) => {
  try {
    const url =
      published !== null
        ? `/api/books/${bookId}?published=${published}`
        : `/api/books/${bookId}`;

    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
      },
    });
    return response.data.chapters;
  } catch (error) {
    return error.response
      ? error.response.data
      : { success: false, message: "Error occurred" };
  }
};

// Função para atualizar um capítulo de um livro
export const updateChapter = async (chapterId, data) => {
  try {
    const response = await axios.put(
      `/api/books/${chapterId}`,
      {
        title: data.title,
        content: data.content,
        published: data.published,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Erro publish", error);
    return error.response
      ? error.response.data
      : { success: false, message: "Error occurred" };
  }
};
