import axios from "axios";

// Criando uma instância personalizada do axios
const instance = axios.create({
  baseURL: "https://homolog.apizinnes.com.br/", // Base URL da sua API
  timeout: 20000, // Tempo limite da requisição
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

let isRefreshing = false; // Flag para evitar múltiplas requisições de refresh token
let failedQueue = []; // Fila de requisições que falharam devido à expiração do token

// Função para processar a fila de requisições quando o token for atualizado
const processQueue = (error, token = null) => {
  failedQueue.forEach((promise) => {
    if (token) {
      promise.resolve(token); // Resolve as promessas da fila com o novo token
    } else {
      promise.reject(error); // Rejeita as promessas caso haja erro
    }
  });

  failedQueue = []; // Limpa a fila após o processamento
};

// Interceptor de requisição para adicionar o token de autorização
instance.interceptors.request.use(
  (config) => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth && auth.access_token) {
      config.headers["Authorization"] = `Bearer ${auth.access_token}`; // Adiciona o token no cabeçalho
    }

    return config;
  },
  (error) => {
    return Promise.reject(error); // Caso haja erro na requisição, rejeita a promessa
  }
);

// Interceptor de resposta para tratar erros 401 e fazer o refresh do token
instance.interceptors.response.use(
  (response) => {
    return response.data; // Retorna a resposta da API
  },
  async (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
      const originalRequest = error.config; // A requisição original que falhou

      if (!originalRequest._retry) {
        originalRequest._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;

          try {
            const auth = JSON.parse(localStorage.getItem("auth"));
            // Envia a requisição para o refresh token
            const response = await axios.post(
              "https://homolog.apizinnes.com.br/api/refresh-token", // URL da API para refresh token
              {}, // Corpo da requisição (geralmente vazio)
              {
                headers: {
                  Authorization: `Bearer ${auth.refresh_token}`, // Envia o refresh token no header
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
                withCredentials: true, // Permite o envio de cookies (se necessário)
              }
            );

            console.log("Refresh token response:", response.data);
            const newAuth = response.data; // Novo auth com o access token
            localStorage.setItem("auth", JSON.stringify(newAuth)); // Salva o novo token no localStorage

            // Processa a fila de requisições pendentes
            processQueue(null, newAuth.access_token);

            isRefreshing = false;

            // Adiciona o novo token ao cabeçalho da requisição original
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${newAuth.access_token}`;

            return instance(originalRequest); // Reenvia a requisição original com o novo token
          } catch (refreshError) {
            processQueue(refreshError, null); // Caso haja erro no refresh token
            isRefreshing = false;
            console.log("Erro ao atualizar o token:", refreshError);
            // Redireciona para a página de login se falhar ao atualizar o token
            window.location.href = "/#/login";
            return Promise.reject(refreshError); // Rejeita a promessa com erro
          }
        }

        // Caso o refresh token já esteja sendo atualizado, adiciona a requisição à fila
        return new Promise((resolve, reject) => {
          failedQueue.push({
            resolve: (token) => {
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
              resolve(instance(originalRequest)); // Resolve a requisição original com o novo token
            },
            reject: (err) => {
              reject(err); // Rejeita a requisição original em caso de erro
            },
          });
        });
      }
    }

    return Promise.reject(error); // Caso não seja erro 401, rejeita a promessa
  }
);

export default instance; // Exporta a instância personalizada do axios
