<template>
  <v-app class="app-main">
    <header-series />
    <div class="reader-container">
      <div class="aligner">
        <div class="chapter-header">
          <h2>{{ chapter.name }}</h2>
          <v-select v-model="readingMode" :items="['Vertical', 'Horizontal']" label="Modo de Leitura" dark dense
            outlined class="reading-mode-selector" />
        </div>

        <!-- Modo de leitura VERTICAL -->
        <div v-if="readingMode === 'Vertical'" class="read-vertical">
          <div class="image-container">
            <img v-for="(page, index) in chapter.pagesData" :key="index" :src="page.image" :alt="'Página ' + page.page"
              class="responsive-image" />
          </div>
        </div>

        <!-- Modo de leitura HORIZONTAL -->
        <div v-else class="read-horizontal">
          <v-carousel v-model="currentPageIndex" hide-delimiters show-arrows :height="carouselHeight">
            <v-carousel-item v-for="(page, index) in chapter.pagesData" :key="index">
              <img :src="page.image" :alt="'Página ' + page.page" class="carousel-image" />
            </v-carousel-item>
          </v-carousel>
        </div>

        <widget-read-menu :next_chapter="chapter.next_chapter" :previous_title="chapter.previous_title"
          :idObra="chapter.project_id" @update-chapter="fetchChapter" />
      </div>

    </div>
    <CommentsChapter :idChapter="idChapter" :key="idChapter" />

  </v-app>
</template>

<script>
import WidgetReadMenu from "@/components/WidgetReadMenu.vue";
import CommentsChapter from "@/components/CommentsChapter.vue";
import TitleService from "@/server/TitleService";

export default {
  name: "ObraPageView",
  props: ["idChapter"],
  components: {
    WidgetReadMenu,
    CommentsChapter,
  },
  data: () => ({
    chapter: {
      id: null,
      name: "",
      pagesData: [],
      next_chapter: null,
      previous_title: null,
    },
    readingMode: "Vertical",
    currentPageIndex: 0,
    service: new TitleService(),
  }),

  watch: {
    idChapter: {
      immediate: true,
      handler() {
        this.fetchChapter();
      },
    },
    readingMode() {
      this.currentPageIndex = 0;
    },
  },
  computed: {
    carouselHeight() {
      return window.innerHeight * 0.9 + 'px';
    }
  },

  methods: {
    async fetchChapter() {
      try {
        const response = await this.service.view(this.idChapter);
        this.chapter = {
          ...response,
          pagesData: (response.pages_data || []).sort((a, b) => a.page - b.page),
          next_chapter: response.next_title ? response.next_title.id : null,
          previous_title: response.previous_title ? response.previous_title.id : null,
        };
        this.currentPageIndex = 0;
        console.log("Capítulo carregado:", this.chapter);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } catch (error) {
        console.error("Erro ao carregar páginas do capítulo:", error);
      }
    },
  },
};
</script>

<style scoped>
.carousel-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  /* Mantém a altura do carrossel */
}

.carousel-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  /* Garante que a imagem se ajuste sem cortar */
}

/* Container principal */
.reader-container {
  max-width: 900px;
  width: 90%;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Cabeçalho do capítulo */
.chapter-header {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

/* Estilo para o seletor de leitura */
.reading-mode-selector {
  max-width: 200px;
  margin: 10px auto;
}

/* Modo de leitura vertical */
.read-vertical .image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

/* Modo de leitura horizontal */
.read-horizontal .carousel-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

/* Ajustes para telas menores */
@media (max-width: 768px) {
  .reader-container {
    width: 95%;
    padding: 15px;
  }

  .reading-mode-selector {
    max-width: 100%;
  }
}
</style>
