import axios from "../interceptors/axios";
export const fetchComments = async (chapterId) => {
  console.log("Service> GET ", chapterId);
  try {
    const response = await axios.get(`/api/comments/title/index/${chapterId}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    console.error(
      "Erro ao buscar comentários:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const createComment = async (titleId, text, referenceId = null) => {
  console.log("Service> ", titleId, text, referenceId);
  try {
    const response = await axios.post("/api/comments/title/create", {
      title_id: titleId,
      text: text,
      reference_id: referenceId,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erro ao criar comentário:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const toggleLikeComment = async (commentId) => {
  console.log("Service> ", commentId);
  try {
    await axios.post("/api/comments/title/like", {
      comment_title_id: commentId,
    });
  } catch (error) {
    console.error(
      "Erro ao curtir ou descurtir comentário:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const updateComment = async (commentId, newText) => {
  try {
    const response = await axios.put("/api/comments/title/update", {
      id: commentId,
      text: newText,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erro ao atualizar comentário:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const deleteComment = async (commentId) => {
  try {
    const response = await axios.delete(
      `/api/comments/title/delete/${commentId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erro ao excluir comentário:",
      error.response?.data || error.message
    );
    throw error;
  }
};
