<template>
  <div style="align-self: self-end;">
    <div>
      <v-card-actions class="outer-div">
        <div>
          <v-btn @click="shareOnWhatApp" color="purple" class="btn-heart" dark>
            <v-icon>mdi-share-variant</v-icon>
          </v-btn>
        </div>
        <div>
          <v-btn @click="backChapter" color="purple" class="btn-heart" dark :disabled="!previous_title" >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </div>
        <div>
          <v-btn @click="nextChapter" color="purple" class="btn-heart" dark :disabled="!next_chapter">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <div>
          <v-btn @click="openMenuModal" color="purple" class="btn-heart" dark>
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </div>
      </v-card-actions>
    </div>
    <v-dialog v-model="menuModalOpen" max-width="600" dark>
      <v-card>
        <v-card-title>Menu</v-card-title>
        <v-col v-for="(chapter, index) in chapters" :key="index" cols="12" md="12" lg="8">
          <v-card class="d-flex flex-row align-center mb-4 card-obra" @click="goToReadingPage(chapter.id)" hover>
            <v-img :src="chapter.title_thumb" alt="Capa do capítulo" class="chapter-image" height="130"
              width="90"></v-img>
            <v-card-text>
              <div class="font-weight-bold chapter-title">{{ chapter.name }}</div>
              <p>{{ chapter.description }}</p>
              <div class="chapter-date">{{ chapter.publication_date }}</div>
              <v-row clas="align-center mt-2 chapter-info" dense>
                <v-col cols="auto" class="d-flex align-center chapter-icon-text">
                  <v-icon small color="#fff">mdi-message</v-icon>
                  {{ chapter.comments || 0 }}
                </v-col>
                <v-col cols="auto" class="d-flex align-center chapter-icon-text">
                  <v-icon small color="#fff">mdi-eye</v-icon>
                  {{ chapter.views || 0 }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-card-text>
          <v-btn @click="closeMenuModal" color="error" block>Fechar</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>


</template>

<script>
import { getProjectDetailsById } from '@/server/homeServe';

export default {
  name: 'WidgetReadMenu',
  data: () => {
    return {
      menuModalOpen: false,
      chapters: [],
    }
  },
  props: {
    next_chapter: {
      type: [String, Number, null],
      default: null
    },
    previous_title: {
      type: [String, Number, null],
      default: null
    },
    idObra: {
      type: [String, Number],
      required: true
    }
  },
  methods: {
    async openMenuModal() {
      console.log('openMenuModal', this.previous_title);
      this.menuModalOpen = true;
      const data = await this.fetchObra();
      this.chapters = data.titles;
    },
    closeMenuModal() {
      this.menuModalOpen = false;
    },
    shareOnWhatApp() {
      const pageUrl = encodeURIComponent(window.location.href);
      const message = encodeURIComponent('Confira este link: ')
      const whatsappUrl = `https://wa.me/?text=${message}${pageUrl}`;

      window.open(whatsappUrl, '_blank');
    },

    nextChapter() {
      console.log('nextChapter', this.next_chapter);
      this.$router.push(`/${this.next_chapter}/page`);
      this.$emit('update-chapter');
    },

    backChapter() {
      console.log('backChapter', this.previous_title);
      this.$router.push(`/${this.previous_title}/page`);
      this.$emit('update-chapter');
    },

    async fetchObra() {
      try {
        const data = await getProjectDetailsById(this.idObra);
        this.obra = data.project;
        this.author = data.author;
        this.chapters = data.titles;
        this.similar_projects = data.similar_projects;
        this.author_projects = data.author_projects;
      } catch (error) {
        console.error('Erro ao buscar obra:', error);
      }
    },
    goToReadingPage(idChapter) {
      this.$router.push(`/${idChapter}/page`);
      this.closeMenuModal();
    },
  }
}
</script>


<style scoped>
p {
  color: #fff !important;
}
.outer-div {
  position: fixed;
  bottom: 50px;
  right: 30px;
  border: 2px solid #000;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 30px;
  align-self: self-end;
}

.card-obra {
  width: 100% !important;
}
</style>