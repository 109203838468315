import axios from "../interceptors/axios";

// Função para pegar todos os projetos
export const getAllProjects = async () => {
  try {
    const response = await axios.get(`/api/projects`, {
      headers: {
        Accept: "application/json",
      },
    });
    console.log(response.data);
    return response.data.my_projects;
  } catch (error) {
    console.error("Erro ao buscar projetos do autor:", error);
    throw error.response ? error.response.data : error.message;
  }
};

// Função para pegar projetos por nome
export const getProjectsByName = async (name) => {
  console.log("Query:", name);
  try {
    const response = await axios.get(`/api/search/titles/${name}`, {
      headers: {
        Accept: "application/json",
      },
    });
    console.log("Resposta:", response.data);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar projetos:", error);
    throw error.response ? error.response.data : error.message;
  }
};

// Função para pegar projetos com parâmetros de busca
export const getSearchProjects = async (params) => {
  console.log("Query:", params);
  try {
    const response = await axios.get(`/api/search/projects`, {
      params,
      headers: {
        Accept: "application/json",
      },
    });
    console.log("Resposta:", response.data);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar projetos:", error);
    throw error.response ? error.response.data : error.message;
  }
};
export const saveWork = async (dataWork) => {
  try {
    const response = await axios.post("/api/projects", dataWork, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    if (error.response) {
      console.error("Erro:", error.response.data);
      throw new Error(
        `Erro ${error.response.status}: ${error.response.data.message}`
      );
    } else {
      console.error("Erro:", error.message);
      throw new Error(error.message);
    }
  }
};

// Função para pegar projetos do autor
export const getAuthorProjects = async () => {
  const userData = JSON.parse(localStorage.getItem("user"));
  try {
    const response = await axios.get(`/api/author/projects/${userData.id}`, {
      headers: {
        Accept: "application/json",
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar projetos do autor:", error);
    throw error.response ? error.response.data : error.message;
  }
};

// Função para pegar capítulos de um projeto
export const getchapters = async (idProjet) => {
  try {
    const response = await axios.get(`/api/projects/titles/${idProjet}`, {
      headers: {
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar capítulos:", error);
    throw error.response ? error.response.data : error.message;
  }
};

// Criar um capítulo novo
export const publiChapter = async (idProjet, dataChapter) => {
  const formData = {
    project_id: idProjet,
    name: dataChapter.nome,
    description: dataChapter.descricao,
    title_thumb: dataChapter.file,
  };

  try {
    const response = await axios.post(`api/titles/create`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.error("Erro ao enviar o capítulo:", error);
    throw error.response ? error.response.data : error.message;
  }
};

// Buscar as informações do capítulo e as páginas
export const getChapterPages = async (idChapter) => {
  try {
    const response = await axios.get(`/api/titles/pages/index/${idChapter}`, {
      headers: {
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar capítulos:", error);
    throw error.response ? error.response.data : error.message;
  }
};

// Tornar um capítulo público
export const publiChapterPages = async (idChapter) => {
  try {
    const response = await axios.put(`/api/titles/publish/${idChapter}`, {
      headers: {
        Accept: "application/json",
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Erro ao tornar o capítulo público:", error);
    throw error.response ? error.response.data : error.message;
  }
};

// Salvar as páginas
export const uploadPagesImages = async (pages) => {
  try {
    const response = await axios.post(`/api/titles/pages/upload`, pages, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao salvar páginas do capítulo:", error.response?.data || error.message);
    throw error.response ? error.response.data : error.message;
  }
};

// Atualizar a ordem das páginas
export const updatePagesChapter = async (formData) => {
  try {
    const response = await axios.put(`/api/titles/pages/reorder`, formData, {
      headers: {
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao atualizar páginas:", error.response?.data || error.message);
    throw error.response ? error.response.data : error.message;
  }
};

// Obter categorias
export const getCategorys = async () => {
  try {
    const response = await axios.get(`/api/project_category`);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar categorias:", error);
    throw error.response ? error.response.data : error.message;
  }
};

// Obter idiomas
export const getLanguages = async () => {
  try {
    const response = await axios.get(`/api/language`);
    return response;
  } catch (error) {
    console.error("Erro ao buscar idiomas:", error);
    throw error.response ? error.response.data : error.message;
  }
};

// Obter gêneros
export const getGenders = async () => {
  try {
    const response = await axios.get(`/api/gender`);
    console.log({ response });
    return response;
  } catch (error) {
    console.error("Erro ao buscar gêneros:", error);
    throw error.response ? error.response.data : error.message;
  }
};

// Obter tipos de projeto
export const getProjectType = async () => {
  try {
    const response = await axios.get(`/api/project_type`);
    return response;
  } catch (error) {
    console.error("Erro ao buscar tipos de projeto:", error);
    throw error.response ? error.response.data : error.message;
  }
};

// Incrementar visualizações do título
export const putViewTitle = async (id) => {
  try {
    const response = await axios.put(`/api/titles/${id}/view`);
    console.log(response);
  } catch (error) {
    console.error("Erro ao atualizar visualizações:", error);
    throw error.response ? error.response.data : error.message;
  }
};
