<template>
  <v-container class="d-flex align-center justify-center fill-height">
    <div v-if="!emailEnviado">
      <v-card-title class="justify-center">
        <v-avatar size="80" color="#451755">
          <v-icon large color="#FFF">mdi-key</v-icon>
        </v-avatar>
      </v-card-title>

      <v-card-subtitle class="text-center">
        <img src="@/assets/images/Rectangle 3.png" alt="Logo Zinnes" class="mb-4">
        <h3 class="headline mb-2 font-bold">Esqueceu sua senha?</h3>
        <p class="text-color">Não se preocupe, enviaremos instruções de redefinição de senha</p>
      </v-card-subtitle>

      <v-form @submit.prevent="submitForm">
        <v-text-field
          v-model="email"
          label="Digite seu email..."
          outlined
          required
        ></v-text-field>

        <v-btn type="submit" color="#451755" block large class="mb-2 btn-send font-bold">
          Enviar
        </v-btn>
      </v-form>

      <v-card-actions class="justify-end">
        <v-btn text color="purple" @click="goToLogin">
          <v-icon left>mdi-arrow-left</v-icon> Fazer login
        </v-btn>
      </v-card-actions>
    </div>

    <div v-else class="text-center">
      <div class="flex-column">
        <v-avatar size="80" class="mb-4" color="green">
          <v-icon large color="#FFF">mdi-email-check-outline</v-icon>
        </v-avatar>
        <img src="@/assets/images/Rectangle 3.png" alt="Logo Zinnes" class="mb-4">
      </div>

      <h3 class="headline mb-2 font-bold">Verifique seu Email</h3>
      <p class="text-color">Enviamos um link de redefinição de senha para <span class="email-highlight">{{ email }}</span></p>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      emailEnviado: false,
    };
  },
  methods: {
    submitForm() {
      if (this.email) {
        this.emailEnviado = true;
        console.log(`Enviando email de recuperação para: ${this.email}`);
      }
    },
    goToLogin() {
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.fill-height {
  min-height: 100vh;
}

.btn-send {
  color: #FFF;
}

.text-color {
  color: #000;
}

.font-bold {
  font-weight: bold;
}

.email-highlight {
  color: orange;
  font-weight: bold;
}

.flex-column{
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
