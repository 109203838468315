<template>
  <router-view>
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
.v-application--wrap {
  background-color: #11111a !important;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.9s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>