<template>
  <v-footer color="black">
    <div class="footer-container">
      <v-row justify="space-between" align="center">
        <v-col cols="12" md="3" class="text-center text-md-left">
          <v-img src="@/assets/images/image-footer.png" alt="Zinnes Logo" max-width="350" />
        </v-col>
        <v-col cols="12" md="6">
          <v-row no-gutters class="justify-center">
            <v-col cols="6" class="pr-2">
              <v-list-item-group>
                <v-list-item v-for="(link, index) in menuLeft" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>
                      <router-link :to="link.href" class="footer-link">{{ link.text }}</router-link>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-col>
            <v-col cols="6" class="pl-2">
              <v-list-item-group>
                <v-list-item v-for="(link, index) in menuRight" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>
                      <router-link :to="link.href" class="footer-link">{{ link.text }}</router-link>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3" class="text-center text-md-right">
          <h3 class="text-h6 mb-3">Redes Sociais</h3>
          <v-btn icon v-for="icon in socialIcons" :key="icon" class="mx-1">
            <v-icon color="#FFF" size="34">{{ icon }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row justify="space-between" align="center" class="mt-4">
        <v-col cols="12" md="6" class="text-center text-md-left">
          <p>© 2024 Zinnes. Todos os direitos reservados.</p>
        </v-col>
        <v-col cols="12" md="6" class="text-center text-md-right">
          <p>
            Feito com <v-icon color="red" small>mdi-heart</v-icon> por
            <a href="#" class="footer-link">BYCODE AI</a>
          </p>
        </v-col>
      </v-row>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      menuLeft: [
        { text: "Home", href: "/" },
        { text: "Comunidade", href: "/comunidade" },
        { text: "Publicar", href: "/dashboard/publicar-obra/#/dashboard/publicar-obra" },
      ],
      menuRight: [
        { text: "Política de Privacidade", href: "#" },
        { text: "Termos de Uso", href: "#" },
        { text: "Informações", href: "#" },
        { text: "Ajuda", href: "#" },
      ],
      socialIcons: ["mdi-facebook", "mdi-instagram"],
    };
  },
};
</script>

<style scoped>
p {
  color: #ffffff !important;
}

.footer-container {
  width: 100%;
  padding: 20px;
}

.footer-link {
  color: rgb(218, 215, 215) !important;
  text-decoration: none;
  font-size: 14px;
  padding: 0 10px !important;
}

.footer-link:hover {
  text-decoration: underline;
}

.v-footer {
  width: 100% !important;
  background-color: #631b8d !important;
  ;
  color: #ffffff;
}

.v-icon {
  color: white;
}

.v-list-item {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 0
}
</style>
