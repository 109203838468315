import axios from "../interceptors/axios";

export default class TitleService {
  view = async (id) => {
    try {
      const response = await axios.get(`/api/titles/${id}/view`);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
      throw error;
    }
  };

  delete = async (params) => {
    try {
      const response = await axios.delete("/api/titles/delete", { params });
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
      throw error;
    }
  };

  edit = async (data) => {
    try {
      const response = await axios.post("/api/titles/edit", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("auth")).access_token
          }`,
        },
      });
      console.log(response);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
      throw error;
    }
  };
}
