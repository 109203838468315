<template>
    <div class="warning-page">
    <div class="alfa">
        <div class="message-content">
        <content-message/>
    </div>

    <div>
    </div> <div>
    </div>
    </div>
    <div>
    </div>
    </div>
</template>

<script>
import ContentMessage from '@/components/ContentMessage.vue';
export default
{
    components: {
        ContentMessage
    }
}
</script>