import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

const obra = {
  id: 1,
  title: 'Krest Gale',
  description: 'Lorem ipsum dolor sit amet consectetur...',
  author: 'Fernanda Cardoso',
  year: 2024,
  views: 2300,
  rating: 5.0,
  genre: ['Fantasia', 'Aventura', 'Ação'],
  status: 'Lançamento',
  bannerUrl: require('@/assets/images/Group85.png'),
  imageUrl: require('@/assets/images/Group86.png'),
  lang: 'pt-BR'
};

const capitulos = [
  {
    id: 1,
    title: 'Título do primeiro capítulo',
    date: '13/07/2024',
    views: 1400,
    likes: 142,
    imageUrl: require('@/assets/images/capaobra.png'),
  },
  {
    id: 2,
    title: 'Título do segundo capítulo',
    date: '13/07/2024',
    views: 1400,
    likes: 142,
    imageUrl: require('@/assets/images/capaobra.png'),
  },
  {
    id: 3,
    title: 'Título do terceiro capítulo',
    date: '13/07/2024',
    views: 1400,
    likes: 142,
    imageUrl: require('@/assets/images/capaobra.png'),
  }
];

// Mock para a Home
const bestSellingItems = [
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
];

const mostViewedComedyItems = [
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "Sabertooth",
    genres: ["Mangá", "Comédia"],
    description: "Descrição do item de comédia...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-outline", "mdi-star-outline"],
    reviews: "15k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "Sabertooth",
    genres: ["Mangá", "Comédia"],
    description: "Descrição do item de comédia...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-outline", "mdi-star-outline"],
    reviews: "15k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "Sabertooth",
    genres: ["Mangá", "Comédia"],
    description: "Descrição do item de comédia...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-outline", "mdi-star-outline"],
    reviews: "15k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
];

const minhaLista = [
  {
    image: require('@/assets/images/image1.jpg'),
    title: "Item da Minha Lista 1",
    genres: ["Fantasia", "Aventura"],
    description: "Descrição do item da minha lista...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star-outline"],
    reviews: "10k",
  },
  {
    image: require('@/assets/images/image-2.jpg'),
    title: "Item da Minha Lista 2",
    genres: ["Ação", "Drama"],
    description: "Descrição do item da minha lista...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "15k",
  },
  {
    image: require('@/assets/images/image3.jpeg'),
    title: "Item da Minha Lista 3",
    genres: ["Comédia", "Romance"],
    description: "Descrição do item da minha lista...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star-outline"],
    reviews: "12k",
  },
  {
    image: require('@/assets/images/image4.png'),
    title: "Item da Minha Lista 4",
    genres: ["Mistério", "Suspense"],
    description: "Descrição do item da minha lista...",
    rating: ["mdi-star", "mdi-star", "mdi-star-outline", "mdi-star-outline", "mdi-star-outline"],
    reviews: "8k",
  },
  {
    image: require('@/assets/images/image1.jpg'),
    title: "Item da Minha Lista 5",
    genres: ["Ficção Científica", "Aventura"],
    description: "Descrição do item da minha lista...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
];

// Mock para a seção "Originais Zinnes"
const originaisZinnes = [
  {
    image: require('@/assets/images/image-2.jpg'),
    title: "Original Zinnes 1",
    genres: ["Fantasia", "Ação"],
    description: "Descrição do original Zinnes...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star"],
    reviews: "25k",
  },
  {
    image: require('@/assets/images/image3.jpeg'),
    title: "Original Zinnes 2",
    genres: ["Drama", "Suspense"],
    description: "Descrição do original Zinnes...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "18k",
  },
  {
    image: require('@/assets/images/image4.png'),
    title: "Original Zinnes 3",
    genres: ["Comédia", "Romance"],
    description: "Descrição do original Zinnes...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star-outline"],
    reviews: "15k",
  },
  {
    image: require('@/assets/images/image1.jpg'),
    title: "Original Zinnes 4",
    genres: ["Terror", "Suspense"],
    description: "Descrição do original Zinnes...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star-outline"],
    reviews: "22k",
  },
  {
    image: require('@/assets/images/image-2.jpg'),
    title: "Original Zinnes 5",
    genres: ["Fantasia", "Aventura"],
    description: "Descrição do original Zinnes...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star-outline"],
    reviews: "30k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
];

// Mock para a seção "Novos no Zinnes"
const novosNoZinnes = [
  {
    image: require('@/assets/images/image3.jpeg'),
    title: "Novo no Zinnes 1",
    genres: ["Fantasia", "Aventura"],
    description: "Descrição do novo no Zinnes...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star-outline"],
    reviews: "13k",
  },
  {
    image: require('@/assets/images/image4.png'),
    title: "Novo no Zinnes 2",
    genres: ["Comédia", "Romance"],
    description: "Descrição do novo no Zinnes...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "17k",
  },
  {
    image: require('@/assets/images/image1.jpg'),
    title: "Novo no Zinnes 3",
    genres: ["Ação", "Drama"],
    description: "Descrição do novo no Zinnes...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-outline", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require('@/assets/images/image-2.jpg'),
    title: "Novo no Zinnes 4",
    genres: ["Mistério", "Suspense"],
    description: "Descrição do novo no Zinnes...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star-outline"],
    reviews: "15k",
  },
  {
    image: require('@/assets/images/image3.jpeg'),
    title: "Novo no Zinnes 5",
    genres: ["Ficção Científica", "Aventura"],
    description: "Descrição do novo no Zinnes...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star"],
    reviews: "23k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
];

// Mock para a seção "Mais Vistos em Terror"
const maisVistosEmTerror = [
  {
    image: require('@/assets/images/image4.png'),
    title: "Mais Visto em Terror 1",
    genres: ["Terror", "Suspense"],
    description: "Descrição do item de terror...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star-outline"],
    reviews: "28k",
  },
  {
    image: require('@/assets/images/image1.jpg'),
    title: "Mais Visto em Terror 2",
    genres: ["Terror", "Mistério"],
    description: "Descrição do item de terror...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "22k",
  },
  {
    image: require('@/assets/images/image-2.jpg'),
    title: "Mais Visto em Terror 3",
    genres: ["Suspense", "Mistério"],
    description: "Descrição do item de terror...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star-outline"],
    reviews: "24k",
  },
  {
    image: require('@/assets/images/image3.jpeg'),
    title: "Mais Visto em Terror 4",
    genres: ["Terror", "Fantasia"],
    description: "Descrição do item de terror...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star-outline"],
    reviews: "26k",
  },
  {
    image: require('@/assets/images/image4.png'),
    title: "Mais Visto em Terror 5",
    genres: ["Mistério", "Suspense"],
    description: "Descrição do item de terror...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star"],
    reviews: "29k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
];

// Mock para a seção "Mais Vistos em Romance"
const maisVistosEmRomance = [
  {
    image: require('@/assets/images/image1.jpg'),
    title: "Mais Visto em Romance 1",
    genres: ["Romance", "Comédia"],
    description: "Descrição do item de romance...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star-outline"],
    reviews: "32k",
  },
  {
    image: require('@/assets/images/image-2.jpg'),
    title: "Mais Visto em Romance 2",
    genres: ["Romance", "Drama"],
    description: "Descrição do item de romance...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "28k",
  },
  {
    image: require('@/assets/images/image3.jpeg'),
    title: "Mais Visto em Romance 3",
    genres: ["Romance", "Mistério"],
    description: "Descrição do item de romance...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star-outline"],
    reviews: "29k",
  },
  {
    image: require('@/assets/images/image4.png'),
    title: "Mais Visto em Romance 4",
    genres: ["Romance", "Suspense"],
    description: "Descrição do item de romance...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star-outline"],
    reviews: "26k",
  },
  {
    image: require('@/assets/images/image1.jpg'),
    title: "Mais Visto em Romance 5",
    genres: ["Romance", "Fantasia"],
    description: "Descrição do item de romance...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star", "mdi-star"],
    reviews: "35k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
  {
    image: require("@/assets/images/onepiece.jpg"),
    title: "One Piece",
    genres: ["Mangá", "Aventura"],
    description: "Lorem ipsum dolor sit amet...",
    rating: ["mdi-star", "mdi-star", "mdi-star", "mdi-star-half", "mdi-star-outline"],
    reviews: "20k",
  },
];

const mock = new MockAdapter(axios);

// Mocks das rotas
mock.onGet('/api/obra').reply(200, obra);
mock.onGet('/api/capitulos').reply(200, capitulos);
mock.onGet('/api/home/bestSellingItems').reply(200, bestSellingItems);
mock.onGet('/api/home/mostViewedComedyItems').reply(200, mostViewedComedyItems);
mock.onGet('/api/home/minhaLista').reply(200, minhaLista);
mock.onGet('/api/home/originaisZinnes').reply(200, originaisZinnes);
mock.onGet('/api/home/novosNoZinnes').reply(200, novosNoZinnes);
mock.onGet('/api/home/maisVistosEmTerror').reply(200, maisVistosEmTerror);
mock.onGet('/api/home/maisVistosEmRomance').reply(200, maisVistosEmRomance);

export default mock;
