<template>
  <v-card>
    <div class="image-profiler">
      <img :src="authorData.profile_photo || 'https://cdn-icons-png.flaticon.com/512/847/847969.png'" alt="imagem de perfil">
    </div>
    <h1 class="name-user">{{ authorData.name || 'Nome não disponível' }}</h1>
    <p>@{{ authorData.nickname || 'Nickname não disponível' }}</p>
    <v-row justify="space-between" class="mt-4">
      <v-btn icon v-if="authorData.link_facebook" class="mx-1">
        <v-icon color="#FFF" size="34">mdi-facebook</v-icon>
      </v-btn>
      <v-btn icon v-if="authorData.link_instagram" class="mx-1">
        <v-icon color="#FFF" size="34">mdi-instagram</v-icon>
      </v-btn>
      <v-btn icon v-if="authorData.link_twitter" class="mx-1">
        <v-icon color="#FFF" size="34">mdi-twitter</v-icon>
      </v-btn>
    </v-row>
    <div class="local">
      <v-icon color="red" size="36">mdi-map-marker</v-icon>
      <span>{{ authorData.country || 'Localização não disponível' }} {{ authorData.estate }}</span>
    </div>
    <p class="bio">{{ authorData.description || 'Descrição não disponível.' }}</p>
  </v-card>
</template>

<script>
export default {
  name: "CardDetailsProfiler",
  props: {
    authorData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>



<style scoped>
p{
  color: #fff !important;
}
.v-card {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 370px;
  height: auto !important;
  max-height: 590px;
  padding: 15px 12px;
  border-radius: 30px !important;
  border: 0.5px solid #302d2d !important;
  background-color: #000 !important;
}

.image-profiler {
  width: 160px;
  height: 160px;
  border-radius: 100%;
}

.image-profiler img {
  width: 100%;
  height: auto;
  border-radius: 100%;
}

.name-user {
  font-size: 20px;
  font-weight: bold;
  color: #FFF !important;
  margin-top: 14px;
}

.local {
  width: 100% !important;
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
}



.local span {
  color: #FFFF;
}

.bio {
  font-size: 18px;
  font-weight: 300;
}

@media (max-width:520px) {
  .v-card {
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0px 0px 30px 30px !important;
  }
}
</style>