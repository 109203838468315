import { storeUserData } from "@/utils/storeUserData";
import axios from "@/interceptors/axios";

export const login = async (email, password) => {
  const dataUser = { email, password };

  try {
    const response = await axios.post("/api/login", dataUser);

    console.log("Auth", response.data);

    const accessToken = response?.data?.auth?.access_token;
    if (accessToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    } else {
      throw new Error("Token de autenticação não encontrado.");
    }

    await storeUserData(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      console.error(
        "Erro de resposta:",
        error.response.status,
        error.response.data
      );
      throw new Error(error.response.data?.message || "Erro ao fazer login.");
    } else if (error.request) {
      console.error("Nenhuma resposta recebida:", error.request);
      throw new Error("Nenhuma resposta recebida. Verifique sua conexão.");
    } else {
      console.error("Erro:", error.message);
      throw new Error("Erro ao fazer login. Tente novamente.");
    }
  }
};
