<template>
  <div class="container">
    <div class="container-btns">
      <v-row justify="center" class="mb-4">
        <v-col cols="6" sm="3" class="d-flex justify-center">
          <div class="custom-btn" style="background-color: #6A1B9A; color: white;" @click="openModalBook">
            <div class="icon-text-container">
              <v-icon class="custom-icon">mdi-book-open-page-variant</v-icon>
              <h1 class="custom-text">Livro</h1>
            </div>
          </div>
        </v-col>
        <v-col cols="6" sm="3" class="d-flex justify-center">
          <div class="custom-btn" style="background-color: #6A1B9A; color: white;" @click="openModal">
            <div class="icon-text-container">
              <v-icon class="custom-icon">mdi-view-grid</v-icon>
              <h1 class="custom-text">Quadrinho</h1>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <add-new-work-modal v-model="isModalOpen" @updateNewObra="updateNewObra"></add-new-work-modal>
    <add-new-work-book-modal @updateBookNewObra="updateBookNewObra" v-model="isModalBookOpen"></add-new-work-book-modal>

    <div class="container">
      <title-session-component title="Minhas Obras" tag="h1"></title-session-component>

      <div v-if="obras.length === 0">
        <p>Você não tem nenhum projeto publicado.</p>
      </div>

      <ul v-else class="container-list">
        <div class="card-row" v-for="(obra, index) in obras" :key="index">
          <div class="flex" @click="goToCapitulos(obra.id, obra.category)">
            <div class="image-col">
              <img :src="obra.thumb" alt="Capa da obra" class="obra-image" />
            </div>
            <div class="card-col info-col">
              <h2 class="card-title">{{ obra.name }}</h2>
              <p class="card-subtitle">{{ obra.description }}</p>

              <div class="card-info">
                <div class="info-left">
                  <p><strong>Tipo:</strong> {{ obra.category}}</p>
                  <p><strong>Capítulos:</strong> {{ obra.chapters }}</p>
                </div>
                <div class="info-right">
                  <p><strong>Última atualização:</strong> {{ obra.last_update }}</p>
                  <p><strong>Data de publicação:</strong> {{ obra.publication_date }}</p>
                </div>
              </div>
            </div>
          </div>
          <v-btn icon @click.stop="openModalConfirmation(obra.id)" color="purple" dark>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </ul>

    </div>

    <modal-confirmation v-model="isModalOpenConfirmation" @confirm="handleConfirmation"></modal-confirmation>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" top>
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import AddNewWorkModal from '@/components/AddNewWorkModal.vue';
import TitleSessionComponent from '@/components/TitleSessionComponent.vue';
import ModalConfirmation from '@/components/ModalConfirmation.vue';
import { getAllProjects } from '@/server/workService';
import AddNewWorkBookModal from '@/components/AddNewWorkBookModal.vue';
import ProjectService from '@/server/ProjectService';

export default {
  name: "PostWorkView",
  components: {
    TitleSessionComponent,
    AddNewWorkModal,
    AddNewWorkBookModal,
    ModalConfirmation
  },
  data() {
    return {
      isModalOpen: false,
      isModalBookOpen: false,
      isModalOpenConfirmation: false,
      obras: [],
      snackbar: {
        show: false,
        message: '',
        color: '',
        timeout: 2500,
      },
      service: new ProjectService(),
      deleted_id:null,
    };
  },
  async created() {
    this.list();
  },
  methods: {
    async list(){
      const response = await getAllProjects();
    if (response && response.length > 0) {
      this.obras = response;
    } else {
      this.obras = [];
    }
    },
    showSnackbar(message, type) {
      this.snackbar.message = message;
      this.snackbar.color = type === 'success' ? 'green' : 'red';
      this.snackbar.show = true;
    },
    updateNewObra(newObras) {
      this.obras = newObras;
      this.showSnackbar('Nova obra adicionada com sucesso', 'success');
    },
    updateBookNewObra(newObras) {
      this.obras = newObras;
      this.showSnackbar('Novo obra adicionado com sucesso', 'success');
    },
    openModal() {
      this.isModalOpen = true;
    },
    openModalBook() {
      this.isModalBookOpen = true;
    },
    openModalConfirmation(id) {
      this.deleted_id = id;
      this.isModalOpenConfirmation = true;
    },
    async handleConfirmation(confirmed) {
      if (confirmed) {
        console.log("Item deletado");
        await this.service.delete({id:this.deleted_id})
        this.list();
      } else {
        console.log("Ação cancelada");
      }
    },
    goToCapitulos(idObra, category) {
      if(category === 'Livros') {
        this.$router.push({ name: 'CreateChapterBookView', params: { idObra } });
      }else {
        this.$router.push({ name: 'ListCapituloView', params: { idObra } });
      }
    },
  },
};
</script>

<style scoped>
p{
  color: #fff  !important;
}
.container-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  background-color: #161515 !important;
  flex-wrap: wrap;
}

.custom-btn {
  width: 100%;
  max-width: 345px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin: 8px;
}

.icon-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-icon {
  font-size: 64px;
  margin-bottom: 8px;
}

.custom-text {
  font-size: 24px;
  margin: 0;
}

.card-row {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 95%;
  min-height: 350px;
  border-radius: 20px;
  margin: 12px 0;
  padding: 24px;
  background-color: #161515;
  cursor: pointer;
}

.card-row:hover {
  background-color: #2b2a28;
}

.card-col {
  flex: 1;
  min-width: 200px;
}

.image-col {
  width: 200px;
  height: 300px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.6);
}

.obra-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 3px solid #333;
  border-radius: 4px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.obra-image:hover {
  transform: scale(1.05);
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.8);
}
.card-title {
  font-size: 18px;
  color: #bbb;
}

.card-subtitle {
  font-size: 14px;
  color: #bbb;
}

.card-info {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.flex {
  display: flex;
  gap: 14px;
}

@media (max-width: 500px) {
  .container {
    margin-top: 50px;
  }

  .custom-btn {
    height: 150px;
  }

  .custom-text {
    font-size: 16px;
  }

  .card-title {
    font-size: 16px;
  }

  .card-subtitle {
    font-size: 12px;
  }

  .card-row {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .card-col{
    align-items: center;
  }

  .info-right{
    display: none;
  }

  .info-left {
    gap: 10px;
    display: flex;
  }

  .flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
  }

  .info-col{
    display: flex;
    flex-direction: column;
  }
}
</style>
