<template>
  <v-app class="app-main">
    <div class="container-autor">
      <header-author :data="author" @updated="findProjectsProfile" />
      <div class="mx-8 projects-grid" v-if="author.author?.projects?.length">
        <title-session-component title="Obras Publicadas" tag="h1" />
        <div class="projects-wrapper">
          <div v-for="project in author.author.projects" :key="project.id" class="project-card">
            <img :src="project.project_thumb" :alt="project.name" class="project-image" />
          </div>
        </div>
      </div>
    </div>

    <div class="mx-8">
      <CarouselSection v-if="author?.latest_updateds.length" type="last-updated" title="Últimos Atualizados"
        :items="author?.latest_updateds" />
    </div>
  </v-app>
</template>

<script>
import CarouselSection from '@/components/CarouselSection.vue';
import HeaderAuthor from '@/components/HeaderAuthor.vue';
import TitleSessionComponent from '@/components/TitleSessionComponent.vue';
import { detailsAuthor } from '@/server/userServe';

export default {
  name: 'AuthorView',
  components: {
    HeaderAuthor,
    CarouselSection,
    TitleSessionComponent
  },
  data() {
    return {
      authorId: null,
      author: {
        author: {
          description: '',
          projects: []
        },
        latest_updateds: []
      },
    };
  },

  methods: {
    async findProjectsProfile() {
      try {
        this.authorId = this.$route.params.authorId;
        this.author = await detailsAuthor(this.authorId);
      } catch (error) {
        console.error("Erro ao buscar os dados do autor:", error);
      }
    },
  },

  watch: {
    '$route.params.authorId': {
      immediate: true,
      handler() {
        this.findProjectsProfile();
      }
    }
  }
};
</script>

<style scoped>
.container-autor {
  display: flex;
}

.app-main {
  width: 80%;
  margin: 0 auto;
}

.projects-grid {
  width: 100%;
  overflow: hidden;
}

.projects-wrapper {
  display: grid;
  gap: 14px;
}

/* Definição base dos cards */
.project-card {
  width: 100%;
  max-width: 250px !important;
  aspect-ratio: 2 / 3;
  /* Mantém a proporção do card */
  background-color: #222;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.2s;
}

.project-card:hover {
  transform: scale(1.05);
}

/* A imagem ocupa 100% do card */
.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Responsividade */
@media (max-width: 780px) {
  .app-main {
    width: 100% !important;
    margin: 0 auto;
  }

  .container-autor {
    display: block;
  }

  .projects-wrapper {
    grid-template-columns: repeat(3, 1fr); /* Agora mostra 3 colunas no mobile */
    gap: 8px; /* Reduz o espaçamento entre os cards */
  }

  .project-card {
    max-width: 80px; /* Define um tamanho menor para os cards */
    aspect-ratio: 2 / 3;
  }
}

@media (max-width: 480px) {
  .projects-wrapper {
    grid-template-columns: repeat(2, 1fr); /* Mostra 4 colunas em telas menores */
    gap: 6px;
  }

  .project-card {
    max-width: 150px; /* Tamanho ainda menor para telas muito pequenas */
  }
}


@media (min-width: 730px) and (max-width: 1024px) {
  .projects-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .projects-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1400px) {
  .projects-wrapper {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1800px) {
  .projects-wrapper {
    grid-template-columns: repeat(6, 1fr);
  }
}
</style>
