<template>
  <v-app>
    <banner-component :slides="slides" v-if="myList.length || top.length || originaisZinnes.length" />
    <div class="text-center white--text section-search">
      <v-col cols="12" class="pb-0">
        <v-img class="logo-image" src="@/assets/images/image-footer.png" alt="Logo" contain height="150"></v-img>
      </v-col>
      <v-col cols="12"  class="pt-0">
        <h2>A sua plataforma de quadrinhos</h2>
      </v-col>
      <v-col cols="12" class="mb-12">
        <v-row>
          <v-col></v-col>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="searchQuery" dense hide-details solo flat prepend-inner-icon="mdi-magnify"
                class="search-input" placeholder="Pesquisar..." @keyup.enter="executeSearch" v-bind="attrs" v-on="on"
                @input="searchFilter"></v-text-field>
            </template>
            <v-list v-show="filteredResults.length > 0" dark>
              <v-list-item v-for="(item, index) in filteredResults" :key="index" :to="`/obra/${item.id}`">
                <v-list-item-avatar tile>
                  <v-img :src="item.project_thumb"></v-img>
                </v-list-item-avatar>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-col></v-col>
        </v-row>
      </v-col>

    </div>
    <div class="main">
      <CarouselSection v-if="myList.length" title="Minha lista" :items="myList" />
      <CarouselSection v-if="latest_releases.length" title="Novos No Zinnes" :items="latest_releases" />
      <ListTop10Component v-if="top.length" :items="top" />

      <!-- Primeiro banner (2 colunas) -->
      <v-row v-if="banners?.length > 1 && banners[0]?.image && banners[1]?.image">
        <v-col cols="6" class="mb-12 mt-12">
          <a :href="banners[0].link" target="_blank">
            <v-img :src="banners[0].image" :alt="banners[0].title" contain class="banner-image"></v-img>
          </a>
        </v-col>
        <v-col cols="6" class="mb-12 mt-12">
          <a :href="banners[1].link" target="_blank">
            <v-img :src="banners[1].image" :alt="banners[1].title" contain class="banner-image"></v-img>
          </a>
        </v-col>
      </v-row>

      <div v-for="(section, index) in sections.filter(s => s.items.length)" :key="index">
        <CarouselSection :title="section.title" :items="section.items" :type="section.type" />

        <!-- Exibir banner a cada 3 sessões -->
        <v-row v-if="(index + 1) % 3 === 0 && banners[Math.floor(index / 3) + 2]?.image" :key="'banner-' + index">
          <v-col cols="12" class="mb-12 mt-12">
            <a :href="banners[Math.floor(index / 3) + 2].link" target="_blank">
              <v-img :src="banners[Math.floor(index / 3) + 2].image" :alt="banners[Math.floor(index / 3) + 2].title"
                contain class="banner-image"></v-img>
            </a>
          </v-col>
        </v-row>
      </div>

      <user-session-high v-if="authors.length > 0" :users="authors" />

      <!-- Banner abaixo do user-session-high -->
      <v-row v-if="authors.length > 0 && banners?.length > 0 && banners[banners.length - 1]?.image">
        <v-col cols="12" class="mb-12 mt-12">
          <a :href="banners[banners.length - 1].link" target="_blank">
            <v-img :src="banners[banners.length - 1].image" :alt="banners[banners.length - 1].title" contain
              class="banner-image-finshed"></v-img>
          </a>
        </v-col>
      </v-row>
    </div>
    <zinnes-informations :total-creators="totalCreators" :total-stories="totalStories" :total-views="totalViews" />
  </v-app>
</template>

<script>
import BannerComponent from '@/components/BannerComponent.vue';
import ListTop10Component from '@/components/ListTop10Component.vue';
import UserSessionHigh from '@/components/UserSessionHigh.vue';
import ZinnesInformations from '@/components/ZinnesInformations.vue';
import CarouselSection from '@/components/CarouselSection.vue';
import { getAllHome } from '@/server/homeServe';
import BannerService from '@/server/BannerService';
import Banner from '@/models/Banner';
import { getSearchProjects } from "@/server/workService";

export default {
  computed: {
    sections() {
      return [
        { title: "Originais Zinnes", items: this.byZinnes, type: "zinnes" },
        { title: "Mais Visto da Semana", items: this.visas, type: "titles" },
        { title: "Mais Vistos Em Comédia", items: this.mostViewedComedyItems },
        { title: "Mais Vistos Em Terror", items: this.mostViewedHorror },
        { title: "Mais Vistos Em Romance", items: this.mostViewedRomance },
        ...this.series_by_genre.map(gender => ({ title: `Mais Vistos Em ${gender.name}`, items: gender.projects }))
      ];
    }
  },
  components: {
    BannerComponent,
    ListTop10Component,
    CarouselSection,
    UserSessionHigh,
    ZinnesInformations,
  },
  data() {
    return {
      myList: [],
      latest_releases: [],
      last_updated: [],
      series_by_genre: [],
      top: [],
      visas: [],
      authors: [],
      totalCreators: 0,
      totalStories: 0,
      totalViews: 0,
      slides: [],

      mostViewedRomance: [],
      mostViewedHorror: [],
      originaisZinnes: [],
      mostViewedComedyItems: [],
      bestSellingItems: [],
      byZinnes: [],
      banners: [],
      banner: new Banner(),
      service: new BannerService(),
      searchQuery: "",
      filteredResults: []
    };
  },
  async created() {
    await this.init();
    await this.list();
  },
  methods: {
    async init() {
      try {
        const res = await getAllHome();
        this.slides = res.banners || [];
        console.log('Dados carregados:', res);

        this.myList = res.follow_projects || [];
        this.latest_releases = res.latest_releases || [];
        this.last_updated = res.last_updated || [];
        this.series_by_genre = res.series_by_genre || [];
        this.top = res.top || [];
        this.visas = res.visas || [];
        this.authors = res.authors || [];

        // Contadores gerais
        this.totalCreators = res.total_creators || 0;
        this.totalStories = res.total_stories || 0;
        this.totalViews = res.total_views || 0;

        // Outros dados ausentes (pendentes no backend)
        this.mostViewedRomance = res.most_viewed_romance || [];
        this.mostViewedHorror = res.most_viewed_horror || [];
        this.originaisZinnes = res.originals || [];
        this.mostViewedComedyItems = res.most_viewed_comedy || [];
        this.bestSellingItems = res.best_selling || [];
        this.byZinnes = res.for_zinnes || [];
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
      }
    },
    async list() {
      let params = {};

      let response = await this.service.list(params);

      this.banners = response || [];
      console.log("Banners", this.banners[0])
    },
    executeSearch() {
      this.$router.push({ path: '/series', query: { q: this.searchQuery } })
      this.searchQuery = ''
      this.filteredResults = []
    },
    async searchFilter(val) {
      const params = {
        name: val,
      };

      const results = await getSearchProjects(params);
      this.filteredResults = results.data;
    },
  },
};
</script>



<style scoped>
.section-search{
  background-color: #171717 !important;
}

.search-input{
  width: 55% !important;
}
.main {
  background-color: #11111a !important;
  width: 75%;
  max-width: 1900px;
  margin: 26px auto;
  padding: 0 36px !important;
  flex: 1;
}

.banner-image {
  max-width: 1300px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.banner-image-finshed {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .main {
    padding: 0 8px !important;
  }
}



@media (max-width: 1840px) {
  .main {
    width: 90% !important;
  }
}

@media (max-width: 1024px) {
  .main {
    width: 100% !important;
  }
}

@media (max-width: 320px) {
  .search-input{
  width: 90% !important;
}
  .main {
    width: 100%;
  }
}
</style>
