<template>
  <v-app class="app-main ">
    <div>
      <banner-serie :obra="obra" :author="obra.author" :idChapter="this.chapters.length ? this.chapters[0].id : 0"
        @update-obra="fetchObra" />
    </div>
    <v-col>
    <v-col>
      <div class="container mt-20">
        <title-session-component title="Capítulos" tag="h1" />
        <v-row justify="center" class="mb-4 btn-languages">
          <v-btn v-for="(language, index) in languages" :key="index"
            :color="obra.language.name === language.value ? '#FFBD59' : '#451755'" class="mx-2" small>
            {{ language.text }}
          </v-btn>
        </v-row>
        <v-col v-if="chapters.length > 0">
          <v-row>
            <v-col v-for="(chapter, index) in chapters" :key="index" cols="12" md="6" lg="4">
              <v-card class="d-flex flex-row align-center mb-4 card-obra"
                @click="goToReadingPage(chapter.id, obra.category ? obra.category.name : '')" hover>
                <v-img :src="chapter.title_thumb ? chapter.title_thumb : obra.project_thumb" alt="Capa do capítulo"
                  class="chapter-image" height="130" width="90"></v-img>
                <v-card-text class="flex-grow-1">
                  <div class="font-weight-bold chapter-title">{{ chapter.name }}</div>
                  <p class="chapter-description">{{ chapter.description }}</p>
                  <div class="chapter-date">{{ chapter.publication_date }}</div>
                  <v-row class="align-center mt-2 chapter-info" dense>
                    <v-col cols="auto" class="d-flex align-center chapter-icon-text">
                      <v-icon small color="#fff">mdi-message</v-icon>
                      {{ chapter.comments || 0 }}
                    </v-col>
                    <v-col cols="auto" class="d-flex align-center chapter-icon-text">
                      <v-icon small color="#fff">mdi-eye</v-icon>
                      {{ chapter.views || 0 }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <p v-else>
          Nenhum capítulo disponível para ler.
        </p>
      </div>
    </v-col>

      <div class="mx-4">
        <CarouselSection v-if="similar_projects.length" title="Você também pode gostar" :items="similar_projects" />
      </div>

      <div class="mx-4">
        <CarouselSection v-if="author_projects.length" title="Outras obras do autor" :items="author_projects" />
      </div>
    </v-col>
  </v-app>
</template>

<script>
import BannerSerie from '@/components/BannerSerie.vue';
import TitleSessionComponent from '@/components/TitleSessionComponent.vue';
import { getProjectDetailsById } from '@/server/homeServe';
import CarouselSection from '@/components/CarouselSection.vue';

export default {
  name: 'ObraOpenView',
  components: {
    BannerSerie,
    TitleSessionComponent,
    CarouselSection
  },
  props: ['idObra'],
  data() {
    return {
      obra: null,
      author: null,
      chapters: [],
      similar_projects: [],
      author_projects: [],
      languages: [
        { text: 'Português (BR)', value: 'pt-br' },
        { text: 'Español', value: 'es' },
        { text: 'English', value: 'en-us' },
      ],
    };
  },
  mounted() {
    this.fetchObra();
  },
  methods: {
    async fetchObra() {
      try {
        const data = await getProjectDetailsById(this.idObra);
        this.obra = data.project;
        this.author = data.author;
        this.chapters = data.titles;
        this.similar_projects = data.similar_projects;
        this.author_projects = data.author_projects;
      } catch (error) {
        console.error('Erro ao buscar obra:', error);
      }
    },
    goToReadingPage(idChapter, type) {
      console.log("Fora do IF", type)
      if (type === 'Livros') {
        console.log("Dentro do IF", type)
        this.$router.push(`/obra/book/reading/${idChapter}`);
      } else {
        this.$router.push(`/${idChapter}/page`);
      }

    },
  }
};
</script>

<style scoped>
.btn-languages {
  flex-wrap: nowrap;
}

.chapter-image {
  object-fit: cover;
  margin-right: 16px;
}

.card-obra {
  transition: transform 0.3s ease;
}

.card-obra:hover {
  transform: scale(1.02);
}

.chapter-title {
  font-size: 1.2rem;
}

.chapter-description {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.chapter-date {
  font-size: 0.8rem;
  color: #666;
}

.chapter-icon-text {
  font-size: 0.8rem;
}

@media (max-width: 600px) {
  .chapter-image {
    height: 100px;
    width: 70px;
  }

  .chapter-title {
    font-size: 1rem;
  }

  .chapter-description {
    font-size: 0.8rem;
  }
}

.app-main {
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  flex: 1;
}

p {
  color: #fff !important;
}

.mt-20 {
  margin-top: 400px !important;
}

.list-cards {
  display: flex;
  gap: 14px;
  padding: 34px;
  align-items: center;
  overflow: hidden;
}

.card-obra {
  background-color: #161515 !important;
  color: #fff !important;
  padding: 16px;
  width: 100%;
}

.chapter-image {
  height: 130px;
  width: 90px;
}

.chapter-title,
.chapter-date,
.chapter-info,
.chapter-icon-text {
  color: white !important;
}

.chapter-info v-icon {
  color: white !important;
}

@media(max-width: 600px) {
  .container {
    margin-top: 800px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .chapter-description {
    display: none !important;
  }
}
</style>
