<template>
  <v-container class="container full-height" fluid>
    <v-card class="pa-4" dark>
      <v-card-title>Notificações</v-card-title> 
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#451755" @click="readAll()">Ler tudo</v-btn>
      </v-card-actions>

      <v-list class="">
        <template v-for="notification in notifications.data">
          <v-list-item class="notification-item" v-if="!notification.content" :key="notification.id"
            @click="read(notification)"
            :class="{ 'unread': !notification.read }">
            <div class="image-conteiner">
              <v-img :src="notification.title.title_thumb?notification.title.title_thumb:notification.title.project.project_thumb" class="image-notification" />
            </div>
            <v-list-item-content>
              <v-list-item-title>{{ notification.title.name }}</v-list-item-title>
              <v-list-item-subtitle>Novo Capítulo atualizado</v-list-item-subtitle>
              <v-list-item-subtitle>{{ formatDate(notification.created_at) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-else :key="notification.id" :class="{ 'unread': !notification.read }" @click="read(notification)">
            <v-list-item-avatar>
              <v-img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0B5wCphb7iYV9XnpoOjefbr2LKuOPuAVthLswOcMV-GSnp6zRSGWVNK9iDwzWxyrsMv0&usqp=CAU" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Aviso da Plataforma</v-list-item-title>
              <v-list-item-subtitle>{{ notification.content }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ formatDate(notification.created_at) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider :key="'divider-' + notification.id" />
        </template>
      </v-list>

      <v-pagination color="#451755" 
            v-model="notifications.current_page" 
            :length="notifications.last_page"
            total-visible="5"
            @input="list"
            ></v-pagination>
      <v-alert v-if="notifications.length === 0" type="info" class="mt-4">
        Nenhuma notificação no momento.
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
import NotificationService from '@/server/NotificationService';

export default {
  name: "UserNotifications",
  data() {
    return {
      service: new NotificationService(),
      notifications: {
        data:[],
        current_page:1,
        last_page:1,
      },
    };
  },

  created() {
    this.getNotification();
  },

  methods: {
    async getNotification() {
      const auth = JSON.parse(localStorage.getItem("auth"));
      const user = JSON.parse(localStorage.getItem("user"));
      console.log("Auth:", auth);
      console.log("User:", user);

      if (auth && user) {
        await this.list();
      }
    },
    async list(page=1){
      try {
          let params = {page}; 
          let res = await this.service.list(params);
          this.notifications = res;
          console.log("Notificações recebidas:", this.notifications);
        } catch (error) {
          console.error("Erro ao buscar notificações:", error);
        }
    },
    async read(data){
      if(!data.read){
        let params = {id:data.id}; 
        data.read = true;
        await this.service.read(params);
      }
      if(data.title){
        let destino = data.title.project.category.id==1?`/${data.title.id}/page`:`obra/book/reading/${data.title.id}`;
        const rota = this.$router.resolve({ path: destino });
        window.open(rota.href, '_blank')
      }
    },
    async readAll(){
      await this.service.read();
      await this.list(this.notifications.current_page);
    },
    openLink(link) {
      if (link) {
        window.open(link, '_blank');
      }
    },

    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('pt-BR', options);
    },
  }
};
</script>

<style scoped>
.notification-item {
  cursor: pointer;
  align-items: center;
}

.image-conteiner {
  margin-right: 10px;
  width: 60px;
  height: 95px !important;
}

.image-notification {
  width: 100%;
  height: 100%;
}

.full-height {
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.container {
  margin: 30px 0 0 0 !important;
}

.v-card {
  max-width: 800px;
  width: 100%;
}

.unread {
  background-color: #474747;
}

.notification-list {
  max-height: 400px;
  overflow-y: auto;
}

.v-list-item {
  cursor: pointer;
}

.v-img {
  border-radius: 8px;
}
</style>
