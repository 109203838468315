import axios from "../interceptors/axios";

export const fetchAuthorManagerData = async () => {
  try {
    const response = await axios.get("/api/author/manager", {
      headers: {
        Accept: "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};
