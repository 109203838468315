<template>
  <div class="box" v-if="users && users.length">
    <title-session-component title="Autores em Alta" tag="h1" />
    <vue-slick-carousel :dots="true" :infinite="false" :speed="500"  :slides-to-show="slidesToShow"
      :arrows="!isMobile"
      :slides-to-scroll="1"
      class="zine-carousel">
      <card-user-high v-for="(user, index) in users" :key="index" :id="user.id" :name="user.name"
        :profileImage="user.profile_photo
" :followers="user.follow_count" />
    </vue-slick-carousel>
  </div>
</template>


<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import VueSlickCarousel from "vue-slick-carousel";
import TitleSessionComponent from './TitleSessionComponent.vue';
import CardUserHigh from './CardUserHigh.vue';

export default {
  name: 'UserSessionHigh',
  components: {
    TitleSessionComponent,
    CardUserHigh,
    VueSlickCarousel,
  },
  data() {
    return {
      screenWidth: window.innerWidth,
      isMobile: window.innerWidth <= 768,
    };
  },
  props: {
    users: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    slidesToShow() {
      if (this.screenWidth <= 750) return 2; // Mobile
      if (this.screenWidth <= 950) return 3;
      if (this.screenWidth <= 1024) return 3; // Tablet
      if (this.screenWidth <= 1766) return 8; // Desktop médio
      return 10; // Desktop grande
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
      this.isMobile = this.screenWidth <= 768;
    },
  },
};
</script>
