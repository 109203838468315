<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
  >
    <v-card color="#333333" dark>
      <v-card-title class="justify-center text-h6 text-center">
        Deseja realmente deletar esse item?
      </v-card-title>
      <v-card-actions class="justify-end">
        <v-btn
          color="#451755"
          depressed
          elevation="2"
          large
          @click="closeDialog(false)"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="#8F0E0E"
          depressed
          elevation="2"
          large
          @click="closeDialog(true)"
        >
          Deletar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalConfirmation",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.dialog = newValue;
    },
    dialog(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    closeDialog(confirmed) {
      this.$emit("confirm", confirmed);
      this.dialog = false;
    },
  },
};
</script>
