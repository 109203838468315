<template>
  <div class="comment-section">
    <h3>{{ comments.total }} Comentários</h3>
    <row class="comment-input">
      <v-textarea v-model="newComment" label="Deixe seu comentário..." outlined clearable rows="2" dense />
      <v-btn small color="primary mx-2" @click="addComment">Comentar</v-btn>
    </row>

    <div v-if="comments.comments.length === 0" class="no-comments">
      <p>Este capítulo não possui comentários ainda. Seja o primeiro a comentar!!</p>
    </div>

    <div class="comment-list">
      <div v-for="comment in comments.comments" :key="comment.id" class="comment-item d-flex">
        <!-- Comentário principal -->
        <div class="avatar">
          <v-avatar size="40" class="mr-3">
            <img :src="comment.profile_photo" alt="Avatar" />
          </v-avatar>
        </div>

        <div class="comment-content">
          <div class="comment-header">
            <span class="font-weight-bold">{{ comment.nickname }}</span>
          </div>
          <v-textarea v-if="editingCommentId === comment.id" v-model="editingText" label="Edite seu comentário..."
            outlined rows="2" dense auto-grow />
          <p v-else>{{ comment.text }}</p>

          <div class="comment-footer d-flex align-center">
            <div @click="toggleReplyBox(comment.id)">
              <v-icon small>mdi-message</v-icon>
              <span class="ml-1">Responder</span>
            </div>
            <v-icon small class="ml-3" @click="handleToggleLike(comment.id)">mdi-heart</v-icon>
            <span class="ml-1">{{ comment.like_count }}</span>

            <template v-if="comment.user_id === userId">
              <v-icon small class="ml-3" @click="editComment(comment)"
                v-if="editingCommentId !== comment.id">mdi-pencil</v-icon>

              <v-icon small class="ml-3" color="green" @click="saveEditedComment"
                v-if="editingCommentId === comment.id">mdi-check</v-icon>

              <v-icon small class="ml-3" color="red" @click="cancelEdit"
                v-if="editingCommentId === comment.id">mdi-close</v-icon>

              <v-icon small class="ml-3" color="red" @click="deleteComment(comment.id)">mdi-delete</v-icon>
            </template>
          </div>

          <!-- Caixa de Resposta -->
          <v-row v-if="replyTexts[comment.id] !== undefined" class="reply-box">
            <v-textarea v-model="replyTexts[comment.id]" label="Responda ao comentário..." outlined col="12" clearable
              rows="1" dense />
            <v-btn color="primary mx-2" @click="addReply(comment.id)">Enviar</v-btn>
          </v-row>

          <!-- Respostas -->
          <div class="replies">
            <div v-for="reply in comment.comments" :key="reply.id" class="reply-item d-flex">
              <div class="avatar">
                <v-avatar size="30" class="mr-2">
                  <img :src="reply.profile_photo" alt="Avatar" />
                </v-avatar>
              </div>
              <div class="reply-content">
                <div class="reply-header">
                  <span class="font-weight-bold">{{ reply.nickname }}</span>
                </div>
                <v-row class="reply-body">
                  <v-textarea v-if="editingCommentId === reply.id" v-model="editingText" label="Edite seu comentário..."
                    outlined rows="2" dense auto-grow />
                  <p v-else>{{ reply.text }}</p>

                  <div class="comment-footer d-flex align-center">
                    <v-icon small class="ml-3" @click="handleToggleLike(reply.id)">mdi-heart</v-icon>
                    <span class="ml-1">{{ reply.like_count }}</span>

                    <template v-if="reply.user_id === userId">
                      <v-icon small class="ml-3" @click="editComment(reply)"
                        v-if="editingCommentId !== reply.id">mdi-pencil</v-icon>

                      <v-icon small class="ml-3" color="green" @click="saveEditedComment"
                        v-if="editingCommentId === reply.id">mdi-check</v-icon>

                      <v-icon small class="ml-3" color="red" @click="cancelEdit"
                        v-if="editingCommentId === reply.id">mdi-close</v-icon>

                      <v-icon small class="ml-3" color="red" @click="deleteComment(reply.id)">mdi-delete</v-icon>
                    </template>
                  </div>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" top>
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>



<script>
import { createComment, deleteComment, fetchComments, toggleLikeComment, updateComment } from '@/server/commentsChapterService';

export default {
  name: "CommentsChapter",
  props: {
    idChapter: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      comments: {
        total: 0,
        comments: [],
      },
      newComment: '',
      replyTexts: {},
      userId: null,
      editingCommentId: null,
      editingText: '',
      replyBoxId: null,
      snackbar: {
        show: false,
        message: '',
        color: '',
        timeout: 2500,
      },
    };
  },

  created() {
    this.loadComments();
    this.userId = JSON.parse(localStorage.getItem("user")).id;
  },

  methods: {
    showSnackbar(message, type) {
      this.snackbar.message = message;
      this.snackbar.color = type === 'success' ? 'green' : 'red';
      this.snackbar.show = true;
    },
    async loadComments() {
      try {
        console.log("loadCOmments", this.idChapter);
        const response = await fetchComments(this.idChapter);
        console.log("response comentarios", response);
        this.comments = response;
      } catch (error) {
        console.error("Erro ao carregar os comentários:", 'error');
      }
    },

    async addComment() {
      await this.handleCreateComment(this.newComment);
      this.showSnackbar('Comentário adicionado com sucesso', 'success');
      this.newComment = '';
    },

    async handleCreateComment(text) {
      try {
        await createComment(this.idChapter, text);
        this.loadComments();
      } catch (error) {
        this.showSnackbar("Erro ao criar comentário:", 'error');
      }
    },

    async handleToggleLike(commentId) {
      try {
        await toggleLikeComment(commentId);
        this.loadComments();
      } catch (error) {
        this.showSnackbar("Erro ao curtir ou descurtir comentário:", 'error');
      }
    },

    async handleDeleteComment(commentId) {
      try {
        await deleteComment(commentId);
        this.comments = this.comments.filter(comment => comment.id !== commentId);
        this.showSnackbar("Comentário excluído com sucesso", 'success');
      } catch (error) {
        this.showSnackbar("Erro ao excluir comentário:", 'error');
      }
    },

    async addReply(commentId) {
      const replyText = this.replyTexts[commentId];
      if (!replyText) {
        this.showSnackbar("O texto da resposta está vazio.", "error");
        return;
      }

      try {
        await createComment(this.idChapter, replyText, commentId);
        this.replyTexts[commentId] = '';
        this.loadComments();
        this.showSnackbar("Resposta adicionada com sucesso!", "success");
      } catch (error) {
        this.showSnackbar("Erro ao adicionar resposta.", "error");
      }
    },
    toggleReplyBox(commentId) {
      if (this.replyTexts[commentId] === undefined) {
        this.$set(this.replyTexts, commentId, "");
      }
    },

    async deleteComment(commentId) {
      try {
        await deleteComment(commentId);
        this.loadComments();
        this.showSnackbar("Comentário excluído com sucesso", 'success');
      } catch (error) {
        this.showSnackbar("Erro ao excluir comentário.", 'error');
      }
    },

    editComment(comment) {
      this.editingCommentId = comment.id;
      this.editingText = comment.text;
    },

    async saveEditedComment() {
      if (!this.editingText.trim()) {
        this.showSnackbar("O comentário não pode estar vazio.", "error");
        return;
      }

      try {
        await updateComment(this.editingCommentId, this.editingText);
        this.loadComments();
        this.showSnackbar("Comentário atualizado com sucesso!", "success");
      } catch (error) {
        this.showSnackbar("Erro ao atualizar comentário.", "error");
      } finally {
        this.cancelEdit();
      }
    },

    cancelEdit() {
      this.editingCommentId = null;
      this.editingText = '';
    },
  },

  mounted() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.id) {
      this.userId = user.id;
    }
    console.log("id capitulo component comentario", this.idChapter);
    this.loadComments();
  },
};
</script>

<style scoped>
label {
  color: #fff !important;
}

p {
  color: #fff !important;
}

.replies {
  margin-top: 20px;
}

.reply-body {
  margin-top: 10px;
  text-align: center;
}

.comment-content {
  width: 100%;
}

.reply-box {
  margin-top: 20px;
  width: 100%;
}

.comment-section {
  width: 70%;
  margin: 10px auto;
  background-color: #1e1e1e;
  padding: 16px;
  border-radius: 8px;
  color: #fff;
}

.comment-input {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.comment-input v-textarea {
  flex: 1;
}

.comment-list {
  margin-top: 16px;
}

.comment-item {
  padding: 12px 0;
  border-bottom: 1px solid #333;
}

.comment-header {
  font-size: 16px;
}

.comment-body {
  font-size: 14px;
  color: #ddd;
  margin-top: 4px;
}

.comment-footer {
  font-size: 12px;
  color: #aaa;
  margin-top: 8px;
}

.report-icon {
  cursor: pointer;
}

@media(max-width: 600px) {
  .comment-section {
    width: 100%;
    background-color: #1e1e1e;
    padding: 16px;
    border-radius: 8px;
    color: #fff;
  }

}
</style>
