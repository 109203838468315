<template>
  <v-app class="app">
    <div class="flex">
      <card-details-profiler :authorData="authorData" />
      <div class="fixed-works-section">
        <title-session-component title="Obras Publicadas" tag="h1" />
        <div class="my-list">
          <div v-if="minhasObras.length === 0" class="no-works-message">
            O usuário não tem obra publicada.
          </div>
          <div v-for="(item, index) in visibleWorks" :key="index" class="list">
            <ProjectCard :cardData="item" />
          </div>
          <div v-if="minhasObras.length > 6" class="view-all-button">
            <button @click="toggleShowAll" class="unstyled-button">
              {{ showAll ? "Ver Menos" : "Ver Todos" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-color">
      <div>
        <title-session-component title="Obras Favoritas" tag="h1" />
        <div class="flex-favorites">
          <div v-if="minhaLista.length === 0" class="no-works-message">
            O usuário ainda não tem nenhuma obra favorita.
          </div>
          <div v-for="(item, index) in minhaLista" :key="index" class="flex-favorites">
            <ProjectCard :cardData="item" />
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import CardDetailsProfiler from '@/components/CardDetailsProfiler.vue';
import TitleSessionComponent from '@/components/TitleSessionComponent.vue';
import { getAuthorProjects } from '../server/workService';
import { getFavoriteProjects } from '@/server/userServe';
import ProjectCard from '@/components/cards/ProjectCard.vue'

export default {
  name: "ProfilerUserView",
  components: {
    CardDetailsProfiler,
    TitleSessionComponent,
    ProjectCard
  },
  data() {
    return {
      showAll: false,
      minhasObras: [],
      minhaLista: [],
      authorData: [],
    };
  },
  async created() {
    await this.findProjectsProfile();
    await this.fetchFavoriteProjects();
  },
  computed: {
    visibleWorks() {
      // Exibe os primeiros 10 itens ou todos, dependendo do estado
      return this.showAll ? this.minhasObras : this.minhasObras.slice(0, 6);
    },
  },
  methods: {
    toggleShowAll() {
      // Alterna entre exibir todos os itens ou apenas 10
      this.showAll = !this.showAll;
    },
    async findProjectsProfile() {
      try {
        const data = await getAuthorProjects();
        this.authorData = data.author;
        this.minhasObras = data.author.projects;
        this.minhaLista = data.author.follow_projects;
        console.log(this.authorData);
      } catch (error) {
        console.error("Erro ao buscar projetos do autor:", error);
      }
    },

    // Para buscar os projetos favoritos
    async fetchFavoriteProjects() {
      try {
        const favoriteProjects = await getFavoriteProjects();
        console.log("Projetos favoritos encontrados:", favoriteProjects);
      } catch (error) {
        console.error("Erro ao buscar projetos favoritos:", error);
      }
    }

  },
};
</script>


<style scoped>
.bg-color {
  background-color: #1D1D1D !important;
}

.view-all-button {
  width: 100%;
  display: flex;
  justify-content: star;
  margin-top: 1rem;
}

.unstyled-button {
  background: none;
  border: none;
  color: #a21bf0;
  /* Azul padrão para links */
  cursor: pointer;
  text-decoration: underline;
  font-size: 1rem;
  padding: 0;
}

.unstyled-button:hover {
  text-decoration: none;
  /* Remove sublinhado ao passar o mouse */
}

.no-works-message {
  text-align: center;
  color: #999;
  margin-top: 20px;
}

.flex-favorites {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
}


.app {
  background-color: #1D1D1D !important;
}

.flex {
  display: flex;
  background-color: #1D1D1D !important;
  gap: 20px;
}

.fixed-works-section {
  flex: 0 0 350px;
  max-width: 350px;
}

.my-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.my-list .list {
  display: block;
  width: 100%;
}

.unstyled-button {
  color: #a21bf0;
  font-weight: bold;
  border-radius: 5px;
  padding: 8px 12px;
  transition: background 0.3s ease;
}

.unstyled-button:hover {
  background: rgba(162, 27, 240, 0.1);
}



@media (max-width: 530px) {
  .flex {
    flex-direction: column;
  }

  .flex-favorites {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }

  .list {
    padding: 5px 14px !important;
  }

  .my-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .fixed-works-section {
    max-width: 100%;
    flex: 1;
  }
}
</style>