<template>
  <v-bottom-navigation
    v-model="active"
    background-color="black"
    color="white"
    grow
    class="mobile-nav"
  >
    <v-btn
      :class="{'active-btn': active === 0}"
      text
      to="/"
      @click="active = 0"
    >
      <span>Home</span>
      <span>
        <v-icon class="icon">mdi-home</v-icon>
      </span>
    </v-btn>

    <v-btn
      :class="{'active-btn': active === 1}"
      text
      to="/comunidade"
      @click="active = 1"
    >
      <span>Comunidade</span>
      <span>
        <v-icon class="icon">mdi-account-group</v-icon>
      </span>
    </v-btn>

    <v-btn
      :class="{'active-btn': active === 2}"
      text
      to="/series"
      @click="active = 2"
    >
      <span>Séries</span>
      <span>
        <v-icon class="icon">mdi-movie</v-icon>
      </span>
    </v-btn>

    <v-btn
      :class="{'active-btn': active === 3}"
      text
      to="/blog"
      @click="active = 3"
    >
      <span>Blog</span>
      <span>
        <v-icon class="icon">mdi-newspaper</v-icon>
      </span>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
    };
  }
};
</script>


<style scoped>
.mobile-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

.v-btn {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: white;
}

.v-btn .v-icon {
  color: white;
}

.active-btn {
  color: #451755 !important;
}

.active-btn .v-icon {
  color: #451755 !important;
}

@media (min-width: 320px) and (max-width: 650px) {
  .mobile-nav {
    display: flex;
  }
}

@media (min-width: 651px) {
  .mobile-nav {
    display: none;
  }
}
</style>


