<template>
  <v-app-bar app color="black" dark elevate-on-scroll>
    <div class="app-bar-content">
      <v-toolbar-title>
        <router-link to="/">
          <v-img class="logo-image" src="@/assets/images/Rectangle 3.png" alt="Logo" contain height="40"></v-img>
        </router-link>
      </v-toolbar-title>

      <div class="nav">
        <v-btn text to="/" class="nav-button">Home</v-btn>
        <v-btn text to="/comunidade" class="nav-button">Comunidade</v-btn>
        <v-btn text to="/series" class="nav-button">Series</v-btn>
        <v-btn text to="/blog" class="nav-button">Blog</v-btn>
      </div>

      <div class="container-btns">
        <!-- Ícone de Busca -->
        <v-btn icon v-if="!showSearch" @click="toggleSearch">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <!-- Campo de Busca -->
        <div class="search-container" v-if="showSearch">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="searchQuery" dense hide-details solo flat prepend-inner-icon="mdi-magnify"
                class="search-input" placeholder="Pesquisar..." @keyup.enter="executeSearch" v-bind="attrs" v-on="on"
                @input="searchFilter"></v-text-field>
            </template>
            <v-list v-show="filteredResults.length > 0" dark>
              <v-list-item v-for="(item, index) in filteredResults" :key="index" :to="`/obra/${item.id}`">
                <v-list-item-avatar tile>
                  <v-img :src="item.project_thumb"></v-img>
                </v-list-item-avatar>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

        </div>

        <!-- Notificações -->
        <v-btn icon to="/notifications">
          <v-icon>mdi-bell-outline</v-icon>
          <v-badge v-show="notifications.length" :content="notifications.length" color="purple" overlap></v-badge>
        </v-btn>

        <!-- Avatar com Menu Dropdown -->
        <v-menu v-if="isAuthenticated" offset-y transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-avatar size="40">
                <v-img :src="profilePhoto" alt="Avatar"></v-img>
              </v-avatar>
            </v-btn>
          </template>

          <v-list class="profile-menu">
            <v-list-item v-for="item in menuItems" :key="item.title"
              @click="item.route && isItemEnabled(item) ? navigateTo(item.route) : null"
              :class="{ 'disabled-menu-item': !isItemEnabled(item) }">
              <v-list-item-icon>
                <v-icon color="purple">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-menu>

        <template v-else>
          <v-btn color="purple" class="ml-3" rounded large to="/login">Entrar</v-btn>
        </template>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import NotificationService from "@/server/NotificationService";
import { getProjectsByName, getSearchProjects } from "@/server/workService";

export default {
  name: "HeaderComponent",
  data() {
    return {
      isAuthenticated: false,
      user: null,
      profilePhoto: "",
      showSearch: false,
      searchQuery: "",
      searchResults: [],
      service: new NotificationService(),
      notifications: [],
      menuItems: [
        { title: "Perfil", icon: "mdi-account", route: "/dashboard/user-profiler" },
        { title: "Painel", icon: "mdi-view-dashboard", route: "/dashboard/painel", },
        { title: "Publicar", icon: "mdi-upload", route: "/dashboard/publicar-obra" },
        { title: "Configuração", icon: "mdi-cog", route: "/dashboard/configuracao" },
        { title: "Sair", icon: "mdi-logout", route: "/login" },
      ],
      filteredResults: [],
      intervalo: null,
      isAuthor: false,
      isAdmin: false
    };
  },
  mounted() {
    this.setUserAccess();
    this.checkAuthentication();
    this.getNotification();
    this.intervalo = setInterval(this.getNotification, 30000);
    
  },
  methods: {
    isItemEnabled(item) {
    if (item.title === 'Painel' || item.title === 'Publicar') {
      return this.isAuthor || this.isAdmin; // Só ativa se o usuário for um autor
    }
    return true; // Outros itens sempre estão habilitados
  },
    setUserAccess() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        this.isAdmin = user.level === 'GM Guild Master';
        this.isAuthor = user.level === 'OA Administrador';
      }
    },
    async searchFilter(val) {
      const params = {
        name: val,
      };

      const results = await getSearchProjects(params);
      this.filteredResults = results.data;
    },
    executeSearch() {
      this.$router.push({ path: '/series', query: { q: this.searchQuery } })
      this.searchQuery = ''
      this.filteredResults = []
    },
    async getNotification() {
      const auth = JSON.parse(localStorage.getItem("auth"));
      const user = JSON.parse(localStorage.getItem("user"));
      if (auth && user) {
        let res = await this.service.list({ news: true });
        this.notifications = res ? res : [];
      }
    },
    checkAuthentication() {
      const auth = JSON.parse(localStorage.getItem("auth"));
      const user = JSON.parse(localStorage.getItem("user"));

      if (auth && user) {
        this.isAuthenticated = true;
        this.user = user;
        this.profilePhoto = user.profile_photo
          ? user.profile_photo
          : "https://i.pinimg.com/564x/d2/98/4e/d2984ec4b65a8568eab3dc2b640fc58e.jpg";
      }
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (!this.showSearch) {
        this.searchQuery = "";
      }
    },
    navigateTo(route) {
      this.$router.push(route);
    },
    async searchProjects() {
      if (!this.searchQuery.trim()) return;

      try {
        const results = await getProjectsByName(this.searchQuery.trim());
        this.searchResults = results.search_works || [];
      } catch (error) {
        console.error("Erro ao buscar projetos:", error);
      }
    },
    beforeDestroy() {
      clearInterval(this.intervalo); // Remove o intervalo ao destruir o componente
    }
  },
};
</script>



<style scoped>
.disabled-menu-item {
  pointer-events: none;
  opacity: 0.5;
}


.profile-menu {
  background: black;
  min-width: 200px;
}

.v-list-item {
  color: white !important;
  font-size: 16px;
  transition: background 0.3s;
}

.v-list-item:hover {
  background: #333;
}

.v-list-item-icon {
  color: white;
}

.search-container {
  position: relative;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #2c2c2c;
  border: 1px solid #444;
  border-radius: 4px;
  margin: 0;
  padding: 8px 0;
  z-index: 10;
  list-style: none;
  max-height: 200px;
  overflow-y: auto;
}

.search-result-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.search-result-item:hover {
  background: #383838;
}

.result-img {
  width: 45px;
  height: 65px;
  border-radius: 4px;
  margin-right: 8px;
  object-fit: cover;
}

.search-results::-webkit-scrollbar {
  width: 8px;
  /* Largura da barra */
}

/* Fundo da barra */
.search-results::-webkit-scrollbar-track {
  background: #1e1e1e;
  /* Cor de fundo */
  border-radius: 4px;
}

/* Thumb da barra (a parte que se move) */
.search-results::-webkit-scrollbar-thumb {
  background: #5a5a5a;
  /* Cor do thumb */
  border-radius: 4px;
}

/* Thumb ao passar o mouse */
.search-results::-webkit-scrollbar-thumb:hover {
  background: #777;
  /* Cor ao hover */
}

.app-bar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nav {
  display: flex;
  gap: 20px;
}


.container-btns {
  display: flex;
  align-items: center;
  gap: 16px;
}

.search-input {
  max-width: 300px;
  transition: all 0.3s ease;
}

@media (max-width: 1024px) {
  .nav {
    gap: 10px;
  }

  .container-btns {
    gap: 12px;
  }
}

@media (max-width: 767px) {
  .app-bar-content {
    flex-wrap: wrap;
  }

  .nav {
    display: none;
  }

  .container-btns {
    gap: 8px;
  }
}

@media (max-width: 420px) {
  .logo-image {
    width: 89px;
    height: 30px;
  }

  .v-icon {
    font-size: 14px !important;
  }

  .v-btn {
    font-size: 10px;
  }

  .ml-3 {
    height: 20px !important;
    width: 50px !important;
  }
}
</style>
