<template>
  <v-container class="banner-section" fluid>
    <v-row>
      <title-session-component title="Banners" tag="h1" />
    </v-row>

      <v-row>
      <v-col cols="12" class="text-right">
        <v-btn color="amber" @click="openAddDialog">Adicionar Banner</v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-4" align="start" justify="start">
      <v-col v-for="(banner, index) in banners" :key="index" cols="12" md="4">
        <v-card class="banner-card">
          <!-- Pré-visualização da Imagem -->
          <v-img :src="banner.image" height="200px" alt="Imagem do Banner">
            <template v-slot:placeholder>
              <v-row justify="center" align="center" class="fill-height">
                <v-icon large>mdi-image</v-icon>
              </v-row>
            </template>
          </v-img>
          <v-card-title>{{ banner.title }}</v-card-title>
          <v-card-subtitle>{{ banner.description }}</v-card-subtitle>
          <v-card-actions>
            <v-btn icon @click="editBanner(banner, index)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn outlined color="error" @click="deleteBanner(banner.id)">
              Deletar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ banner.id ? 'Editar Banner' : 'Adicionar Banner' }}</span>
        </v-card-title>
        <v-card-text>
          <div class="upload-container" @click="triggerFileInput">
            <v-img :src="imagePreview" v-if="imagePreview" height="200px" alt="Pré-visualização"></v-img>
            <span v-if="!imagePreview" class="upload-text">Clique para selecionar uma imagem</span>
          </div>
          <input 
            ref="fileInput" 
            type="file" 
            accept="image/*" 
            style="display: none" 
            @change="previewImage" 
          />
          <v-text-field
            v-model="banner.title"
            label="Título do Banner"
            class="mt-4"
          ></v-text-field>
          <v-text-field
            v-model="banner.link"
            label="Link de redirecionamento"
            class="mt-4"
          ></v-text-field>
          <v-textarea
            v-model="banner.description"
            label="Descrição do Banner"
            rows="4"
            class="mt-4"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="closeDialog">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="save()">
            {{ isEditing ? 'Salvar Alterações' : 'Adicionar' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TitleSessionComponent from "@/components/TitleSessionComponent.vue";
import Banner from "@/models/Banner";
import BannerService from "@/server/BannerService";
export default {
  name: 'BannersDisclosure',
  components: {
    TitleSessionComponent
  },
  data() {
    return {
      search: '',
      banners: [],
      dialog: false,
      imagePreview: null,
      isEditing: false,
      editIndex: null,
      banner: new Banner(),
      service: new BannerService(),
    };
  },
  mounted(){
    this.list();
  },
  methods: {
    async list(){
      let params = {};

      let response = await this.service.list(params);

      this.banners = response;
    },
    async save(){
      let formData = new FormData(); 
      console.log(this.banner);

      if(this.banner.id) formData.append("id", this.banner.id);
      formData.append("title", this.banner.title);
      formData.append("description", this.banner.description);
      formData.append("link", this.banner.link);
      if(this.banner.image && typeof this.banner.image != 'string')
        formData.append("image", this.banner.image);

      if(this.banner.id){
        await this.service.edit(formData);
      }else{
        await this.service.create(formData);
      }
      this.list();
      this.closeDialog();
    },
    openAddDialog() {
      this.isEditing = false;
      this.resetForm();
      this.dialog = true;
    },
    editBanner(banner) {
      this.banner = banner;
      this.imagePreview = banner.image;
      this.dialog = true;
    },
    async deleteBanner(id) {
      let params = {id}
      await this.service.delete(params);
      this.list();
    },
    resetForm() {
      this.banner = new Banner();
      this.imagePreview = null;
      this.editIndex = null;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
          this.banner.image = file;
        };
        reader.readAsDataURL(file);
      }
    },
    // saveBanner() {
    //   if (this.editIndex) {
    //     this.$set(this.banners, this.editIndex, {
    //       title: this.form.title,
    //       description: this.form.description,
    //       image: this.form.image
    //     });
    //   } else {
    //     this.banners.push({
    //       title: this.form.title,
    //       description: this.form.description,
    //       image: this.form.image
    //     });
    //   }
    //   this.closeDialog();
    // },
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    }
  }
};
</script>

<style scoped>
.banner-section {
  padding: 16px;
}

.banner-card {
  background-color: #333;
  color: #fff;
}

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ccc;
  height: 200px;
  position: relative;
  cursor: pointer;
}

.upload-text {
  color: #aaa;
  text-align: center;
}

.upload-container v-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
