<template>
  <div class="card-user-high">
    <div class="card-main">
      <div class="cicle-image" @click="gottoAuthor(id)">
        <img :src="profileImage || defaultImage" :alt="name" />
      </div>
      <div class="card-info">
        <span class="followers-icon">
          <v-icon size="15">mdi-account-group</v-icon>
        </span>
        <span>{{ followers }}</span>
      </div>
    </div>
    <h1 class="name-user">{{ name }}</h1>
  </div>
</template>

<script>
export default {
  name: 'CardUserHigh',
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    profileImage: {
      type: String,
      required: true,
    },
    followers: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      defaultImage: 'https://thumbs.dreamstime.com/b/%C3%ADcone-de-perfil-avatar-padr%C3%A3o-imagem-vetorial-usu%C3%A1rio-m%C3%ADdia-social-209162840.jpg',
    };
  },

  methods: {
    gottoAuthor(id) {
      this.$router.push(`/autor/${id}`);
    },
  },
};
</script>

<style scoped>
.card-user-high {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  gap: 10px;
  cursor: pointer;
}

.card-main {
  position: relative;
  display: flex;
  justify-content: center;
}

.cicle-image {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  overflow: hidden;
}

.cicle-image img {
  width: 100%;
  height: auto;
  border-radius: 100%;
}

.card-info {
  position: absolute;
  bottom: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1.5px solid #fff;
  border-radius: 30px;
  padding: 8px 16px;
  background-color: blueviolet;
  text-align: center;
  font-size: 14px;
  color: #FFF;
}

.followers-icon {
  font-size: 16px;
}

.name-user {
  color: #FFF;
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
  text-align: center;
}

@media (max-width: 1024px) {
  .cicle-image {
    width: 250px;
    height: 250px;
  }

  .card-info {
    font-size: 20px;
    padding: 10px;
    width: 180px;
  }

  .name-user {
    font-size: 18px;
  }
}

@media (max-width: 850px) {
  .cicle-image {
    width: 180px;
    height: 180px;
  }

  .card-info {
    font-size: 18px;
    padding: 8px 16px;
    width: 150px;
  }

  .name-user {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .cicle-image {
    width: 120px;
    height: 120px;
  }

  .card-info {
    font-size: 14px;
    width: 130px;
    padding: 8px 12px;
  }

  .name-user {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .cicle-image {
    width: 100px;
    height: 100px;
  }

  .card-info {
    font-size: 12px;
    padding: 6px 10px;
    width: 100px;
  }

  .name-user {
    font-size: 12px;
  }
}
</style>
