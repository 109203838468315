<template>
  <v-app class="app">
    <header-component />
    <v-main>
      <router-view />
    </v-main>
    <navegation-mobile />
    <footer-component />
  </v-app>
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
import HeaderComponent from '@/components/HeaderComponent'
import NavegationMobile from '@/components/components-mobile/NavegationMobile.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    NavegationMobile,
    FooterComponent
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped>
.app {
  background-color: #11111a !important;
  width: 100% !important;
}

.v-main {
  background-color: #11111a !important;
}

p {
  color: #fff;
}
</style>
