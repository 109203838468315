<template>
  <div>
    <v-app-bar app color="black" dark elevate-on-scroll class="pl-12 manu-container">
      <v-toolbar-title class="pl-4">
        <router-link to="/">
          <v-img class="logo-image" src="@/assets/images/Rectangle 3.png" alt="Logo" contain height="40"></v-img>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon to="/notifications">
        <v-icon>mdi-bell-outline</v-icon>
        <v-badge v-show="notifications.length" :content="notifications.length" color="purple" overlap></v-badge>
      </v-btn>
      <template v-if="isMobile">
        <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
      </template>

      <template v-else>
        <v-btn class="ml-3" rounded large color="purple">
          {{ username }}
        </v-btn>
      </template>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app dark>
      <v-list dense>
        <template v-if="!isAuthor && !isAdmin">
          <v-list-item style=" width: 70%; margin: 0 auto;">
            <v-btn color="#6A1B9A" @click="openModal">
              <v-list-item-content>
                <v-list-item-title style="text-align: center;">Quero ser Autor</v-list-item-title>
              </v-list-item-content>
            </v-btn>
          </v-list-item>

          <v-list-item :disabled="true">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Painel</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <router-link to="/dashboard/user-profiler" style="text-decoration: none;">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Perfil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/dashboard/configuracao" style="text-decoration: none;">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Configurações</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-if="isAuthor && !isAdmin">
          <v-list-item link to="/dashboard/publicar-obra"
            style="background-color: #6A1B9A; color: white; border-radius: 12px; width: 70%; margin: 0 auto;">
            <v-list-item-content>
              <v-list-item-title style="text-align: center;">Publicar</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <router-link to="/dashboard/painel" style="text-decoration: none;">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Painel</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/dashboard/user-profiler" style="text-decoration: none;">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Perfil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/dashboard/configuracao" style="text-decoration: none;">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Configurações</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-if="isAdmin">
          <router-link to="/dashboard/publicar-obra" style="text-decoration: none;">
            <v-list-item
              style="background-color: #6A1B9A; color: white; border-radius: 12px; width: 70%; margin: 0 auto;">
              <v-list-item-content>
                <v-list-item-title style="text-align: center;">Publicar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/dashboard/painel" style="text-decoration: none;">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Painel</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/dashboard/user-profiler" style="text-decoration: none;">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Perfil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <v-subheader>ADMINISTRADOR</v-subheader>

          <router-link to="/dashboard/adm_dashboard" style="text-decoration: none;">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/dashboard/publicar-banner" style="text-decoration: none;">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-image</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Banners</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/dashboard/adm_moderadores" style="text-decoration: none;">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Moderadores</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/dashboard/avisos" style="text-decoration: none;">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-bell</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Avisos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/dashboard/adm_acess_user" style="text-decoration: none;">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Usuários</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/dashboard/configuracao" style="text-decoration: none;">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Configurações</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <template>
      <div>
        <v-dialog v-model="isModalOpen" scrollable full-width>
          <v-card>
            <v-card-title class="headline">Termos e Condições</v-card-title>
            <v-card-text>
              <p class="text">Estamos felizes que você deseja compartilhar suas obras conosco!</p>
              <p class="text">Ao se tornar um autor, você terá a oportunidade de publicar suas obras originais e
                compartilhar suas histórias com a comunidade.</p>

              <h3 class="font-weight-semibold">Termos e Condições:</h3>
              <ul>
                <li>Você concorda em publicar apenas obras autorais.</li>
                <li>Você não deve plagiar ou copiar o trabalho de outros.</li>
                <li>A plataforma não se responsabiliza por qualquer infração de direitos autorais.</li>
              </ul>

              <h3 class="font-weight-semibold">Responsabilidade sobre Plágio:</h3>
              <p class="text">É de sua responsabilidade garantir que as obras que você publica são de sua própria
                autoria.
                Qualquer violação de direitos autorais será de sua responsabilidade.</p>

              <v-checkbox v-model="acceptedTerms"
                label="Eu li e aceito os termos de privacidade e responsabilidade sobre plágio."></v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" @click="cancel">Cancelar</v-btn>
              <v-btn color="primary" @click="confirm" :disabled="!acceptedTerms">Confirmar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>

  </div>
</template>

<script>
import NotificationService from '@/server/NotificationService';
import { becomeAuthor } from '@/server/userServe';

export default {
  name: "HeaderNormal",
  data() {
    return {
      isModalOpen: false, // Controla a visibilidade da modal
      acceptedTerms: false, // Controle do checkbox
      drawer: false,
      isMobile: false,
      isAuthor: true,
      isAdmin: false,
      username: "",
      service: new NotificationService(),
      notifications: new Array(),
      intervalo: null,
    };
  },
  async created() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.username = user ? user.name : "";
    this.getNotification();
    this.intervalo = setInterval(this.getNotification, 30000)
  },
  methods: {
    async getNotification() {
      if (this.username) {
        this.notifications = await this.service.list({ news: true });
      }
    },
    openModal() {
      console.log("Abrindo modal...");
      this.isModalOpen = true; // Abre o modal
    },
    async confirm() {
      await becomeAuthor();
      await this.setUserAccess();
      this.isModalOpen = false;
      console.log('Usuário se tornou autor!');
    },
    cancel() {
      this.isModalOpen = false; // Fecha o modal
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768;
    },
    logout() {
      localStorage.clear();
      this.$router.push('/');
    },

    setUserAccess() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        this.isAdmin = user.level === 'GM Guild Master';
        this.isAuthor = user.level === 'OA Administrador';
      }
    },
  },
  mounted() {
    this.setUserAccess();
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
    clearInterval(this.intervalo); // Remove o intervalo ao destruir o componente
  }
};
</script>

<style scoped>
.v-app-bar {
  padding: 0 20px;
}

.v-btn {
  font-weight: bold;
  font-size: 16px;
}

@media (max-width: 767px) {
  .v-app-bar {
    justify-content: space-between;
    padding: 0 10px;
  }

  .logo-image {
    width: 89px;
    height: 30px;
  }
}

@media (max-width: 520px) {
  .manu-container {
    width: 100% !important;
    left: 0px !important;
  }

  .v-btn {
    height: 20px;
    font-size: 10px;
  }

  .ml-3 {
    height: 20px !important;
    width: 50px !important;
  }
}
</style>
