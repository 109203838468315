<template>
  <v-app>
    <div class="login-wrapper">
      <img src="@/assets/images/bg.png" alt="fundo" class="background-image">
      <div class="login-container">
        <img src="@/assets/images/Rectangle 3.png" alt="Logo" class="logo">
        <div class="login-content">
          <h2>REALIZAR CADASTRO</h2>
          <p class="display-none" >Junte-se à nossa comunidade criativa! Publique suas histórias ou descubra novos quadrinhos, HQs, novels e mangás feitos por autores independentes.</p>
          <v-form @submit.prevent="registerUserHandler" ref="form">
            <v-text-field
              label="Nome"
              v-model="name"
              :rules="nameRules"
              required
            ></v-text-field>
            <v-text-field
              label="Nickname"
              v-model="nickname"
              :rules="nicknameRules"
              required
            ></v-text-field>
            <v-text-field
              label="Email"
              v-model="email"
              :rules="emailRules"
              required
            ></v-text-field>
            <v-text-field
              label="Senha"
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              :rules="passwordRules"
              required
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="togglePasswordVisibility('password')"
              append-icon-class="password-icon"
            ></v-text-field>
            <v-text-field
              label="Confirmar Senha"
              :type="showPasswordConfirmation ? 'text' : 'password'"
              v-model="password_confirmation"
              :rules="passwordConfirmationRules"
              required
              :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="togglePasswordVisibility('confirmation')"
              append-icon-class="password-icon"
            ></v-text-field>
            <p class="login">
              Já tem conta? 
              <router-link to="/login" style="text-decoration: none;">Efetuar login</router-link>
            </p>
            <v-btn
              :loading="isLoading"
              :disabled="isLoading"
              color="orange"
              class="btn-login"
              type="submit"
            >
              <template v-if="!isLoading">CADASTRAR</template>
              <template v-else>Carregando...</template>
            </v-btn>
            <div v-if="error" class="error">{{ error }}</div>
            <div v-if="success" class="success">{{ success }}</div>
          </v-form>
        </div>
        <img src="@/assets/images/PersonZ.png" alt="Character" class="character-image">
      </div>
      <div class="bd-degrade"></div>
    </div>
  </v-app>
</template>

<script>
import { registerUser } from '../server/userServe';
import {
  nameRules,
  nicknameRules,
  emailRules,
  passwordRules,
  passwordConfirmationRules
} from '@/utils/validationUtils';

export default {
  name: 'RegisterPage',
  data() {
    return {
      name: '',
      nickname: '',
      email: '',
      password: '',
      password_confirmation: '',
      showPassword: false,
      showPasswordConfirmation: false,
      error: null,
      success: null,
      isLoading: false,
      nameRules,
      nicknameRules,
      emailRules,
      passwordRules,
    };
  },
  computed: {
    passwordConfirmationRules() {
      return passwordConfirmationRules(this.password);
    },
  },
  methods: {
    async registerUserHandler() {
      const isValid = this.$refs.form.validate();

      if (!isValid) {
        this.error = 'Por favor, corrija os erros no formulário.';
        return;
      }

      this.isLoading = true;

      const dataUser = {
        name: this.name,
        nickname: this.nickname,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
      };

      try {
        this.success = await registerUser(dataUser);
        this.$refs.form.reset();
        this.success = 'Cadastro realizado com sucesso!';
        this.$router.push('/login');
        this.error = null;
      } catch (error) {
        this.error = error.message || 'Erro ao registrar usuário';
        this.success = null;
        console.error('Erro ao registrar usuário:', this.error);
      } finally {
        this.isLoading = false;
      }
    },
    togglePasswordVisibility(type) {
      if (type === 'password') {
        this.showPassword = !this.showPassword;
      } else if (type === 'confirmation') {
        this.showPasswordConfirmation = !this.showPasswordConfirmation;
      }
    },
  },
};
</script>






<style scoped>
.character-image {
  position: absolute;
  left: 490px;
  bottom: 0;
  height: 660px;
  z-index: -20;
}

.password-icon {
  margin-left: -100px !important;
  z-index: 10;
}
.v-btn {
  margin-bottom: 14px;
  width: 40%;
  color: #FFFF;
  font-size: 19px;
  font-weight: 700;
}

.login {
  text-align: start;
}

.bd-degrade {
  position: absolute;
  background: linear-gradient(to bottom, rgba(5, 5, 5, 0.774), #7a12aac2);
  height: 100vh;
  width: 100%;
  z-index: 2;
}

.error {
  color: red;
}

.success {
  color: green;
}
@media (max-width: 540px) {
  .display-none {
    display: none;
  }

  .login-container{
    width: 100%;
    height: 100%
  }
  .background-image{
    display: none
  }

  .btn-login{
    padding: 8px;
    font-size: 16px;
  }
}
</style>
