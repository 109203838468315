<template>
  <v-container class="container-main">
    <v-row class="align-center">
      <!-- Imagem da Obra -->
      <v-col cols="12" md="3" class="d-flex justify-center">
        <v-img :src="obra.thumb" class="ma-3" max-width="290px"></v-img>
      </v-col>

      <!-- Informações da Obra -->
      <v-col cols="12" md="7">
        <v-card-title class="text-h4 title text-md-left text-center">
          {{ obra.name }}
        </v-card-title>
        <v-card-subtitle class="text-h6 subtitle text-md-left text-center">
          Capítulos: {{ obra.chapters }}
        </v-card-subtitle>
        <p class="description text-md-left text-center">
          {{ obra.description }}
        </p>

        <v-row>
          <v-col cols="12" md="6">
            <div class="info-text text-md-left text-center">
              <strong class="strong-white">Data de publicação:</strong>
              {{ obra.created_at | formataData }}
            </div>
            <div class="info-text text-md-left text-center">
              <strong class="strong-white">Última atualização:</strong>
              {{ obra.updated_at | formataDataHora }}
            </div>
          </v-col>
        </v-row>
      </v-col>

      <!-- Botão de Editar -->
      <v-col cols="12" md="2" class="d-flex justify-center justify-md-end mt-3 mt-md-0">
        <v-btn @click="openEditModal" color="purple" dark>
          Editar Obra
          <v-icon color="#FFF">mdi-pencil</v-icon>
        </v-btn>
      </v-col>
    </v-row>


    <v-dialog v-model="isEditModalOpen" max-width="500px">
      <v-card>
        <v-card-title class="headline">Editar Obra</v-card-title>
        <v-card-text>
          <v-container>
            <!-- Campo para Título -->
            <v-text-field label="Título" v-model="editableObra.name" outlined dense></v-text-field>
            <v-row>
              <v-col cols="6">
                <v-select label="Categoria" v-model="editableObra.project_category_id" :items="categorias"
                  item-text="name" item-value="id" outlined dense disabled class="custom-input"></v-select>
              </v-col>
              <v-col cols="6">
                <v-select label="Gênero" v-model="editableObra.gender_id" :items="generos" item-text="name"
                  item-value="id" outlined dense class="custom-input"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-select label="Idioma" v-model="editableObra.language_id" :items="idiomas" item-text="name"
                  item-value="id" outlined dense disabled class="custom-input"></v-select>
              </v-col>
              <v-col cols="6">
                <v-select label="Tipo da Obra" v-model="editableObra.project_type_id" :items="tiposObra"
                  item-text="name" item-value="id" outlined dense disabled class="custom-input"></v-select>
              </v-col>
            </v-row>
            <!-- Campo para Descrição -->
            <v-textarea label="Descrição" v-model="editableObra.description" outlined dense rows="3"></v-textarea>

            <!-- Campo para Imagem -->
            <v-file-input label="Imagem da Obra" v-model="editableObra.thumb" outlined dense
              accept="image/*"></v-file-input>

            <!-- Exibir miniatura da imagem atual -->
            <v-img v-if="!editableObra.thumb && obra.thumb" :src="obra.thumb" class="ma-3" max-width="100px"></v-img>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="saveEditedObra">Salvar</v-btn>
          <v-btn color="red darken-1" text @click="closeEditModal">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <add-capitulo :idObra="obraId" @updateNewChapter="updateNewChapter" v-model="isModalOpen"></add-capitulo>

    <v-row class="align-center">
      <!-- Título -->
      <v-col cols="12" md="6">
        <h2 class="title text-md-left text-center">Lista de Capítulos</h2>
      </v-col>

      <!-- Botão de Adicionar Capítulo -->
      <v-col cols="12" md="6" class="d-flex justify-center justify-md-end mt-3 mt-md-0">
        <v-btn color="purple" dark @click="openModal">
          Adicionar novo capítulo
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>


    <!-- Verifica se há capítulos -->
    <v-row v-if="obra.chapters === 0 || obra.chaptersList.length === 0">
      <v-col>
        <p class="text-h6">Este projeto ainda não tem nenhum capítulo disponível.</p>
      </v-col>
    </v-row>

    <!-- Renderiza capítulos se existirem -->
    <template v-else>
      <v-row class="card-chapter mb-4" v-for="(chapter, index) in obra.chaptersList" :key="index">
        <!-- Botão de excluir no canto superior direito no mobile -->
        <v-col cols="12" class="d-flex justify-end d-md-none">
          <v-btn class="btn" icon @click="deleteChapter(chapter.id)" color="purple" dark>
            <v-icon color="#FFF">mdi-delete</v-icon>
          </v-btn>
        </v-col>

        <!-- Imagem do capítulo -->
        <v-col cols="12" md="3" class="d-flex justify-center justify-md-start">
          <v-img :src="chapter.thumb" class="ma-3" max-width="120px" max-height="240px"></v-img>
        </v-col>

        <!-- Informações do capítulo -->
        <v-col cols="12" md="7" @click="goToChapter(chapter.id)">
          <v-card-title class="text-h6 title">Capítulo: {{ chapter.number }}</v-card-title>
          <p class="description">{{ chapter.description }}</p>

          <v-row>
            <v-col cols="6">
              <div class="info-text">
                <strong class="strong-white">Data de publicação:</strong> {{ chapter.publication_date }}
              </div>
            </v-col>
            <v-col cols="6">
              <div class="info-text">
                <strong class="strong-white">Última atualização:</strong> {{ chapter.last_update }}
              </div>
            </v-col>
          </v-row>
        </v-col>

        <!-- Botão de excluir apenas no desktop -->
        <v-col cols="12" md="2" class="d-none d-md-flex align-center justify-end">
          <v-btn class="btn" icon @click="deleteChapter(chapter.id)" color="purple" dark>
            <v-icon color="#FFF">mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" top>
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>


<script>
import AddCapitulo from '@/components/AddCapitulo.vue';
import { getchapters, getGenders } from '../server/workService.js';
import TitleService from '@/server/TitleService.js';
import { EditProject } from '@/server/editProject.js';
import UtilMixins from '@/mixins/UtilMixins.js';

export default {
  name: "ListCapituloView",
  mixins: [UtilMixins],
  components: {
    AddCapitulo
  },
  data() {
    return {
      isEditModalOpen: false,
      editableObra: {},
      snackbar: {
        show: false,
        message: '',
        color: '',
        timeout: 3500,
      },
      isModalOpen: false,
      obraId: null,
      obra: {
        name: '',
        thumb: '',
        description: '',
        chapters: 0,
        publication_date: '',
        last_update: '',
        chaptersList: []
      },
      service: new TitleService(),
      idiomas: [
        { id: 1, name: 'Português', value: 'pt-br' },
        { id: 2, name: 'Inglês' },
        { id: 3, name: 'Espanhol' }
      ],
      tiposObra: [
        { id: 1, name: 'Série' },
        { id: 2, name: 'On Shot' },
        { id: 6, name: 'Revista' }
      ],
      categorias: [
        { id: 1, name: 'Quadrinhos' },
      ],
      generos: [],
    };

  },
  props: ['idObra'],
  async created() {
    this.list();
    this.generos = await getGenders();
  },
  methods: {
    openEditModal() {
      this.editableObra = {
        ...this.obra,
        // category: this.categorias.find(c => c.name === this.obra.category) || null,
        // genero: this.generos.find(g => g.name === this.obra.genero) || null,
        // language: this.idiomas.find(l => l.value === this.obra.language) || null,
        // type: this.tiposObra.find(t => t.name === this.obra.type) || null,
      };

      console.log(this.generos); // Para depuração
      this.isEditModalOpen = true;
    },

    closeEditModal() {
      this.isEditModalOpen = false; // Fechar o modal sem salvar
    },
    saveChanges() {
      // Salvar mudanças (aqui você pode chamar um método ou API)
      this.$emit("update-obra", this.editableObra); // Emite evento para atualizar a obra
      this.closeEditModal(); // Fecha o modal após salvar
    },
    async list() {
      try {
        this.obraId = this.idObra
        const chapters = await getchapters(this.idObra);
        this.obra = {
          ...chapters.project,
          chaptersList: chapters.chapters
        };
      } catch (error) {
        this.showSnackbar('Erro ao carregar dados do projeto com ID:', this.idObra, 'error');
      }
    },
    updateNewChapter(newObras) {
      this.obra.chaptersList = newObras;
    },
    async deleteChapter(id) {
      console.log("id chapter deleted: ", id);
      let params = { id };
      await this.service.delete(params);
      this.list();
    },
    openModal() {
      this.isModalOpen = true;
    },
    goToChapter(idCapitulo) {
      this.$router.push({ name: 'ListPagesCapituloView', params: { idCapitulo } });
    },
    showSnackbar(message, type) {
      this.snackbar.message = message;
      this.snackbar.color = type === 'success' ? 'green' : 'red';
      this.snackbar.show = true;
    },
    async saveEditedObra() {
      if (!this.editableObra) return;

      const formattedData = new FormData();
      formattedData.append('id', this.editableObra.id);
      formattedData.append('name', this.editableObra.name);
      formattedData.append('description', this.editableObra.description);
      formattedData.append('project_category_id', this.editableObra.project_category_id);
      formattedData.append('gender_id', this.editableObra.gender_id);
      formattedData.append('project_type_id', this.editableObra.project_type_id);
      formattedData.append('language_id', this.editableObra.language_id);

      if (typeof this.editableObra.thumb != 'string')
        formattedData.append('project_thumb', this.editableObra.thumb);

      console.log(formattedData);

      await EditProject(formattedData)
      this.list();
      this.isEditModalOpen = false;
    }

  }
};
</script>



<style style scoped>
p{
  color: #ABABAB !important;
}
.card-chapter {
  background-color: #161515 !important;
  cursor: pointer;
}

.card-chapter:hover {
  background-color: #2b2a28 !important;
}

.container-main {
  margin: 0, 44px;
}

.v-img {
  border-radius: 8px;
}

.v-btn {
  margin-left: 8px;
}

.title {
  font-size: 30px;
  font-weight: bold;
  color: #FFFF;
}

.subtitle {
  font-size: 24px;
  font-weight: 600;
  color: #ABABAB;
}

.description {
  font-size: 16px;
  font-weight: 500;
  color: #ABABAB;
}

.strong-white {
  font-size: 14px;
  font-weight: bold;
  color: #FFF;
}

.info-text {
  font-size: 14px;
  font-weight: 400;
  color: #ABABAB;
}

.btn {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: #451755;
}
</style>