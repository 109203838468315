<template>
  <v-container fluid class="pa-0">
    <v-carousel 
      cycle 
      :height="carouselHeight" 
      interval="5000" 
      class="carousel-container"
      hide-delimiter-background
    >
      <v-carousel-item v-for="(slide, i) in slides" :key="i" class="carousel-item">
        <v-img :src="slide.banner_image" class="banner-img">
          <div class="details">
            <v-card-actions class="custom-btns">
              <v-btn :href="slide.link_direction" color="purple" dark class="mr-2">Ler Agora</v-btn>
            </v-card-actions>
            <p class="banner-text">
              {{ slide.description }}
            </p>
          </div>
        </v-img>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>


<script>
export default {
  name: "BannerComponent",
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  computed: {
    carouselHeight() {
      return window.innerWidth <= 768 ? '40vh' : '100vh';
    }
  },
}
</script>

<style scoped>
p {
  color: white !important;
}
::v-deep(.v-carousel__controls) {
  bottom: 10px;
}
::v-deep(.v-carousel__controls__item) {
  width: 8px !important;
  height: 8px !important;
  background-color: rgb(247, 137, 12) !important; /* Cor padrão */
  opacity: 0.5;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

::v-deep(.v-carousel__controls__item--active) {
  background-color: rgb(78, 0, 141) !important; /* Cor da bolinha ativa */
  transform: scale(1.5); /* Aumenta o tamanho da bolinha ativa */
  opacity: 1;
}

.banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container {
  margin: 0px !important;
}

.details {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
}

.custom-btns {
  margin-top: 10px;
}

.banner-text {
  font-size: 16px;
  margin-top: 10px;
}
</style>

<style scoped>
.carousel-item {
  height: 100%;
}

.btn-heart {
  height: 60px;
  width: 70px !important;
  border-radius: 10px;
  background-color: #451755;
}

.banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  text-align: center;
}

.v-btn {
  width: 150px;
  height: 50px;
  font-size: 14px;
  margin-bottom: 10px;
}

.custom-card {
  background-color: #451755;
  height: auto;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
}

.custom-title {
  color: white;
  text-align: center;
  font-size: 20px;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .carousel-container {
    height: 40vh !important;
  }

  .details {
    top: 70% !important;
  }

  .v-btn {
    width: 120px;
    height: 40px;
    font-size: 12px;
  }

  .banner-text {
    font-size: 8px !important;
  }

  .custom-title {
    font-size: 16px;
  }

  .custom-btns {
    display: none;
  }
}

@media (min-width: 769px) {
  .carousel-container {
    height: 100vh !important;
  }

  .details {
    top: 80% !important;
  }

  .banner-text {
    font-size: 14px;
  }

}
</style>
