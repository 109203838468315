import axios from "../interceptors/axios";

export const registerUser = async (dataUser) => {
  try {
    const response = await axios.post("/api/register", dataUser);
    return response;
  } catch (error) {
    console.log(error);
    throw error.response.data;
  }
};

export const uploadImageProfile = async (imageProfile) => {
  try {
    const auth = JSON.parse(localStorage.getItem("auth"));
    const accessToken = auth?.access_token;

    if (!accessToken) {
      throw new Error("Token de autenticação não encontrado");
    }
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user?.id;

    if (!userId) {
      throw new Error("ID do usuário não encontrado");
    }
    const formData = new FormData();
    formData.append("image", imageProfile);
    const response = await axios.post(`/api/profile/image`, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao enviar a imagem:", error);
    throw error.response?.data || error.message;
  }
};

export const updateProfile = async (dataUser) => {
  try {
    const auth = JSON.parse(localStorage.getItem("auth"));
    const accessToken = auth?.access_token;

    if (!accessToken) {
      throw new Error("Token de autenticação não encontrado");
    }

    const response = await axios.put(`/api/profile`, dataUser, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Erro na requisição:", error.response.data);
      throw new error(error.message);
    } else {
      console.error("Erro:", error.message);
    }
  }
};

export function formatDateToISO(dateString) {
  const [day, month, year] = dateString.split("/");
  return `${year}-${month}-${day}`;
}

export const findInfoUser = async () => {
  try {
    const auth = JSON.parse(localStorage.getItem("auth"));
    const accessToken = auth?.access_token;

    if (!accessToken) {
      throw new Error("Token de autenticação não encontrado");
    }
    const response = await axios.get(`/api/profile`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const resetPassword = async (newPasword) => {
  try {
    const auth = JSON.parse(localStorage.getItem("auth"));
    const accessToken = auth?.access_token;

    if (!accessToken) {
      throw new Error("Token de autenticação não encontrado");
    }

    console.log(newPasword);

    const response = await axios.put(`/api/password`, newPasword, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

export const getFavoriteProjects = async () => {
  const authData = JSON.parse(localStorage.getItem("auth"));

  try {
    const response = await axios.get(`/api/projects/favorites`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authData.access_token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar projetos favoritos:", error);
    throw error.response ? error.response.data : error.message;
  }
};

//me tornar um autor
export const becomeAuthor = async () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const user = JSON.parse(localStorage.getItem("user"));
  console.log(user.level);
  const userId = user?.id;
  const accessToken = auth?.access_token;
  try {
    const response = await axios.put(
      `/api/users/change/author`,
      { user_id: userId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const updatedUser = { ...user, level: "OA Administrador" };
    localStorage.setItem("user", JSON.stringify(updatedUser));

    console.log("Usuário atualizado no Local Storage:", updatedUser);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

//Detalhes do autor

export const detailsAuthor = async (authorId) => {
  console.log(authorId);
  try {
    const response = await axios.get(`/api/author/profile/${authorId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    console.log("Resposta detalhes do autor", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
