<template>
  <v-container fluid class="pa-8">
    <v-row dense>
      <v-col cols="12" md="6" lg="6" v-for="(item, index) in stats" :key="index">
        <v-card
          dark
          max-width="650"
          class="text-center hover"
        >
          <v-card-title class="d-flex justify-center align-center">
            <v-icon size="40">{{ item.icon }}</v-icon>
          </v-card-title>
          <v-card-subtitle class="text-h5">{{ item.title }}</v-card-subtitle>
          <v-card-text class="text-h4">{{ item.value }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AdmDashboardView",
  data() {
    return {
      stats: [
        { icon: 'mdi-account-multiple', title: 'Usuários', value: '34.000' },
        { icon: 'mdi-account', title: 'Autores', value: '14.000' },
        { icon: 'mdi-pen', title: 'Obras', value: '34.000' },
        { icon: 'mdi-account-group', title: 'Moderadores', value: '60' },
        { icon: 'mdi-comment', title: 'Comentários', value: '34.000' },
        { icon: 'mdi-book-open-page-variant', title: 'Capítulos', value: '14.000' },
        { icon: 'mdi-chart-bar', title: 'Acessos', value: '374.000' },
        { icon: 'mdi-handshake', title: 'Editoras', value: '20' },
      ],
    };
  },
};
</script>

<style scoped>
.hover {
  background-color: #333333;
  transition: background-color 0.3s ease;
}

.hover:hover {
  background-color: #f4a52a !important;
}

.v-card {
  padding: 20px;
  cursor: pointer;
}
.v-icon {
  color: white;
}
</style>
