<template>
  <v-app>
    <div class="login-wrapper">
      <img src="@/assets/images/bg.png" alt="fundo" class="background-image">

      <div class="login-container">
        <img src="@/assets/images/Rectangle 3.png" alt="Logo" class="logo">

        <div class="login-content">
          <h2>LOGIN</h2>
          <p>Bem-vinda(o) de volta! Acesse sua conta para continuar aproveitando nossos serviços e explorar novas
            oportunidades.</p>

          <v-form @submit.prevent="loginUser" ref="form">
            <v-text-field v-model="email" label="Email" :rules="emailRules" required></v-text-field>
            <v-text-field
              v-model="password"
              label="Senha"
              :type="showPassword ? 'text' : 'password'"
              :rules="passwordRules"
              required
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="togglePasswordVisibility"
            ></v-text-field>
            <p>
              Esqueceu a senha? 
              <router-link to="/forgot-password" style="text-decoration: none;">Recuperar</router-link>
            </p>
            <v-btn :loading="isLoading" :disabled="isLoading" color="orange" class="btn-login" type="submit">
              <template v-if="!isLoading">ENTRAR</template>
              <template v-else>Carregando...</template>
            </v-btn>
            <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
          </v-form>

          <p>
            Não tem conta?
            <router-link to="/cadastro" style="text-decoration: none;">Criar conta</router-link>
          </p>
        </div>

        <img src="@/assets/images/Miry.png" alt="Character" class="character-image">
      </div>
      <div class="bd-degrade"></div>
    </div>
  </v-app>
</template>

<script>
import { login } from '../server/authServe';
import { emailRules, passwordRules } from '@/utils/validationUtils';

export default {
  name: 'LoginPage',
  data() {
    return {
      email: '',
      password: '',
      errorMessage: null,
      showPassword: false,
      isLoading: false,
      emailRules,
      passwordRules,
    };
  },
  methods: {
    async loginUser() {
      const isValid = this.$refs.form.validate();

      if (!isValid) {
        this.errorMessage = 'Por favor, corrija os erros no formulário.';
        return;
      }

      this.isLoading = true;

      try {
        const res = await login(this.email, this.password);
        console.log(res);
        this.$router.push('/');
      } catch (error) {
        this.errorMessage = error.message || 'Erro ao fazer login';
        console.error('Erro ao fazer login:', this.errorMessage);
      } finally {
        this.isLoading = false;
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  }
}
</script>





<style>
.login-wrapper {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000bb;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.bd-degrade {
  position: absolute;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.644), #b67f07d8);
  height: 100vh;
  width: 100%;
  z-index: 2;
}

.login-container {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 40px;
  max-width: 600px;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  z-index: 3;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

.login-content h2 {
  font-weight: bold;
  margin-bottom: 20px;
}

.login-content p {
  margin-bottom: 30px;
  color: #555;
}

.character-image {
  position: absolute;
  left: -200px;
  bottom: 0;
  height: 560px;
  z-index: -3;
}

.btn-login {
  margin-bottom: 14px;
  width: 45%;
  color: #FFFF !important;
  font-size: 19px;
  font-weight: 700;
}

a {
  color: orange;
  text-decoration: none;
}

.forgot-password {
  text-align: end;

}

@media (max-width: 430px) {
  .character-image {
    display: none;
  }
}
</style>
