import { render, staticRenderFns } from "./PostWorkView.vue?vue&type=template&id=104e150f&scoped=true"
import script from "./PostWorkView.vue?vue&type=script&lang=js"
export * from "./PostWorkView.vue?vue&type=script&lang=js"
import style0 from "./PostWorkView.vue?vue&type=style&index=0&id=104e150f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "104e150f",
  null
  
)

export default component.exports