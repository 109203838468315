<template>
  <div class="pa-4 ">
    <div>
      <v-card class="pa-4" outlined>
        <h2 class="text-h5 text-center mb-4">Escrever Novo Capítulo</h2>
        <v-form ref="chapterForm" v-model="formIsValid">
          <v-text-field v-model="chapterTitle" label="Título do Capítulo" :rules="[rules.required]" outlined
            dense></v-text-field>

          <div class="editor-container">
            <quill-editor ref="quillEditor" class="quill-container" v-model="chapterContent"
              :options="editorOptions"></quill-editor>
          </div>

          <v-select v-model="chapterStatus" :items="visibilityOptions" label="Visibilidade" outlined dense
            :item-text="(item) => item.label" :item-value="(item) => item.value"></v-select>

          <div class="d-flex flex-column  flex-md-row justify-md-between">
            <v-btn color="purple darken-2" class="mt-4 mr-8" :disabled="!formIsValid" @click="saveChapter">
              Salvar
            </v-btn>
            <v-btn color="purple darken-2" class="mt-4" @click="resetForm">
              Novo
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </div>

    <v-col cols="12">
      <h2 class="text-white">Capítulos Existentes</h2>
      <v-row>
        <v-card v-for="(chapter, index) in chapters" :key="index" class="pa-3 ma-2 w-325" outlined>
          <h3>Capítulo {{ index + 1 }}</h3>
          <p class="text-grey">{{ chapter.title }}</p>
          <v-chip class="chip" :color="chapter.published ? 'green' : 'grey'" dark small>
            {{ chapter.published ? 'Publicado' : 'Não publicado' }}
          </v-chip>
          <v-card-actions class="justify-between">
            <v-btn color="purple darken-2" class="mt-2" @click="editChapter(index)">
              Editar
            </v-btn>
            <v-btn color="error darken-2" class="mt-2" @click="excluir(chapter.id)">
              Excluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-col>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" top>
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import TitleService from "@/server/TitleService";
import { createChapter, updateChapter, listChapters } from "../server/createBook";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.snow.css";
import { uploadImage } from "@/server/file";

export default {
  name: "CreateChapterBookView",
  components: {
    quillEditor,
  },
  props: {
    idObra: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chapterTitle: "",
      chapterContent: "",
      chapters: [],
      chapterStatus: false,
      editingChapterId: null,
      formIsValid: false,
      service: new TitleService(),
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
      },
      visibilityOptions: [
        { label: "Público", value: true },
        { label: "Privado", value: false },
      ],
      snackbar: {
        show: false,
        message: '',
        color: '',
        timeout: 2500,
      },
      editorOptions: {
        theme: "snow",
        placeholder: "Escreva seu capítulo aqui...",
        modules: {
          toolbar: {
            container: [
              [{ 'header': [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'align': [] }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'color': [] }],
              ['blockquote', 'code-block'],
              ['link', 'image'],
              ['clean']
            ],
            handlers: {
              image: this.uploadImage, // Usa o método uploadImage como handler
            },
          },
        },
      },
    };
  },
  async created() {
    try {
      await this.loadChapters(this.idObra);
    } catch (error) {
      this.showSnackbar('Erro ao carregar capítulos.', 'error');
      console.error(error);
    }
  },
  methods: {
    async uploadImage() {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();

      input.onchange = async () => {
        const file = input.files[0];
        if (!file) return;

        try {
          const imageUrl = await uploadImage(file);
          console.log("URL da imagem:", imageUrl); // Verifique a URL retornada

          const quill = this.$refs.quillEditor.quill;
          const range = quill.getSelection();
          quill.insertEmbed(range.index, "image", imageUrl);
          quill.setSelection(range.index + 1);

          this.showSnackbar("Imagem carregada com sucesso!", "success");
        } catch (error) {
          console.error("Erro no upload:", error.response || error); // Log detalhado do erro
          this.showSnackbar("Erro ao fazer upload da imagem.", "error");
        }
      };
    },
    showSnackbar(message, type) {
      this.snackbar.message = message;
      this.snackbar.color = type === 'success' ? 'green' : 'red';
      this.snackbar.show = true;
    },
    async saveChapter() {
      if (this.$refs.chapterForm.validate()) {
        const chapterData = {
          title: this.chapterTitle,
          content: this.chapterContent,
          published: this.chapterStatus,
          project_id: Number(this.idObra),
        };

        try {
          if (this.editingChapterId) {
            await updateChapter(this.editingChapterId, chapterData);
            await this.loadChapters(this.idObra);
            await this.resetForm();
            this.showSnackbar('Capítulo atualizado com sucesso!', 'success');
          } else {
            await createChapter(chapterData);
            await this.loadChapters(this.idObra);
            await this.resetForm();
            this.showSnackbar('Capítulo criado com sucesso!', 'success');
          }
        } catch (error) {
          this.showSnackbar('Erro ao salvar capítulo.', 'error');
          console.error(error);
        }
      } else {
        this.showSnackbar('O formulário contém erros. Verifique os campos obrigatórios.', 'error');
      }
    },
    async loadChapters(bookId) {
      try {
        const response = await listChapters(bookId);
        this.chapters = response;
        this.showSnackbar('Capítulos carregados com sucesso.', 'success');
      } catch (error) {
        this.showSnackbar('Erro ao carregar capítulos.', 'error');
        console.error(error);
      }
    },
    editChapter(index) {
      const chapter = this.chapters[index];
      this.chapterTitle = chapter.title;
      this.chapterContent = chapter.content;
      this.chapterStatus = chapter.published;
      this.editingChapterId = chapter.id;
      this.showSnackbar('Capítulo em edição.', 'success');
    },
    resetForm() {
      this.chapterTitle = "";
      this.chapterContent = "";
      this.chapterStatus = false;
      this.editingChapterId = null;
      this.$refs.chapterForm.resetValidation();
      this.showSnackbar('Formulário reiniciado.', 'success');
    },
    async excluir(id) {
      let params = { id };
      await this.service.delete(params);
      await this.loadChapters(this.idObra);
    },
  },
};
</script>

<style scoped>
.editor-container {
  margin-bottom: 20px;
}

.quill-container {
  min-height: 300px;
  height: auto;
}
</style>

<style>
p {
  color: #000000 !important;
}

.editor-container {
  min-height: 400px;
  max-height: 900px;
  overflow-y: auto;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-editor p {
  color: #000000 !important;
}

h2 {
  margin-bottom: 20px;
}

.pa-20 {
  padding: 40px;
}

.text-white {
  color: wheat;
}

.text-grey {
  color: rgb(90, 89, 89);
}

.card {
  position: relative;
}

.chip {
  position: absolute;
  top: 10px;
  right: 10px;
}

.w-325 {
  width: 325px;
}

.editor-container {
  background: white;
  border-radius: 5px;
  padding: 10px;
}

@media (max-width: 700px) {
  .quill-container img {
    max-width: 80%;
    /* Limita a largura da imagem para 80% da largura do contêiner no mobile */
    margin: 0 auto;
    /* Centraliza a imagem */
    display: block;
    /* Garante que a imagem seja exibida como bloco para centralização */
  }
}
</style>
