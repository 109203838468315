<template>
  <div>
    <v-container class="principle">
      <v-row class="message-edit">
        <v-col>
          <h2>Conteúdo da mensagem</h2>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            name="input-7-1"
            label="Insira a mensagem aqui..."
            dark
            outlined
            class="custom-textarea"
            v-model="form.content"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            outlined dark
            v-model="form.users"
            :items="users"
            item-value="id"
            item-text="name"
            multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" >
         <div >
          <v-container  fluid>
            <v-checkbox
              dark
              v-model="form.all"
              class="custom-checkbox"
            >
              <template v-slot:label>
                <span class="white--text">Todos os usuários</span>
              </template>
            </v-checkbox>
          </v-container>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn
          color="purple principle"
          class="ml-3"
          rounded
          large
            @click="save()"
        >
          Enviar
        </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>

<v-container  class="principle">
  <v-col cols="12" md="12">
    <h2>Denúncias</h2>
  </v-col>
  <v-col>
    <div class="message-box">
      <div>
        <div class="ban-set">
          <h3>João denunciou Marcos</h3>
          <p class="desc">Motivo: Mensagem imprópria</p>
        </div>

        <div>

        </div>
      </div>
    </div>
  </v-col>
</v-container>
  </div>
</template>

<script>
import NotificationService from '@/server/NotificationService';
import UserService from '@/server/UserService';
class FormNotification{
  constructor(){
    this.content = '';
    this.all = false;
    this.users = [];
  }
}

export default {
  name: "ContentMessage",
  data() {
    return {
      checkbox: false,
      service: new NotificationService(),
      form: new FormNotification(), 
      users: new Array(),
      user_service: new UserService(),
    };
  },
  created(){
    this.userList();
  },
  methods:{
    async save(){
      let res = this.service.create(this.form);
      console.log(res);
      this.form = new FormNotification();
    },
    async userList(){
      this.users = await this.user_service.list({});
    }
  }
};
</script>

<style scoped>

.message-edit {
  display: contents;
}

.principle {
  color: white;
}

.custom-checkbox .v-input--selection-controls__ripple {
  background-color: white !important; /* Fundo da caixa de seleção em branco */
}

.custom-checkbox .v-input--selection-controls__input {
  background-color: white !important; /* Fundo do input do checkbox */
  border-color: white !important; /* Borda branca */
}

.custom-checkbox .v-icon {
  color: black !important; /* Ícone preto para contraste com fundo branco */
}

.custom-textarea .v-label {
  color: white !important; /* Cor do label do textarea em branco */
}

.custom-textarea .v-input__control {
  color: white !important; /* Cor do texto do textarea */
  background-color: transparent !important; /* Fundo transparente */
}

.custom-textarea .v-fieldset {
  border-color: white !important; /* Bordas do textarea em branco */
}

.custom-textarea .v-input__control::before,
.custom-textarea .v-input__control::after {
  border-color: white !important; /* Bordas brancas do textarea */
}

.custom-textarea .v-input__control:hover {
  border-color: white !important; /* Mantém as bordas brancas ao passar o mouse */
}

.message-box{
  background-color: rgb(83, 83, 83);
  width: 500px;
}
.ban-set{
  padding: 10px 10px 10px 20px;

}

.desc{
  padding-left: 20px;
}
</style>
