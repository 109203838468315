<template>
  <v-container fluid class="pa-8">
    <v-text-field
      v-model="search"
      label="Buscar usuário..."
      prepend-inner-icon="mdi-magnify"
      outlined
      dark
      class="search-bar mb-6 custom-input"
    ></v-text-field>
    <v-simple-table class="custom-table">
      <thead>
        <tr>
          <th></th>
          <th class="text-left">Nome</th>
          <th class="text-left">Email</th>
          <th class="text-left">Gênero</th>
          <th class="text-left">Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="user in filteredUsers"
          :key="user.id"
          :class="{'selected-row': selectedUser.id === user.id}"
          @click="selectUser(user)"
          style="height: 70px; font-size: 16px;"
        >
          <td>
            <v-avatar size="50" class="mr-2">
              <v-icon large>mdi-account-circle</v-icon>
            </v-avatar>
          </td>
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.gender }}</td>
          <td>
            <v-btn icon @click.stop="openDialog(user)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-model="dialog" max-width="800" dark>
      <v-card>
        <v-card-title class="headline">{{ selectedUser.name }}</v-card-title>

        <v-card-text>
          <div>Acessos</div>
          <v-row>
            <v-col cols="6">
              <v-switch
                v-model="selectedUser.commentAccess"
                label="Comentar"
                color="red"
                inset
              ></v-switch>
            </v-col>
            <v-col cols="6">
              <v-switch
                v-model="selectedUser.likeAccess"
                label="Curtir"
                color="red"
                inset
              ></v-switch>
            </v-col>
          </v-row>

          <div class="mt-4">Suspender conta</div>
          <v-row>
            <v-radio-group v-model="selectedUser.suspendDuration">
              <v-col cols="4">
                <v-radio
                  label="1 dia"
                  value="1"
                ></v-radio>
              </v-col>
              <v-col cols="4">
                <v-radio
                  label="7 dias"
                  value="7"
                ></v-radio>
              </v-col>
              <v-col cols="4">
                <v-radio
                  label="Indeterminado"
                  value="indefinite"
                ></v-radio>
              </v-col>
            </v-radio-group>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn  color="red" @click="closeDialog">Cancelar</v-btn>
          <v-btn color="purple" @click="banUser">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
export default {
  name: "AdmAcessUserView",
  data() {
    return {
      search: '',
      dialog: false,
      selectedUser: {},
      users: [
      { id: 1, name: 'Alice Silva', email: 'alice.silva@gmail.com', gender: 'Feminino' },
      { id: 2, name: 'Bruno Oliveira', email: 'bruno.oliveira@yahoo.com', gender: 'Masculino' },
      { id: 3, name: 'Carla Santos', email: 'carla.santos@hotmail.com', gender: 'Feminino' },
      { id: 4, name: 'Daniel Costa', email: 'daniel.costa@gmail.com', gender: 'Masculino' },
      { id: 5, name: 'Eduarda Lima', email: 'eduarda.lima@gmail.com', gender: 'Feminino' },
      { id: 6, name: 'Felipe Almeida', email: 'felipe.almeida@yahoo.com', gender: 'Masculino' },
      { id: 7, name: 'Gabriela Rocha', email: 'gabriela.rocha@hotmail.com', gender: 'Feminino' },
      { id: 8, name: 'Henrique Ferreira', email: 'henrique.ferreira@gmail.com', gender: 'Masculino' },
      { id: 9, name: 'Isabela Martins', email: 'isabela.martins@yahoo.com', gender: 'Feminino' },
      { id: 10, name: 'João Pedro', email: 'joao.pedro@gmail.com', gender: 'Masculino' },
      { id: 11, name: 'Karina Mendes', email: 'karina.mendes@hotmail.com', gender: 'Feminino' },
      { id: 12, name: 'Lucas Pereira', email: 'lucas.pereira@gmail.com', gender: 'Masculino' },
      { id: 13, name: 'Mariana Souza', email: 'mariana.souza@yahoo.com', gender: 'Feminino' },
      { id: 14, name: 'Nicolas Rocha', email: 'nicolas.rocha@gmail.com', gender: 'Masculino' },
      { id: 15, name: 'Olivia Dias', email: 'olivia.dias@hotmail.com', gender: 'Feminino' }
      ],
    };
  },
  computed: {
    filteredUsers() {
      return this.users.filter(user =>
        user.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    openDialog(user) {
      this.selectedUser = user;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.selectedUser = {};
    },
    banUser(duration) {
      alert(`Usuário ${this.selectedUser.name} banido por ${duration}`);
      this.closeDialog();
    },
  },
};
</script>

<style scoped>
.search-bar {
  max-width: 500px;
  color: white !important;
}

v-simple-table tbody tr {
  height: 70px;
}

.v-simple-table td {
  font-size: 16px;
  vertical-align: middle;
}

.v-avatar {
  margin-right: 10px;
}

.custom-table{
  background-color: #bbb6b6;
}
</style>

