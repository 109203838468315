<template>
  <div class="publish-container">
    <h1>Publicar Postagem</h1>

    <!-- Formulário de Publicação -->
    <form @submit.prevent="submitPost" class="publish-form">
      <!-- Título -->
      <div class="input-group">
        <label for="title">Título</label>
        <input type="text" v-model="title" id="title" placeholder="Digite o título da postagem" required />
      </div>

      <!-- Editor de Texto (Rich Text) -->
      <div class="editor-group">
        <label for="content">Conteúdo</label>
        <quill-editor v-model="content" :options="editorOptions" />
      </div>

      <!-- Botão para Carregar Imagem -->
      <div class="image-upload">
        <input type="file" @change="onImageChange" accept="image/*" />
        <p v-if="imageUrl">Imagem selecionada: <img :src="imageUrl" alt="Preview da Imagem" class="image-preview" /></p>
      </div>

      <!-- Botão de Enviar -->
      <button type="submit" class="submit-btn">Publicar</button>
    </form>
  </div>
</template>

<script>
import { QuillEditor } from 'vue-quill-editor';
import axios from 'axios';
import 'quill/dist/quill.snow.css'; // Estilo do Quill

export default {
  name: 'EditorView',
  components: {
    QuillEditor,
  },
  data() {
    return {
      title: "", // Título do post
      content: "", // Conteúdo do post (usando o Quill)
      imageUrl: null, // URL da imagem carregada
      editorOptions: {
        theme: 'snow',
        placeholder: 'Digite o conteúdo da postagem...',
        modules: {
          toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  methods: {
    onImageChange(event) {
      // Lê o arquivo de imagem selecionado
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result; // Armazena a imagem para pré-visualização
        };
        reader.readAsDataURL(file);
      }
    },
    submitPost() {
      // Envia o conteúdo para o servidor
      const postData = {
        title: this.title,
        content: this.content,
        imageUrl: this.imageUrl, // Envia a URL da imagem (se houver)
      };

      axios
        .post('/api/save-post', postData)
        .then((response) => {
          console.log('Post publicado com sucesso!', response);
          // Após o envio, resetar os campos ou redirecionar
        })
        .catch((error) => {
          console.error('Erro ao publicar o post', error);
        });
    },
  },
};
</script>

<style scoped>
/* Estilos para a tela de publicação */
.publish-container {
  width: 60%;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

h1 {
  text-align: center;
}

.publish-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  font-weight: bold;
}

.input-group input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.editor-group {
  margin-bottom: 15px;
}

.image-upload {
  margin-bottom: 20px;
}

.image-upload input {
  margin-bottom: 10px;
}

.image-preview {
  max-width: 200px;
  max-height: 150px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-btn {
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #218838;
}
</style>
