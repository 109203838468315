<template>
  <v-app class="app">
    <menu-adm />
    <v-main class="main-content">
      <header-normal />
      <router-view />
    </v-main>
    <navegation-mobile />
  </v-app>
</template>

<script>
import MenuAdm from '@/components/MenuAdm.vue';
import HeaderNormal from '@/components/HeaderNormal.vue'
import NavegationMobile from '@/components/components-mobile/NavegationMobile.vue';

export default {
  name: 'AdminPanelLayout',
  components: {
    MenuAdm,
    HeaderNormal,
    NavegationMobile
  },
};
</script>

<style scoped>
.app {
  margin-left: 90px;
}

.main-content {
  background-color: #1d1d1d !important;
  padding: 64px 100px 0px 229px !important;
}

@media (max-width: 500px) {
  .app {
    margin-left: 0px;
  }

  .main-content {
    padding: 0px 0px 0px 0px !important;
  }
}
</style>
