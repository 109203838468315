import axios from "../interceptors/axios";

export class FavoriteProjectService {
  store = async (data) => {
    try {
      const response = await axios.post("/api/favorites/projects", data);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
      throw error;
    }
  };
}
