import axios from "../interceptors/axios";

export default class CommentTitleService {
  index = async (params) => {
    try {
      const response = await axios.get("/api/comment/title/list", { params });
      return response;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
      throw error;
    }
  };
}
