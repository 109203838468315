<template>
  <v-dialog v-model="dialog" max-width="1050px">
    <div class="modal-container" color="#333333" dark>
      <v-card-text>
        <v-row>
          <v-col cols="4" class="image-container">
            <div v-if="!imageSrc" class="image-upload-container image-upload-placeholder">
              <label for="file-upload" class="image-label">
                <v-icon size="100" color="grey lighten-1">mdi-image</v-icon>
                <div class="image-text">400x700</div>
              </label>
              <input
                type="file"
                id="file-upload"
                ref="fileInput"
                class="file-input"
                accept="image/*"
                @change="onImageSelected"
              />
            </div>
            <!-- Elemento de pré-visualização da imagem -->
            <label for="file-upload">
              <div v-if="imageSrc" class="image-preview-container">
                <img :src="imageSrc" alt="Preview" class="image-preview" />
              </div>
              <input
                type="file"
                id="file-upload"
                ref="fileInput"
                class="file-input"
                accept="image/*"
                @change="onImageSelected"
              />
            </label>
          </v-col>
          <v-col cols="8">
            <v-text-field
              label="Nome"
              v-model="localObra.nome"
              outlined
              dense
              class="custom-input"
            ></v-text-field>

            <v-row>
              <v-col cols="6">
                <v-select
                  label="Categoria"
                  v-model="localObra.categoria"
                  :items="categorias"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  class="custom-input"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Gênero"
                  v-model="localObra.genero"
                  :items="generos"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  class="custom-input"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-select
                  label="Idioma"
                  v-model="localObra.idioma"
                  :items="idiomas"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  class="custom-input"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Tipo da Obra"
                  v-model="localObra.tipo"
                  :items="tiposObra"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  class="custom-input"
                ></v-select>
              </v-col>
            </v-row>

            <v-textarea
              label="Descrição"
              v-model="localObra.descricao"
              outlined
              dense
              class="custom-input"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" @click="closeModal">Cancelar</v-btn>
        <v-btn color="purple darken-2" @click="saveObra">Salvar</v-btn>
      </v-card-actions>
    </div>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" top>
      {{ snackbar.message }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
 import { getAllProjects, saveWork, getGenders } from '../server/workService.js'

export default {
  name: "AddNewWorkModal",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    obras: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      snackbar: {
        show: false,
        message: '',
        color: '',
        timeout: 2500,
      },
      dialog: this.value,
      localObra: {
        nome: '',
        categoria: '',
        genero: '',
        idioma: '',
        tipo: '',
        descricao: ''
      },
      idiomas: [
        { id: 1, name: 'Português' },
        { id: 2, name: 'Inglês' },
        { id: 3, name: 'Espanhol' }
      ],
      tiposObra: [
        { id: 1, name: 'Série' },
        { id: 2, name: 'On Shot' },
        { id: 6, name: 'Revista' }
      ],
      categorias: [
        { id: 1, name: 'Quadrinho' },
      ],
      generos: [],
      imageSrc: null,
      selectedFile: null,
      newObras: [],
    };
  },

  watch: {
    value(newValue) {
      this.dialog = newValue;
    },
    dialog(newValue) {
      this.$emit('input', newValue);
    },

    newObras(newValue) {
      this.$emit('updateNewObra', newValue);
    }
  },

  async created() {
    this.generos = await getGenders();
  },
  methods: {
    closeModal() {
      this.dialog = false;
    },
    async saveObra() {
    try {
      const dataEntries = {
        name: this.localObra.nome,
        project_category_id: this.localObra.categoria,
        project_type_id: this.localObra.tipo,
        gender_id: this.localObra.genero,
        language_id: this.localObra.idioma,
        description: this.localObra.descricao,
        project_thumb: this.selectedFile
      };
        if (!this.localObra.nome || !this.localObra.categoria) {
          this.showSnackbar('Preencha todos os campos obrigatórios.', 'error');
          return;
        }
        await saveWork(dataEntries);
        this.newObras = await getAllProjects();
        this.showSnackbar(`Obra criada com sucesso`, 'success');
        this.closeModal();
    } catch (error) {
        console.error("Erro ao salvar a obra:", error);
        this.showSnackbar(`${error.message}`, 'error');
    }
},
  showSnackbar(message, type) {
    this.snackbar.message = message;
    this.snackbar.color = type === 'success' ? 'green' : 'red';
    this.snackbar.show = true;
  },
    onImageSelected(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
        this.selectedFile = file;
      }
    },
  },
};

</script>

<style>
.image-preview-container {
  margin-top: 10px;
}

.image-preview {
  max-width: 100%;
  max-height: 700px;
}
.modal-container{
  background-color: #dadada !important;
  padding: 16px;
  height: 600px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-upload-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.image-label {
  cursor: pointer;
}

.file-input {
  display: none;
}

.custum-input{
  background-color: #1e1e1e !important;
  color: white !important;
}

.image-preview {
  width: 300px;
  height: 500px;
  object-fit: cover;
}
.image-upload-placeholder {
  background-color: #949494 !important;
  border: 2px dashed #b0b0b0;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #b0b0b0;
}
.custom-input {
  margin-bottom: 0;
}

</style>
