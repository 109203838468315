export const nameRules = [
  v => !!v || 'Nome é obrigatório',
  v => (v && v.length >= 3) || 'Nome deve ter pelo menos 3 caracteres',
];

export const nicknameRules = [
  v => !!v || 'Nickname é obrigatório',
  v => (v && v.length >= 3) || 'Nickname deve ter pelo menos 3 caracteres',
];

export const emailRules = [
  v => !!v || 'Email é obrigatório',
  v => (v && /.+@.+\..+/.test(v)) || 'Email deve ser válido',
];

export const passwordRules = [
  v => !!v || 'Senha é obrigatória',
  v => (v && v.length >= 8) || 'Senha deve ter pelo menos 8 caracteres',
];

export const passwordConfirmationRules = (password) => [
  v => !!v || 'Confirmação de senha é obrigatória',
  v => (v && v === password) || 'As senhas devem coincidir',
];
