import axios from "../interceptors/axios";

export const getAllHome = async () => {
  try {
    const response = await axios.get("/api/home", {});
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    if (error.response) {
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
    } else {
      console.error("Error Message:", error.message);
    }
    throw error;
  }
};

//buscar um projeto pelo idexport const getProjectDetailsById = async (id) => {
export const getProjectDetailsById = async (id) => {
  console.log(id);
  try {
    const response = await axios.get(`/api/projects/chapters/${id}`, {
      headers: {
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
    } else {
      console.error("Error Message:", error.message);
    }
    throw error;
  }
};
