import axios from "../interceptors/axios";

export default class UserService {
  list = async (params) => {
    try {
      const response = await axios.get("/api/users/list", { params });
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
      throw error;
    }
  };
}
