<template>
  <v-container fluid class="development-page">
    <v-row align="center" justify="center" class="full-height">
      <v-col cols="12" md="8" class="text-center">
        <v-icon large color="primary">mdi-tools</v-icon>
        <h1 class="display-2 font-weight-bold">Em Desenvolvimento</h1>
        <p class="subheading">A página da comunidade está sendo construída! Fique atento para atualizações.</p>
        <v-btn color="primary" href="/" class="mt-4">Voltar para a Home</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ComunityPage',
}
</script>

<style scoped>
.development-page {
  min-height: 100vh;
  background-color: #f5f5f5;
}

.development-page .v-btn {
  border-radius: 25px;
}
</style>
