import axios from "../interceptors/axios";

export const fetchSlides = async () => {
  try {
    const response = await axios.get("/api/slides/index");
    return response.data.slides;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const saveSlide = async (slide) => {
  try {
    const formData = {
      image: slide.imageFile,
      link: slide.link,
      description: slide.description,
    };
    await axios.post("/api/slide/add", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    if (error.response) {
      throw new Error(
        `Erro ${error.response.status}: ${error.response.data.message}`
      );
    } else {
      throw new Error(error.message);
    }
  }
};

export const updateSlide = async (slide) => {
  try {
    const formData = new FormData();
    formData.append("id", slide.id);
    formData.append("number", slide.number);
    formData.append("link", slide.link);
    formData.append("description", slide.description);

    if (slide.imageFile) {
      formData.append("image", slide.imageFile);
    }

    await axios.post("/api/slide/update", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    if (error.response) {
      throw new Error(
        `Erro ${error.response.status}: ${error.response.data.message}`
      );
    } else {
      throw new Error(error.message);
    }
  }
};

export const deleteSlide = async (slideId) => {
  try {
    const response = await axios.delete(`/api/slide/delete/${slideId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};
