<template>
  <div class="card">
    <router-link :to="`/${cardData.id}/page`">
      <div class="image-cover">
        <img :src="cardData.title_thumb" alt="cover image" />
      </div>
      <div class="overlay">
        <div class="details-card">
          <h3 class="title-project">{{ cardData.name }}</h3>
          <p>{{ cardData.description }}</p>
        </div>
        <div class="footer-card">
          <div class="rating">
            <div class="rating">
              <v-icon color="white">mdi-eye</v-icon>
              <div>{{ cardData.views }}</div>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "CommonCard",
  props: {
    cardData: {
      type: Object,
      required: true,
    },
  },

  created() {
    console.log("CardTets", this.cardData);
  }
};
</script>





<style scoped>
p {
  color: #fff !important;
}

.card {
  position: relative;
  width: 258px;
  height: 400px;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.02);
}

.image-cover {
  width: 100%;
  height: 100%;
  /* Assegura que a altura da imagem seja sempre 100% do card */
  overflow: hidden;
}

.image-cover img {
  width: 100%;
  height: 100%;
  /* Garante que a imagem ocupe 100% de altura */
  object-fit: cover;
  /* Garante que a imagem ocupe 100% do espaço sem distorcer */
  transition: opacity 0.3s ease;
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.836);
  color: white;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  transition: opacity 0.3s ease;
}

.card:hover .overlay {
  opacity: 1;
}

.title-project {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.details-card {
  font-size: 14px;
  color: #ccc;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.card:hover .details-card {
  max-height: 500px;
  opacity: 1;
}

.list-genere {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 8px 0;
}

.list-genere li {
  list-style: none;
  padding: 4px 10px;
  background-color: #451755;
  border-radius: 5px;
  font-size: 12px;
}

.footer-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}

.rating {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 2px;
  padding: 4px;
}

.custom-icon {
  color: #ffcc00;
}

@media (max-width: 520px) {
  .card {
    width: 150px;
    height: 220px;
  }

  .title h3 {
    font-size: 12px;
  }

  .footer-card {
    flex-direction: column;
    align-items: center;
  }

  .rating {
    width: auto;
    height: 20px;
  }

  .custom-icon {
    font-size: 14px;
  }
}
</style>
