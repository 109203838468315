<template>
  <div class="card-container">
    <div class="card" @click="navigateToObra">
      <div class="image-cover">
        <img :src="cardData.project_thumb" alt="cover image" />
      </div>
      <div class="overlay">
        <div class="details-card">
          <h3 class="title-project">Sinopsis</h3>
          <p>{{ cardData.description }}</p>
        </div>
        <div class="footer-card">
          <ul class="list-genere">
            <li>{{ cardData.category.name }}</li>
            <li>{{ cardData.type.name }}</li>
          </ul>
          <div class="rating-container">
            <div class="rating">
              <div>{{ formatNumber(cardData.likes_count) }}</div>
              <v-icon color="white" class="custom-icon">mdi-heart</v-icon>
            </div>
            <div class="rating">
              <div>{{ formatNumber(cardData.views) }}</div>
              <v-icon color="white">mdi-eye</v-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h1-container">
      <h1 ref="title" :class="{ marquee: isOverflowing }">
        | {{ cardData.name }}
      </h1>
    </div>
    <h4>{{ cardData.language.name }} / {{ cardData.gender.name }}</h4>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from "vue";

export default {
  name: "SimpleCardTest",
  props: {
    cardData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const title = ref(null);
    const isOverflowing = ref(false);

    onMounted(() => {
      nextTick(() => {
        console.log("Nome recebido:", title.value?.innerText); // Debug
        if (title.value) {
          isOverflowing.value = title.value.scrollWidth > title.value.clientWidth;
        }
      });
    });

    return { title, isOverflowing };
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    navigateToObra() {
      this.$router.push(`/obra/${this.cardData.id}`).then(() => {
        window.location.reload();
      });
    },

    formatNumber(num) {
      if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + "M";
      if (num >= 1_000) return (num / 1_000).toFixed(1) + "mil";
      return num;
    },
  },
};
</script>

<style scoped>
h1,
h4 {
  color: #fff !important;
}

h1 {
  font-size: x-large;
  font-weight: 600;
}

h4 {
  font-weight: 400;
  padding-left:12px;
}

.h1-container {
  width: 250px; /* Defina o tamanho máximo */
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
}

.h1-container h1 {
  display: inline-block;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}


.v-application ul,
.v-application ol {
  padding-left: 0px;
}

.rating-container {
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}


p {
  color: #fff !important;
}

.card {
  position: relative;
  width: 258px;
  height: 400px;
  border-radius: 14px;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.02);
}

.image-cover {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.836);
  color: white;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  transition: opacity 0.3s ease;
}

.card:hover .overlay {
  opacity: 1;
}

.title-project {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.details-card {
  font-size: 14px;
  color: #ccc;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.card:hover .details-card {
  max-height: 500px;
  opacity: 1;
}

.list-genere {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
}

.list-genere li {
  list-style: none;
  padding: 4px 10px;
  background-color: #451755;
  border-radius: 5px;
  font-size: 12px;
}

.footer-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.rating {
  display: flex;
  align-items: center;
  gap: 2px;
}

.custom-icon {
  color: #ffffff !important;
}

@media (max-width: 520px) {
  .h1-container {
    width: 140px !important;
  }
  h1 {
    font-size: 8px;
  }

  h4 {
    font-size: 12px;
  }
  .card {
    width: 140px !important;
    height: 220px !important;
  }

  .h1-container {
    width: 135px !important;
  }

  .title h3 {
    font-size: 12px;
  }

  .footer-card {
    flex-direction: column;
    align-items: center;
  }

  .rating {
    width: auto;
    height: 20px;
  }

  .custom-icon {
    font-size: 14px;
    color: #451755 !important;
  }
}

@media (max-width: 1024px) {
  .card {
    width: 130px;
    height: 300px;
  }

  .h1-container {
    width: 125px !important;
  }

  .title-project {
    font-size: 14px;
  }

  .list-genere li {
    font-size: 10px;
    padding: 3px 8px;
  }

  .footer-card {
    font-size: 12px;
  }

  .custom-icon {
    font-size: 16px;
  }
}

@media (max-width: 1440px) {
  .card {
    width: 230px;
    height: 380px;
  }

  .h1-container {
    width: 225px !important;
  }

}

@media (max-width: 768px) {
  h1 {
    font-size: 18px;
  }
  .card {
    width: 220px;
    height: 330px;
  }

  .h1-container {
    width: 215px !important;
  }

  .title-project {
    font-size: 12px;
  }

  .list-genere li {
    font-size: 9px;
    padding: 2px 6px;
  }

  .footer-card {
    font-size: 10px;
  }

  .custom-icon {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .card {
    width: 150px;
    height: 220px;
  }

  .h1-container {
    width: 145px !important;
  }

  .title-project {
    font-size: 10px;
  }

  .list-genere li {
    font-size: 8px;
    padding: 2px 5px;
  }

  .footer-card {
    flex-direction: column;
    font-size: 10px;
  }

  .custom-icon {
    font-size: 12px;
  }
}

@media (max-width: 425px) {
  .card {
    width: 190px !important;
    height: 300px !important;
  }

  .h1-container {
    width: 185px !important;
  }
}

@media (max-width: 375px) {
  .card {
    width: 150px !important;
    height: 260px !important;
  }

  .h1-container {
    width: 145px !important;
  }
}

@media (max-width: 320px) {
  .card {
    width: 140px !important;
    height: 230px !important;
  }

  .h1-container {
    width: 135px !important;
  }
}
</style>
