<template>
  <v-app class="app-main">
    <v-container class="pa-0" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="6" class="d-flex align-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="searchQuery" placeholder="Pesquisar" solo hide-details class="mr-2"
                @keyup.enter="searchProjects()" @input="searchFilter" v-bind="attrs" v-on="on" />
            </template>
            <v-list v-show="filteredResults.length > 0">
              <v-list-item v-for="(item, index) in filteredResults" :key="index" @click="setSearchQuery(item.name)">
                <v-list-item-avatar tile>
                  <v-img :src="item.project_thumb"></v-img>
                </v-list-item-avatar>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn class="btn-search" @click="searchProjects">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" class="tags-row">
        <v-slide-group ref="slideGroup" v-model="model" show-arrows class="tags-slider">
          <v-slide-item v-for="tag in tags" :key="tag.id">
            <v-btn class="tag-button" depressed :class="{ active: gender_id === tag.id }"
              @click="handleFilter(tag.id, 'gender')">
              {{ tag.name }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-row>

      <v-row align="center" justify="center" class="tags-row">
        <v-btn class="tag-button" v-for="tag in tagsLang" :key="tag.id" depressed
          :class="{ active: language_id === tag.id }" @click="handleFilter(tag.id, 'language')">
          {{ tag.name }}
        </v-btn>
        <span>|</span>
        <v-btn class="tag-button" v-for="tag in tagsTypes" :key="tag.id" depressed
          :class="{ active: type_id === tag.id }" @click="handleFilter(tag.id, 'type')">
          {{ tag.name }}
        </v-btn>
      </v-row>
    </v-container>

    <div class="list-result container" v-if="projects.length">
      <h1 class="container">Todos os resultados...</h1>
      <div class="result-item" v-for="(result, index) in projects" :key="index" @click="navigateToWork(result.id)">
        <img :src="result.project_thumb" alt="Imagem do Projeto" class="result-image" />
        <v-container class="fill-height d-flex flex-column justify-end project-overlay">
          <v-card-title class="text-white">{{ result.name }}</v-card-title>
          <div class="py-2 ">
            <v-chip class="mb-2 mr-2" color="blue lighten-2" text-color="white">{{ result.language?.name ||
              'Desconhecido' }}</v-chip>
            <v-chip class="mb-2 mr-2" color="green lighten-2" text-color="white">{{ result.gender?.name || 'Não informado' }}</v-chip>
            <v-chip class="mb-2 mr-2" color="purple lighten-2" text-color="white">{{ result.type?.name || 'Não especificado' }}</v-chip>
            <v-chip class="mb-2 mr-2" color="red lighten-2" text-color="white">Views: {{ result.views }}</v-chip>
          </div>
        </v-container>
      </div>
    </div>
    <div class="list-result container no-results" v-else>
      <p>Nenhum resultado encontrado.</p>
    </div>
    <div class="pagination-controls" v-if="totalPages > 1">
      <v-pagination v-model="currentPage" :length="totalPages" color="primary" active-color="pink"
        @input="searchProjects"></v-pagination>
    </div>
  </v-app>
</template>


<script>
import { getGenders, getLanguages, getProjectType, getSearchProjects } from '../server/workService.js';

export default {
  name: 'SeriesViews',
  data() {
    return {
      model: null,
      tags: [],
      tagsLang: [],
      tagsTypes: [],
      searchQuery: "",
      projects: [],
      filteredResults: [],
      gender_id: null,
      language_id: null,
      type_id: null,
      currentPage: 1,
      totalPages: 1,
      showMenu: true
    };
  },
  async created() {


    this.tags = await getGenders();
    this.tagsLang = await getLanguages();
    this.tagsTypes = await getProjectType();
  },
  methods: {
    setSearchQuery(val) {
      this.searchQuery = val;
      this.gender_id = null;
      this.language_id = null;
      this.type_id = null;
      this.searchProjects();
    },
    async searchFilter(val) {
      const params = {
        name: val,
      };

      const results = await getSearchProjects(params);
      console.log(results.data);
      this.filteredResults = results.data;
    },
    async searchProjects(page = 1) {
      try {
        const params = {
          name: this.searchQuery,
          gender_id: this.gender_id,
          language_id: this.language_id,
          project_type_id: this.type_id,
          page,
        };

        const results = await getSearchProjects(params);
        this.projects = results.data;
        this.currentPage = results.current_page;
        this.totalPages = results.last_page;
      } catch (error) {
        console.error("Erro ao buscar projetos:", error);
      }
    },
    handleFilter(selectedId, type) {
      if (type === 'gender') {
        this.gender_id = this.gender_id === selectedId ? null : selectedId;
      } else if (type === 'language') {
        this.language_id = this.language_id === selectedId ? null : selectedId;
      } else if (type === 'type') {
        this.type_id = this.type_id === selectedId ? null : selectedId;
      }
      this.searchProjects();
    },
    navigateToWork(idObra) {
      this.$router.push({ name: 'ObraOpenView', params: { idObra } });
    },
  },
  mounted() {
    let urlParams = this.$route.query;

    if (urlParams && urlParams.q) {
      this.searchQuery = urlParams.q;
    }
    this.searchProjects();
  },
};
</script>



<style scoped>
.app-main {
  background-color: #262626 !important;
  max-width: 1900px;
  margin: 0px auto !important;
}

.list-result {
  display: flex;
  flex-wrap: wrap;
  max-width: 1900px;
  margin: 0px auto !important;
  gap: 16px;
}

.result-item {
  position: relative;
  flex: 1 1 calc(60.333% - 16px);
  /* 3 itens por linha no mobile */
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
}

.project-overlay {
  width: 300px !important;
  bottom: -16px;
  border-radius: 8px;
  position: absolute;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  padding: 16px;
}

.result-image {
  width: 300px !important;
  height: 460px !important;
  height: auto;
  /* Altura automática para manter a proporção */
  border-radius: 8px;
  /* Borda arredondada */
}

.result-title {
  margin-top: 8px;
  font-size: 1rem;
  color: #333;
}

/* Ajustes para tablets */
@media (min-width: 600px) {
  .result-item {
    flex: 1 1 calc(25% - 16px);
    /* 4 itens por linha em tablets */
  }
}

/* Ajustes para desktop */
@media (min-width: 960px) {
  .result-item {
    flex: 1 1 calc(20% - 16px);
    /* 5 itens por linha em desktop */
  }
}

.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.tag-button.active {
  background-color: #66218E;
  color: white;
}

.result-title {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

.pa-0 {
  margin-top: 70px;
}

.tags-row {
  justify-content: center;
  padding: 0 16px;
}

.tags-slider {
  max-width: 80%;
}

.tag-button {
  margin: 5px;
  background-color: #5c2a75 !important;
  color: white;
}

.tag-button.active {
  background-color: #7f47a6;
}

.btn-search {
  height: 50px !important;
  background-color: #66218E !important;
}

.v-icon {
  color: white !important;
}

@media (max-width: 420px) {
  .tag-button {
    font-size: 8px;
    padding: 0px 4px !important;
  }

  .tags-slider {
    overflow: hidden;
    max-width: 100% !important;
  }

  .v-icon {
    display: none;
  }
}

.container {
  margin: 16px;
}

.result-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.result-image {
  width: 145px;
  height: 230px;
}

.no-results {
  text-align: center;
  margin-top: 32px;
}

.app-main {
  padding: 4px 8px;
  margin-top: 40px !important;
  background-color: #262626 !important;
}

.app-main h1 {
  color: #fff;
  font-size: 26px;
  font-weight: 300;
}

.list-result {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.pagination {
  margin: 50px 0px;
}

@media (max-width: 430px) {
  .v-text-field  {
    width: 250px;
  }
  .list-result {
    justify-content: center;
  }

  .app-main h1 {
    font-size: 14px;
  }

  .btn-search {
    height: 40px !important;
    width: 40px !important;
  }
}
</style>