<template>
  <div class="top-zines" v-if="items && items.length">
    <title-session-component :title="title" tag="h1" />
    <vue-slick-carousel :dots="true" :infinite="false" :speed="500" :slides-to-show="slidesToShow" :arrows="showArrows"
      :slides-to-scroll="1" class="zine-carousel">
      <div v-for="(column, index) in columns" :key="index" class="zine-column">
        <div v-for="(item, idx) in column" :key="item.id" class="zine-card">
          <img :src="item.thumb" :alt="item.name" class="zine-image" />
          <div class="zine-info-container">
            <span class="zine-number">{{ index * column.length + idx + 1 }}</span>
            <div class="zine-info">
              <h2 class="zine-title">{{ item.name }}</h2>
              <p class="zine-genre">{{ item.gender }}</p>
            </div>
          </div>
        </div>
      </div>
    </vue-slick-carousel>
  </div>
</template>

<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import VueSlickCarousel from "vue-slick-carousel";
import TitleSessionComponent from "./TitleSessionComponent.vue";

export default {
  name: "ListTop10Component",
  components: {
    TitleSessionComponent,
    VueSlickCarousel,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      default: "Top 10 Zines",
    },
  },
  data() {
    return {
      slidesToShow: this.getSlidesToShow(),
    };
  },
  computed: {
    columns() {
      const numColumns = 5;
      let columns = Array.from({ length: numColumns }, () => []);

      this.items.forEach((item, index) => {
        columns[index % numColumns].push(item);
      });

      return columns;
    },
    showArrows() {
      return window.innerWidth > 520; // Oculta as setas a partir de 430px
    }
  },
  methods: {
    updateShowArrows() {
      this.showArrows = window.innerWidth > 430;
    },
    getSlidesToShow() {
      if (window.innerWidth >= 1024) return 3; // Telas grandes: 3 colunas visíveis
      if (window.innerWidth >= 768) return 2; // Tablets: 2 colunas visíveis
      return 1; // Mobile: apenas 1 coluna visível
    },
    updateSlidesToShow() {
      this.slidesToShow = this.getSlidesToShow();
    }
  },
  mounted() {
    window.addEventListener("resize", this.updateSlidesToShow);
    window.addEventListener("resize", this.updateShowArrows);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateSlidesToShow);
    window.removeEventListener("resize", this.updateShowArrows);
  },
};
</script>

<style scoped>
.zine-info-container {
  display: flex;
  gap: 16px;
}

.zine-carousel {
  margin-top: 20px;
  margin-left: 16px;
}

.zine-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* Aumentando o espaçamento entre os cards */
}

.zine-card {
  display: flex;
  align-items: center;
  gap: 24px;
}

.zine-image {
  width: 120px;
  height: 180px;
  margin-bottom: 16px;
  object-fit: cover;
  border-radius: 8px;
}

.zine-info {
  color: white;
}

.zine-number {
  font-size: 26px;
  font-weight: bold;
  color: #e9dddd;
  margin-bottom: 10px;
}

.zine-title {
  font-size: 15px;
  font-weight: bold;
  margin: 0px;
}

.zine-genre {
  font-size: 13px;
  color: #c7c0c0 !important;
  margin: 0px;
}

@media (max-width: 320px) {
  .zine-image {
    width: 80px;
    height: 140px;
    margin-bottom: 16px;
    object-fit: cover;
    border-radius: 8px;
  }
}
</style>
