import axios from "../interceptors/axios";

export const uploadImage = async (file) => {
  const formData = new FormData();
  formData.append("file", file); // "file" é o nome do campo esperado pelo backend

  try {
    const response = await axios.post("/api/files", formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Define o tipo de conteúdo como multipart/form-data
      },
    });
    console.log(response);
    return response; // Retorna a URL da imagem após o upload
  } catch (error) {
    console.error("Erro ao fazer upload da imagem:", error);
    throw error; // Lança o erro para ser tratado no componente
  }
};