<template>
  <div class="header-container">
    <div class="profiler-container">
      <div class="image-autor">
        <img :src="data.author.profile_photo || this.imagemPadrao" alt="imagem de perfil">
      </div>
      <h1 class="name-autor">{{ data.author.name }}</h1>
      <h4 class="nickname-autor">@{{ data.author.nickname }}</h4>
      <div class="btn-container-follow">
        <v-btn color="#451755" class="custom-btn">
          Apoiar
        </v-btn>
        <v-btn color="#451755" :outlined="!data.author.is_follow" class="custom-btn mt-1"
          @click="follow(data.author.id)">
          {{ data.author.is_follow ? 'Seguindo' : 'Seguir' }}
        </v-btn>
      </div>
      <p class="about">{{ data.author.description }}</p>
      <div class="infor">
        <v-col>
          <p>Seguidores</p>
          <h2>{{ data.author.follow_count }}</h2>
        </v-col>
        <v-col>
          <p>Seguindo</p>
          <h2>{{ data.following }}</h2>
        </v-col>
      </div>
      <v-row justify="space-between" class="mt-4">
        <v-btn v-if="data.author.link_facebook" class="mx-1" icon :href="data.author.link_facebook" target="_blank">
          <v-icon color="#FFF" size="34">mdi-facebook</v-icon>
        </v-btn>

        <v-btn v-if="data.author.link_instagram" class="mx-1" icon :href="data.author.link_instagram" target="_blank">
          <v-icon color="#FFF" size="34">mdi-instagram</v-icon>
        </v-btn>

        <v-btn v-if="data.author.link_twitter" class="mx-1" icon :href="data.author.link_twitter" target="_blank">
          <v-icon color="#FFF" size="34">mdi-twitter</v-icon>
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import { FollowAuthorService } from '@/server/FollowtService';

export default {
  name: 'HeaderAuthor',
  data() {
    return {
      imagemPadrao: "https://thumbs.dreamstime.com/b/vetor-de-ícone-perfil-do-avatar-padrão-foto-usuário-mídia-social-183042379.jpg"
    }
  },

  props: {
    data: {
      type: Object,
      required: true,
    }
  },

  created() {
    console.log(this.data);
  },

  methods: {
    capitalizeWords(name) {
      return name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join()
    },
    async follow(author_id) {
      let params = {
        author_id
      }

      let service = new FollowAuthorService();
      let res = await service.store(params);
      this.$emit('updated');
      console.log(res);
    },
  }
}


</script>

<style scoped>
.name-autor {
  font-size: 36px;
  font-weight: bold;
  color: #f7f7f7 !important;
  margin-bottom: 0px;
}
.nickname-autor {
  font-size: 24px;
  font-weight: 300;
  color: #f7f7f7 !important;
  margin-bottom: 12px;
}
.about {
  max-width: 600px;
  max-height: 400px;
}
.btn-container-follow {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

p {
  color: #fff !important;
}

.info-author span {
  font-size: 24px;
  font-weight: 300;
  color: #fff;
}

.info-author h1 {
  font-size: 30px;
  font-weight: bold;
  color: #FFBD59;
}

.info-author div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.info-author div p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 300;
}

.infor {
  padding: 0px 8px;
  display: flex;
  align-items: center;
  margin-top: 36px;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #451755;
  border-radius: 50px;
}

.infor h2 {
  margin: 0px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.infor p {
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.profiler-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.image-autor {
  width: 300px;
  height: 300px;
  margin-bottom: 20px;
  background-color: gray;
  border-radius: 5px;
  overflow: hidden;
}

.image-autor img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-btn {
  border-radius: 8px;
  color: #fff !important;
  width: 100%;
  max-width: 100px;
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 10px;
  }

  .infor {
    gap: 0px;
    margin-top: 20px;
  }

  .info-author h1 {
    font-size: 16px;
  }

  .info-author span,
  .info-author div p {
    font-size: 14px;
  }

  .image-cicle {
    width: 150px;
    height: 150px;
  }

  .custom-btn {
    width: 100%;
    max-width: 100px;
  }

  .infor h2 {
    font-size: 17px;
  }

  .infor p {
    font-size: 14px;
  }
}
</style>
