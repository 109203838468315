<template>
  <v-container class="d-flex align-center justify-center fill-height">
    <div v-if="!passwordChanged">
      <v-card-title class="justify-center">
        <v-avatar size="80" color="#451755">
          <v-icon large color="#FFF">mdi-key</v-icon>
        </v-avatar>
      </v-card-title>

      <v-card-subtitle class="text-center">
        <img src="@/assets/images/Rectangle 3.png" alt="Logo Zinnes" class="mb-4">
        <h3 class="headline mb-2 font-bold">Definir Nova Senha</h3>
        <p class="text-color">Sua nova senha deve ser diferente das senhas usadas anteriormente.</p>
      </v-card-subtitle>

      <v-form @submit.prevent="submitForm">
        <v-text-field
          v-model="newPassword"
          label="Nova senha"
          outlined
          required
          type="password"
        ></v-text-field>
        <v-text-field
          v-model="confirmPassword"
          label="Confirmar nova senha"
          outlined
          required
          type="password"
        ></v-text-field>

        <v-btn type="submit" color="#451755" block large class="mb-2 btn-send font-bold">
          Confirmar
        </v-btn>
      </v-form>

      <v-card-actions class="justify-end">
        <v-btn text color="purple" @click="goToLogin">
          <v-icon left>mdi-arrow-left</v-icon> Fazer login
        </v-btn>
      </v-card-actions>
    </div>

    <div v-else class="text-center">
      <div class="flex-column">
        <v-avatar size="80" class="mb-4" color="green">
          <v-icon large color="#FFF">mdi-check-outline</v-icon>
        </v-avatar>
        <img src="@/assets/images/Rectangle 3.png" alt="Logo Zinnes" class="mb-4">
      </div>

      <h3 class="headline mb-2 font-bold">Senha Redefinida</h3>
      <p class="text-color">Sua senha foi redefinida com sucesso. Clique abaixo para fazer login.</p>
      <v-card-actions class="justify-center">
        <v-btn text color="purple" @click="goToLogin">
          <v-icon left>mdi-arrow-left</v-icon> Fazer login
        </v-btn>
      </v-card-actions>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      passwordChanged: false,
    };
  },
  methods: {
    submitForm() {
      if (this.newPassword && this.confirmPassword) {
        if (this.newPassword === this.confirmPassword) {
          this.passwordChanged = true;
          console.log(`Senha redefinida com sucesso para: ${this.newPassword}`);
        } else {
          alert('As senhas não coincidem. Por favor, tente novamente.');
        }
      }
    },
    goToLogin() {
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.fill-height {
  min-height: 100vh;
}

.btn-send {
  color: #FFF;
}

.text-color {
  color: #000;
}

.font-bold {
  font-weight: bold;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
