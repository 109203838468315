<template>
  <v-container class="banner-view p-2 mx-2" fluid>
    <v-row>
      <title-session-component title="Slides Home" tag="h1" />
    </v-row>

    <v-row class="mt-2" align="start" justify="space-between">
      <v-col cols="12" md="5">
        <div>
          <div class="banner-title mb-2">Banner - 01</div>
          <label for="file-upload" class="upload-banner mb-2">
            <img v-if="image" :src="image" alt="Pré-visualização" class="uploaded-image" />
            <span v-else>Suba a imagem aqui</span>
            <input type="file" ref="fileInput" id="file-upload" accept="image/*" @change="handleImageUpload"
              style="display: none;" />
          </label>
          <v-text-field label="Link de redirecionamento" v-model="slideLink" class="mb-2" outlined dense dark
            style="color: #000; border-color: #c1c1c1;" />
          <v-textarea label="Sinopse" v-model="slideDescription" rows="3" class="mb-2" placeholder="Aqui vai a sinopse"
            outlined dense dark style="color: #000; border-color: #c1c1c1;" />

          <v-btn color="amber" block class="white--text mb-2" @click="saveSlideData">
            Salvar
          </v-btn>
        </div>
      </v-col>

      <v-col cols="12" md="6" class="mx-0 mx-md-6">
        <v-carousel v-model="carouselModel" height="450">
          <v-carousel-item v-for="(slide, index) in slides" :key="index" :src="slide.image">
            <div class="slide-image" :style="{ backgroundImage: `url(${slide.image})` }"></div>
          </v-carousel-item>
        </v-carousel>

        <v-btn outlined color="purple" @click="openEditModal" class="mt-2">
          Editar Slide
        </v-btn>
      </v-col>
    </v-row>

    <BannersDisclosure />

    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Editar Slide</v-card-title>
        <v-card-text>
          <label for="edit-file-upload" class="upload-banner mb-2">
            <img v-if="image || selectedSlide.imageUrl" :src="image || selectedSlide.imageUrl" alt="Pré-visualização"
              class="uploaded-image" />
            <span v-else>Suba a imagem aqui</span>
            <input type="file" ref="editFileInput" id="edit-file-upload" accept="image/*" @change="handleImageUpload"
              style="display: none;" />
          </label>
          <v-text-field label="Link de redirecionamento" v-model="selectedSlide.link" placeholder="Link de redirecionamento"
            outlined dense style="color: #757575; border-color: #bdbdbd;"></v-text-field>
          <v-textarea label="Sinopse" v-model="selectedSlide.description" rows="3" placeholder="Sinopse do slide" outlined
            dense style="color: #757575; border-color: #bdbdbd;"></v-textarea>
          <v-text-field label="Número de Ordem" v-model="selectedSlide.number" placeholder="Número de ordem" outlined dense
            style="color: #757575; border-color: #bdbdbd;"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="closeEditModal">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="saveEdit">Salvar</v-btn>
          <v-btn color="red darken-2" text @click="deleteCurrentSlide">Deletar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" top>
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<style scoped>
.banner-view {
  min-height: 100vh;
  padding-left: 4px;
  padding-right: 4px;
}

.banner-title {
  font-weight: bold;
  font-size: 1.2rem;
  color: #757575;
}

.upload-banner {
  width: 100%;
  height: 200px;
  background-color: #e0e0e0;
  color: #757575;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px dashed #bdbdbd;
  cursor: pointer;
  overflow: hidden;
}

.upload-banner:hover {
  background-color: #d6d6d6;
}

.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-image {
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.mt-2 {
  margin-top: 8px;
}

.v-btn {
  color: #757575;
}

.v-text-field,
.v-textarea {
  border-color: #c1c1c1 !important;
  color: #000 !important;
}

.v-dialog .v-btn {
  color: #757575;
}

/* Ajustes para mobile */
@media (max-width: 600px) {
  .banner-view {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .upload-banner {
    height: 150px;
  }

  .v-carousel {
    height: 200px !important;
  }

  .v-btn {
    width: 100%;
    margin-bottom: 8px;
  }

  .v-text-field,
  .v-textarea {
    font-size: 14px;
  }

  .v-col {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
}
</style>


<script>
import { fetchSlides, saveSlide, updateSlide, deleteSlide } from "../server/admService.js";
import TitleSessionComponent from "@/components/TitleSessionComponent";
import BannersDisclosure from "@/components/BannersDisclosure";
export default {
  name: "PostBannerView",
  components: {
    TitleSessionComponent,
    BannersDisclosure,
  },
  data() {
    return {
      image: null,
      slideLink: "",
      slideDescription: "",
      carouselModel: 0,
      slides: [],
      selectedSlide: { id: null, number: null, imageUrl: "", link: "", description: "" },
      editDialog: false,
      snackbar: {
        show: false,
        message: '',
        color: '',
        timeout: 3500,
      },
    };
  },
  methods: {
    showSnackbar(message, type) {
      this.snackbar.message = message;
      this.snackbar.color = type === 'success' ? 'green' : 'red';
      this.snackbar.show = true;
    },
    async fetchAllSlides() {
      try {
        this.slides = await fetchSlides();
        if (this.slides.length > 0) {
          this.carouselModel = 0;
        }
      } catch (error) {
        this.showSnackbar("Erro ao carregar slides.", "error");
      }
    },

    async handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
          this.slideFile = file;
        };
        reader.readAsDataURL(file);
      }
    },

    async saveSlideData() {
      if (!this.slideFile || !this.slideLink || !this.slideDescription) {
        this.showSnackbar("Preencha todos os campos antes de salvar!");
        return;
      }

      const slide = {
        id: this.slides.length + 2,
        imageFile: this.slideFile,
        link: this.slideLink,
        description: this.slideDescription,
      };

      console.log({ slide })

      try {
        await saveSlide(slide);
        this.fetchAllSlides();
        this.image = null
        this.slideLink = "",
          this.slideDescription = "",
          this.showSnackbar("Slide enviado com sucesso.", "success");
      } catch (error) {
        console.log("error Slides", error)
        this.showSnackbar("Erro ao enviar o slide.", "error");
      }
    },

    openEditModal() {
      if (this.carouselModel >= 0 && this.carouselModel < this.slides.length) {
        const selectedSlide = this.slides[this.carouselModel];
        this.selectedSlide = { ...selectedSlide };
        this.image = selectedSlide.imageUrl;
        this.editDialog = true;
      } else {
        this.showSnackbar("Índice de slide inválido", "error");
      }
    },

    closeEditModal() {
      this.image = null; // Limpa a imagem carregada
      this.editDialog = false;
    },
    async saveEdit() {
      try {
        const newSlide = {
          id: this.selectedSlide.id,
          number: this.selectedSlide.number,
          link: this.selectedSlide.link,
          description: this.selectedSlide.description,
        };

        if (this.image !== this.selectedSlide.imageUrl && this.image) {
          newSlide.imageFile = this.slideFile || this.image;
        }

        console.log("Dados do slide antes do envio:", newSlide);

        await updateSlide(newSlide);
        this.fetchAllSlides();
        this.closeEditModal();
        this.showSnackbar("Slide atualizado com sucesso.", "success");
      } catch (error) {
        console.error("Erro ao salvar edição:", error);
        this.showSnackbar("Erro ao atualizar o slide.", "error");
      }
    },


    async deleteCurrentSlide() {
      const selectedSlide = this.slides[this.carouselModel];
      if (!selectedSlide) return;

      try {
        await deleteSlide(selectedSlide.id);
        this.slides = this.slides.filter((slide) => slide.id !== selectedSlide.id);
        this.closeEditModal()
        this.fetchAllSlides();
        this.showSnackbar("Slide excluído com sucesso.", "success");
      } catch (error) {
        this.showSnackbar("Erro ao excluir o slide.", "error");
      }
    },
  },
  async mounted() {
    await this.fetchAllSlides();
  },
};


</script>
