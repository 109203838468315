import axios from "../interceptors/axios";

export default class BannerService {
  list = async (params) => {
    try {
      const response = await axios.get("/api/banners/list", { params });
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
      throw error;
    }
  };

  create = async (data) => {
    try {
      const response = await axios.post("/api/banners/create", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
      throw error;
    }
  };

  edit = async (data) => {
    try {
      const response = await axios.post("/api/banners/edit", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
      throw error;
    }
  };

  delete = async (params) => {
    try {
      const response = await axios.delete("/api/banners/delete", { params });
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
      throw error;
    }
  };
}
