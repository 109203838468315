<template>
  <div class="main-container">
    <v-navigation-drawer v-model="drawer" app dark permanent class="menu-drawer">
      <div v-if="!isAuthor && !isAdmin">
        <v-list>
          <v-list-item>
            <v-btn @click="openModal" color="#451755" class="wh-btn">Quero ser Autor</v-btn>
          </v-list-item>
          <div class="list-item">
            <v-list-item>
              <v-btn color="#111111" class="wh-btn disable">
                <v-icon>mdi-view-dashboard</v-icon>
                <v-list-item-content>Painel</v-list-item-content>
                <v-icon>mdi-lock</v-icon>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn color="#5A5A5A" class="wh-btn">
                <v-icon>mdi-account</v-icon>
                <v-list-item-content>Perfil</v-list-item-content>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn color="#1D1D1D" to="/dashboard/configuracao" class="wh-btn">
                <v-icon>mdi-cog</v-icon>
                <v-list-item-content>Configurações</v-list-item-content>
              </v-btn>
            </v-list-item>
            <v-list-item @click="logout">
              <v-btn color="#1D1D1D" class="wh-btn">
                <v-icon>mdi-logout</v-icon>
                <v-list-item-content>Sair</v-list-item-content>
              </v-btn>
            </v-list-item>
          </div>
        </v-list>
      </div>

      <template v-if="isAuthor && !isAdmin">
        <v-list>
          <v-list-item>
            <v-btn color="#451755" to="/dashboard/publicar-obra" class="wh-btn" @click="publish">Publicar</v-btn>
          </v-list-item>
          <div class="list-item">
            <v-list-item>
              <v-btn color="#1D1D1D" to="/dashboard/painel" class="wh-btn">
                <v-icon>mdi-view-dashboard</v-icon>
                <v-list-item-content>Painel</v-list-item-content>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn color="#1D1D1D" to="/dashboard/user-profiler" class="wh-btn">
                <v-icon>mdi-account</v-icon>
                <v-list-item-content>Perfil</v-list-item-content>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn color="#1D1D1D" to="/dashboard/configuracao" class="wh-btn">
                <v-icon>mdi-cog</v-icon>
                <v-list-item-content>Configurações</v-list-item-content>
              </v-btn>
            </v-list-item>
            <v-list-item @click="logout">
              <v-btn color="#1D1D1D" class="wh-btn">
                <v-icon>mdi-logout</v-icon>
                <v-list-item-content>Sair</v-list-item-content>
              </v-btn>
            </v-list-item>
          </div>
        </v-list>
      </template>

      <template v-if="isAdmin">
        <v-list>
          <v-list-item>
            <v-btn color="#451755" to="/dashboard/publicar-obra" class="wh-btn" @click="publish">Publicar</v-btn>
          </v-list-item>
          <div class="list-item">
            <v-list-item>
              <v-btn color="#1D1D1D" to="/dashboard/painel" class="wh-btn">
                <v-icon>mdi-view-dashboard</v-icon>
                <v-list-item-content>Painel</v-list-item-content>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn color="#1D1D1D" to="/dashboard/user-profiler" class="wh-btn">
                <v-icon>mdi-account</v-icon>
                <v-list-item-content>Perfil</v-list-item-content>
              </v-btn>
            </v-list-item>
            <div class="adm">
              <v-subheader class="title-adm">ADMINISTRADOR</v-subheader>
              <div class="line"></div>
              <v-list-item>
                <v-btn color="#1D1D1D" to="/dashboard/adm_dashboard" class="btn-adm">
                  <v-icon>mdi-view-dashboard</v-icon>
                  <v-list-item-content>Dashboard</v-list-item-content>
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn color="#1D1D1D" to="/dashboard/publicar-banner" class="btn-adm">
                  <v-icon>mdi-image</v-icon>
                  <v-list-item-content>Banners</v-list-item-content>
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn to="/dashboard/adm_moderadores" color="#1D1D1D" class="btn-adm">
                  <v-icon>mdi-account-group</v-icon>
                  <v-list-item-content>Moderadores</v-list-item-content>
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn color="#1D1D1D" to="/dashboard/avisos" class="btn-adm">
                  <v-icon>mdi-bell</v-icon>
                  <v-list-item-content>Avisos</v-list-item-content>
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn to="/dashboard/adm_acess_user" color="#1D1D1D" class="btn-adm">
                  <v-icon>mdi-account-multiple</v-icon>
                  <v-list-item-content>Usuários</v-list-item-content>
                </v-btn>
              </v-list-item>
            </div>
            <v-list-item>
              <v-btn color="#1D1D1D" to="/dashboard/configuracao" class="wh-btn">
                <v-icon>mdi-cog</v-icon>
                <v-list-item-content>Configurações</v-list-item-content>
              </v-btn>
            </v-list-item>
            <v-list-item @click="logout">
              <v-btn color="#1D1D1D" class="wh-btn">
                <v-icon>mdi-logout</v-icon>
                <v-list-item-content>Sair</v-list-item-content>
              </v-btn>
            </v-list-item>
          </div>
        </v-list>
      </template>
    </v-navigation-drawer>
    <div>
      <v-dialog v-model="isModalOpen" max-width="40vw"   full-width>
        <v-card class="modal-card">
          <v-card-title class="headline">Termos e Condições</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="8" offset-md="2">
                  <p class="text">Estamos felizes que você deseja compartilhar suas obras conosco!</p>
                  <p class="text">Ao se tornar um autor, você terá a oportunidade de publicar suas obras originais e
                    compartilhar suas histórias com a comunidade.</p>
                  <h3 class="font-weight-semibold">Termos e Condições:</h3>
                  <ul>
                    <li>Você concorda em publicar apenas obras autorais.</li>
                    <li>Você não deve plagiar ou copiar o trabalho de outros.</li>
                    <li>A plataforma não se responsabiliza por qualquer infração de direitos autorais.</li>
                  </ul>
                  <h3 class="font-weight-semibold">Responsabilidade sobre Plágio:</h3>
                  <p class="text">É de sua responsabilidade garantir que as obras que você publica são de sua própria
                    autoria. Qualquer violação de direitos autorais será de sua responsabilidade.</p>
                  <v-checkbox class="terms-checkbox" v-model="acceptedTerms"
                    label="Eu li e aceito os termos de privacidade e responsabilidade sobre plágio."></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="btn-modal-author" color="red" @click="cancel">Cancelar</v-btn>
            <v-btn class="btn-modal-author" color="primary" @click="confirm"
              :disabled="!acceptedTerms">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
  </div>

</template>

<script>
import { becomeAuthor } from '@/server/userServe';

export default {
  name: 'MenuAdm',
  data() {
    return {
      isModalOpen: false,
      acceptedTerms: false,
      drawer: true,
      mini: false,
      isAuthor: false,
      isAdmin: false,
    };
  },
  mounted() {
    this.setUserAccess();
  },
  methods: {
    openModal() {
      console.log("Abrindo modal...");
      this.isModalOpen = true; // Abre o modal
    },
    async confirm() {
      await becomeAuthor();
      await this.setUserAccess();
      this.isModalOpen = false;
      console.log('Usuário se tornou autor!');
    },
    cancel() {
      this.isModalOpen = false;
    },
    setUserAccess() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        this.isAdmin = user.level === 'GM Guild Master';
        this.isAuthor = user.level === 'OA Administrador';
      }
    },
    publish() {
    },
    logout() {
      localStorage.clear();
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
.v-dialog {
  margin: 0px !important;
  max-width: 100vw !important;
}

.text {
  color: #616161;
}

.btn-modal-author {
  width: 200px !important;
}

.v-card {
  padding: 16px;
  /* Adiciona um pouco de espaçamento ao conteúdo do card */
}

/* Ajustes adicionais para telas menores */
@media (max-width: 600px) {
  .v-card-title {
    font-size: 1.5rem;
    /* Tamanho de fonte menor para o título em dispositivos móveis */
  }

  .v-card-text {
    font-size: 1rem;
    /* Tamanho de fonte menor para o texto em dispositivos móveis */
  }
}

.menu-drawer {
  background-color: #000000 !important;
  width: 320px !important;
  padding-top: 30px !important;
}

.main-container {
  width: 317px !important;
  padding: 24px 12px;
}

.v-navigation-drawer {
  background-color: #1a1a1a;
}

.v-list-item {
  color: white;
}

.list-item {
  margin-top: 40px;
}

.v-btn {
  background-color: #6b21a8;
  color: white;
  width: 100%;
  margin-bottom: 10px;
}

.wh-btn {
  width: 260px;
  height: 56px !important;
  border-radius: 100px;
}

.adm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.title-adm {
  font-size: 20px;
  font-weight: bold;
}

.line {
  width: 250px;
  height: 2px;
  background-color: #616161;
  margin-bottom: 20px;
}

.btn-adm {
  width: 160px;
  height: 46px !important;
  padding: 8px !important;
  border-radius: 100px;
}

.disable {
  color: #675B5B;
}

@media (max-width: 500px) {
  .main-container {
    display: none
  }
}
</style>
